import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import * as settingMakerCommon from '@/store/settingmaker/settingMakerCommon';

import { YMD, CenterItem, ItemTagData, MakerTagData, InfoDataStoreSales, InfoDataTransfer, getTransferBL, InfoDataWarehousing, getNum, getNumCS, getNumPS, getWarehousingBL, InfoDataBestBefore, InfoDataStoreOrder, InfoDataShipping, InfoDataShippingTokubai, getShippingBL, InfoDataStock, ProvisionalData, listSortOrders, centerItemAttributes, CommentData, DiffEditSeq, DiffData, DiffFurikaeData, ItemCommonData, DisableDateData, AlertSettings, MakerLimitData, YMDInfo, RowDataGroup, HighlightInfoData, calcDataGroup1, calcDataGroup2, calcDataGroup3, parseDataYMD, parseDataCenterItem, parseItemTagData, parseItemCommonData, parseDisableDateData, parseMakerTagData, parseMakerLimitData, parseDataInfoDataStoreSales, parseDataInfoDataTransfer, parseDataInfoDataWarehousing, parseDataInfoDataBestBefore, parseDataInfoDataStoreOrder, parseDataInfoDataShipping, parseDataInfoDataShippingTokubai, parseDataInfoDataStock, parseDataInfoDataWeather, parseDataCommentData, getNumBLfromPS, rowToIdx, idxToRow, calcDataGroupSingle, calcDataGroupAll, calcDataGroups, InfoDataOrder, parseDataInfoDataOrder, defaultVisibleRowsKey, DiffItemTagData, OpenMeteoDaily, findDataGroupIndex, InfoDataShippingGroup1, parseDataInfoDataShippingGroup1, TagData } from '@/store/mainpage/mainPageCommon';
import * as mainPageRetrieve from "@/assets/apitype/mainPageRetrieve";
import { MainPageColRowModel, rowKeys, rowKeysUnOrderableHidden, rowKeysBumonHiddenZaikoNormal, rowKeysBumonHiddenStoreOrder, headersRowGeneral, headersRowGeneralTeibal, headersRowAll, headersRowFM, headersRowSEJ, termColKeys, rowDataTotalVisible, rowDataNoSimilarVisible, colDataType, rowDataType, headersRowGeneralYoyaku, headersRowGeneralTokubai } from "@/components/mainpage/MainPageTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";
import * as momentUtil from "@/util/momentUtil";
import moment, {Moment} from 'moment';

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });


export type RowInfo = {
  TP: "total" | "maker" | "item" | "center" | "info",
  top:boolean,
  no?: string,
  subno?: string,
  // dataGroup?:RowDataGroup,
  dataGroupIndex?:number,
  rowKey?:string,
  drilldownKey?:string,
  // drilldownIndex?:number,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  rowCount?:number,  //同一集計行内でのrowKeyの件数
  index:number,  //行番号

  koCenterDataGroupIndex1?:number, //子センターのIndex
  koCenterDataGroupIndex2?:number, //子センターのIndex1
  koCenterDataGroupIndex3?:number, //子センターのIndex1
  oyaCenterDataGroupIndex1?:number, //親センターのIndex
  oyaCenterDataGroupIndex2?:number, //親センターのIndex
  oyaCenterDataGroupIndex3?:number, //親センターのIndex
  bundleItemDataGroupIndex?:number, //バンドル商品のIndex
  bundledItemDataGroupIndex?:number, //被バンドル商品のIndex1
}
export const findRowInfoTopIndex = (itemCD:string, centerCD:string, dataGroups:RowDataGroup[], rowInfos:RowInfo[]): number => {
  if(!itemCD || !centerCD) {
    return null;
  }
  return rowInfos.findIndex(rowInfo => {
    if(rowInfo.rowIndex > 0) {
      return false;
    }
    if(rowInfo.TP != 'info') {
      return false;
    }
    const rowInfo_dataGroup = getRowInfoDataGroup(rowInfo, dataGroups, null);
    return rowInfo_dataGroup?.centerItem?.itemCD == itemCD
      && rowInfo_dataGroup?.centerItem?.centerCD == centerCD
    ;
  });
}
export const findRowInfoIndex = (itemCD:string, centerCD:string, rowKeys:string[], dataGroups:RowDataGroup[], rowInfos:RowInfo[]): number => {
  if(!itemCD || !centerCD || !rowKeys?.length) {
    return null;
  }
  return rowInfos.findIndex(rowInfo => {
    if(rowKeys.indexOf(rowInfo.rowKey) == -1) {
      return false;
    }
    if(rowInfo.TP != 'info') {
      return false;
    }
    const rowInfo_dataGroup = getRowInfoDataGroup(rowInfo, dataGroups, null);
    return rowInfo_dataGroup?.centerItem?.itemCD == itemCD
      && rowInfo_dataGroup?.centerItem?.centerCD == centerCD
    ;
  });
}


export const getRowInfoDataGroup = (rowInfo:RowInfo, dataGroups:RowDataGroup[], dataGroupTotal:RowDataGroup): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.dataGroupIndex < 0) {
    return dataGroupTotal;
  }
  return dataGroups[rowInfo.dataGroupIndex];
}
export const getRowInfoDataGroupKoCneter1 = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.koCenterDataGroupIndex1 == null) {
    return null;
  }
  return dataGroups[rowInfo.koCenterDataGroupIndex1];
}
export const getRowInfoDataGroupKoCneter2 = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.koCenterDataGroupIndex2 == null) {
    return null;
  }
  return dataGroups[rowInfo.koCenterDataGroupIndex2];
}
export const getRowInfoDataGroupKoCneter3 = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.koCenterDataGroupIndex3 == null) {
    return null;
  }
  return dataGroups[rowInfo.koCenterDataGroupIndex3];
}
export const getRowInfoDataGroupOyaCneter1 = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.oyaCenterDataGroupIndex1 == null) {
    return null;
  }
  return dataGroups[rowInfo.oyaCenterDataGroupIndex1];
}
export const getRowInfoDataGroupOyaCneter2 = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.oyaCenterDataGroupIndex2 == null) {
    return null;
  }
  return dataGroups[rowInfo.oyaCenterDataGroupIndex2];
}
export const getRowInfoDataGroupOyaCneter3 = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.oyaCenterDataGroupIndex3 == null) {
    return null;
  }
  return dataGroups[rowInfo.oyaCenterDataGroupIndex3];
}
export const getRowInfoDataGroupBundleItem = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.bundleItemDataGroupIndex == null) {
    return null;
  }
  return dataGroups[rowInfo.bundleItemDataGroupIndex];
}
export const getRowInfoDataGroupBundledItem = (rowInfo:RowInfo, dataGroups:RowDataGroup[]): RowDataGroup => {
  if(!rowInfo) {
    return null;
  }
  if(rowInfo.bundledItemDataGroupIndex == null) {
    return null;
  }
  return dataGroups[rowInfo.bundledItemDataGroupIndex];
}

//編集情報
export interface SaveInfoData {
  seq: number,
  centerItem: CenterItem,

  infos?: {
    // storeSales?: InfoDataStoreSales, //店頭売上
    transfer?: {
      oldVal: InfoDataTransfer, //転送
      newVal: InfoDataTransfer, //転送
    },
    warehousing?: {
      oldVal: InfoDataWarehousing, //入庫
      newVal: InfoDataWarehousing, //入庫
    },
    // bestBefore?: InfoDataBestBefore, //賞味期限
    // storeOrder?: InfoDataStoreOrder, //受注
    shipping?: {
      oldVal: InfoDataShipping, //出庫
      newVal: InfoDataShipping, //出庫
    },
    // stockNormal?: InfoDataStock, //在庫（良品）
    // stockUseUP?: InfoDataStock, //在庫（出切）
    // stockReturnd?: InfoDataStock, //在庫（返品）
  },

  comment?: CommentData, //グループコメント

  comments?: {
    weather?: CommentData, //"天気",
    storeSales?: CommentData, //店頭売上
    transfer?: CommentData, //転送予定
    warehousing?: CommentData, //入庫
    bestBefore?: CommentData, //賞味期限
    storeOrder?: CommentData, //受注
    shipping?: CommentData, //出庫
    stockNormal?: CommentData, //在庫（良品）
    stockUseUp?: CommentData, //在庫（出切）
    stockReturned?: CommentData, //在庫（返品）

    similarItem_warehousing?: CommentData,
    similarItem_shipping?: CommentData,
    similarItem_stockNormal?: CommentData,  
  },
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  rowKey?:string, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
  auto?:boolean,
}
export interface EditInfo {
  numUnit_view:'CS'|'BL'|'PS'|string, //表示数量単位
  numUnit_input:'LOT'|'CS'|'BL'|'PS'|'auto'|string, //丸め数量単位
  wholeOrDiff:'whole'|'diff'|'auto'|string, //全量or差分
  editDatas:EditCellInfo[],
}

export interface PageInfo {
  value?:string,
  title?:string,
  searched?:boolean,
  rowInfoIdxFrom?:number,
  rowInfoIdxTo?:number,
}
export interface PagingInfo {
  selectedPageIndex?:number,
  selectedPage?:PageInfo,
  pages?:PageInfo[],
  selectedPageRowInfos?: RowInfo[],
}

//Page State
export type MainPageTmpState = {
  editing: boolean,

  // bumonList: CodeName[],  //部門
  makerList: CodeName[],  //メーカー
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫
  tagsList: CodeName[], //タグ
  orderUserList: CodeName[], //

  progress: Record<string, unknown>,

  alertSetting: AlertSettings, //検索条件
  retrieveParam: mainPageRetrieve.RequestParam,  //検索条件

  retrievedParam: mainPageRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedEditSeq: DiffEditSeq,  //取得済みEdiSew

  searchedTargets: boolean,
  searchedBefore: boolean,
  searchedItemTags: boolean,
  searchedMakerTags: boolean,
  searchedComments: boolean,
  searchedResults: boolean,
  searchedResults_weather: boolean,     //天気
  searchedResults_storeSales: boolean,  //店頭売上",
  searchedResults_bestBefore: boolean,  //賞味期限",
  searchedResults_warehousing_last: boolean,  //前年入庫
  searchedResults_shipping_last: boolean,  //前年出庫

  retrieveBefore_ver: number,

  retrieveItemTag_ver: number,
  retrieveItemTag_centerItems: CenterItem[],
  retrieveMakerTag_ver: number,
  retrieveMakerTag_centerItems: CenterItem[],
  retrieveComment_ver: number,
  retrieveComment_centerItems: CenterItem[],
  retrieveResult_ver: number,
  retrieveResult_centerItems: CenterItem[],

  ymds: YMD[],
  centerItems: CenterItem[],
  itemTagDatas: ItemTagData[],
  makerTagDatas: MakerTagData[],
  makerLimitDatas: MakerLimitData[],
  centerCDs: string[],
  itemCDs: string[],

  confirmEditInfo_warehousing_clear:EditInfo,//確認中の編集情報 //入庫　クリア
  confirmEditInfo_warehousing_holiday:EditInfo,//確認中の編集情報 //入庫　祝日
  confirmEditInfo_warehousing_warn: EditInfo,//確認中の編集情報 //入庫　入庫可能曜日以外

  dataGroupTotal: RowDataGroup,
  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],

  makerIndex: number,
  selectedMaker: CodeName,
  pageStatus: 'makerPrev' | string,
  pagingInfo:PagingInfo,

  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,
  tableVer_scrollDefault: number,

  excelVer: number,
  excelQueue: boolean,
  excelQueuePageOnly:boolean,
  excelQueueWithDataSheet:boolean,

  autoOrderVer: number,
  autoOrderQueue: boolean,

  provisionalVer: number,
  provisionalQueue: boolean,

  saveVer: number,
  saveSeq: number,
  saveQueue: SaveInfoData[],

  loadVerCorrect: number,
  loadVerRefrect: number,
  loadSeq: number,
  loadQueue: DiffData[],

  errorMessage: string | null,
  functionKeys: string[],
  openDialog: boolean,
};

export const initialState: MainPageTmpState = {
  editing: false,
  // bumonList: [],
  makerList: [],
  areaList: [],
  centerList: [],
  tagsList: [],
  orderUserList: [], //

  progress: {},

  alertSetting: {},  //検索条件
  retrieveParam: null,  //検索条件

  retrievedParam: null,
  retrievedEditSeq: {},  //取得済みEdiSew

  searchedTargets: false,
  searchedBefore: false,
  searchedItemTags: false,
  searchedMakerTags: false,
  searchedComments: false,
  searchedResults: false,
  searchedResults_weather: false,
  searchedResults_storeSales: false,
  searchedResults_bestBefore: false,
  searchedResults_warehousing_last: false,
  searchedResults_shipping_last: false,

  retrieveBefore_ver: 0,

  retrieveItemTag_ver: 0,
  retrieveItemTag_centerItems: [],
  retrieveMakerTag_ver: 0,
  retrieveMakerTag_centerItems: [],
  retrieveComment_ver: 0,
  retrieveComment_centerItems: [],
  retrieveResult_ver: 0,
  retrieveResult_centerItems: [],

  ymds: [],
  centerItems: [],
  itemTagDatas: [],
  makerTagDatas: [],
  makerLimitDatas: [],
  centerCDs: [],
  itemCDs: [],

  confirmEditInfo_warehousing_clear: null,//確認中の編集情報 //入庫　クリア
  confirmEditInfo_warehousing_holiday: null,//確認中の編集情報 //入庫　祝日
  confirmEditInfo_warehousing_warn: null,//確認中の編集情報 //入庫　入庫可能曜日以外

  dataGroupTotal: null,
  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  rows: [],
  mergeCells: null,

  makerIndex: 0,
  selectedMaker: null,
  pageStatus: null,
  pagingInfo: {},

  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,
  tableVer_scrollDefault: 0,

  excelVer: 0,
  excelQueue: false,
  excelQueuePageOnly:false,
  excelQueueWithDataSheet:false,

  autoOrderVer: 0,
  autoOrderQueue: false,

  provisionalVer: 0,
  provisionalQueue: false,

  saveVer: 0,
  saveSeq: 0,
  saveQueue: [],

  loadVerCorrect: 0,
  loadVerRefrect: 0,
  loadSeq: 0,
  loadQueue: [],

  errorMessage: null,
  functionKeys: [],
  openDialog: false,
};

//Page Slice
export type MainPageTmpReducer = {
  setEditingStart: (state:MainPageTmpState) => void,
  setEditingEnd: (state:MainPageTmpState) => void,
  putProgress: (state:MainPageTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:MainPageTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:MainPageTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setTagsList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  setOrderUserList: (state: MainPageTmpState, action: PayloadAction<CodeName[]>) => void,
  startRetrieve: (state:MainPageTmpState, action: PayloadAction<{
    alertSetting: AlertSettings, //検索条件
    retrieveParam: mainPageRetrieve.RequestParam,  //検索条件
  }>) => void,
  retrieveStart: (state:MainPageTmpState, action: PayloadAction<{}>) => void,
  setRetrievedParam: (state:MainPageTmpState, action: PayloadAction<mainPageRetrieve.RequestParam>) => void,
  initRetrievedEditSeq: (state:MainPageTmpState, action: PayloadAction<DiffEditSeq>) => void,
  updateRetrievedEditSeq: (state:MainPageTmpState, action: PayloadAction<DiffEditSeq>) => void,
  setFunctionKey: (state:MainPageTmpState, action: PayloadAction<string>) => void,
  setOpenDialog: (state:MainPageTmpState, action: PayloadAction<boolean>) => void,
  setPageStatus: (state: MainPageTmpState, action: PayloadAction<string>) => void,

  searchedTargets: (state:MainPageTmpState, action: PayloadAction<{
    optionBumon: CodeName, 
    param: mainPageRetrieve.RequestParam, ymds: YMD[], centerItems: CenterItem[], 
    colRowModel:MainPageColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[],
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string
  }>) => void,
  searchedBefore: (state:MainPageTmpState, action: PayloadAction<{}>) => void,
  searchedItemTags: (state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[], itemTagDatas: ItemTagData[], itemCommonDatas: ItemCommonData[], disableDateDatas: DisableDateData[],
    colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) => void,
  searchedMakerTags: (state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[], makerTagDatas: MakerTagData[],
    colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], holidays:any, alertSetting:AlertSettings}>) => void,
  searchedComments: (state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[], comments:CommentData[]}>) => void,
  searchedResults: (state:MainPageTmpState, action: PayloadAction<{
    centerItems: CenterItem[], 
    makerLimitDatas: MakerLimitData[],
    infosStoreSales:InfoDataStoreSales[], 
    infosTransferIn:InfoDataTransfer[], 
    infosTransferOut:InfoDataTransfer[], 
    infosWarehousing:InfoDataWarehousing[], 
    infosWarehousingLast:InfoDataWarehousing[], 
    infosOrder:InfoDataOrder[], 
    infosBestBefore:InfoDataBestBefore[], 
    infosStoreOrder:InfoDataStoreOrder[], 
    infosShipping:InfoDataShipping[], 
    infosShippingLast:InfoDataShipping[], 
    infosShippingTokubai:InfoDataShippingTokubai[], 
    infosShippingTokubaiLast:InfoDataShippingTokubai[], 
    infosStock:InfoDataStock[], 
    infosWeather: OpenMeteoDaily[],
    colRowModel:MainPageColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[], holidays:any, alertSetting:AlertSettings,
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string, 
    onlyNormal:boolean,
    result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) => void,
  searchedShippingGroup1s: (state:MainPageTmpState, action: PayloadAction<{
    optionBumon: CodeName, 
    centerItems: CenterItem[], 
    infosShippingGroup1s:InfoDataShippingGroup1[], 
    colRowModel:MainPageColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[], 
    // holidays:any, 
    alertSetting:AlertSettings,
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string,
    onlyNormal:boolean,
    result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) => void,
  searchContents: (state:MainPageTmpState, action: PayloadAction<{type:'all'|'page'|'target'|string, centerItems?:CenterItem[]}>) => void,
  reSearchResults: (state:MainPageTmpState, action: PayloadAction<{result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) => void,
  clearTable: (state:MainPageTmpState, action: PayloadAction<{}>) => void,
  refreshTableColumns: (state:MainPageTmpState, action: PayloadAction<{ymds: YMD[]}>) => void,
  refreshTable: (state:MainPageTmpState, action: PayloadAction<{
    optionBumon: CodeName, 
    colRowModel:MainPageColRowModel, pageValue:string, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[], holidays:any, alertSetting:AlertSettings,
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string,
    checkForced?: boolean,
    result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) => void,
  renderTable: (state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) => void,
  editRowDatas: (state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, 
    showSubTotal:boolean,
    showGroupRow:boolean, 
    numUnit_view:'CS'|'BL'|'PS'|string, numUnit_input:'LOT'|'CS'|'BL'|'PS'|'auto'|string, 
    wholeOrDiff:'whole'|'diff'|'auto'|string, //全量or差分
    visibleAttributes: string[][], holidays:any, alertSetting:AlertSettings, doConfirm:boolean, editDatas:EditCellInfo[], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) => void,
  confirmdEditInfo: (state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    visibleAttributes: string[][], holidays:any, alertSetting:AlertSettings, editInfo:EditInfo, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) => void,
  clearConfirmEditInfo_warehousing_clear: (state:MainPageTmpState) => void,
  clearConfirmEditInfo_warehousing_holiday: (state:MainPageTmpState) => void,
  clearConfirmEditInfo_warehousing_warn: (state: MainPageTmpState) => void,

  editComments: (state: MainPageTmpState, action: PayloadAction<{
    colRowModel: MainPageColRowModel, numUnit: 'CS' | 'BL' | 'PS' | string,
    visibleAttributes: string[][],
    editDatas: EditCellInfo[],
    onlyNormal: boolean, result_type: 'general_teiban' | 'general' | 'general_yoyaku' | 'general_tokubai' | 'all' | string
  }>) => void,
  editItemTags: (state:MainPageTmpState, action: PayloadAction<{centerItem: CenterItem}>) => void,
  editMakerTags: (state:MainPageTmpState, action: PayloadAction<{centerItem: CenterItem}>) => void,
  editOrderInstruction: (state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[]}>) => void,
  setAutoOrderQueue: (state:MainPageTmpState, action: PayloadAction<{exec:boolean}>) => void,
  editAutoOrder: (state:MainPageTmpState, action: PayloadAction<{leadTimeNo:''|'1'|'2'|'3'|'4'|'5'|string, excludeItemTags:string[], centerMakers:settingMakerCommon.CenterMaker[], infoDatas:settingMakerCommon.InfoData[], holidays:any, alertSetting:AlertSettings, colRowModel:MainPageColRowModel, 
  showSubTotal:boolean, 
  showGroupRow:boolean, 
  numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) => void,
  setProvisionalQueue: (state:MainPageTmpState, action: PayloadAction<{exec:boolean}>) => void,
  editProvisional: (state:MainPageTmpState, action: PayloadAction<{provisionalDatas:ProvisionalData[], holidays:any, alertSetting:AlertSettings, colRowModel:MainPageColRowModel, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) => void,
  refrectLoadDatas: (state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][], holidays:any, alertSetting:AlertSettings, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
    }>) => void,
  execSaveQueue: (state:MainPageTmpState) => void,
  addSaveQueue: (state:MainPageTmpState, action: PayloadAction<SaveInfoData[]>) => void,
  removeSaveQueue: (state:MainPageTmpState, action: PayloadAction<SaveInfoData[]>) => void,
  execLoadQueueCorrect: (state:MainPageTmpState) => void,
  execLoadQueueRefrect: (state:MainPageTmpState) => void,
  addLoadQueue: (state:MainPageTmpState, action: PayloadAction<DiffData[]>) => void,
  removeLoadQueue: (state:MainPageTmpState, action: PayloadAction<DiffData[]>) => void,
  setExcelQueue: (state:MainPageTmpState, action: PayloadAction<{exec:boolean, pageOnly?:boolean, withDataSheet?:boolean}>) => void,
}

//ItemTagDataソート
const fncItemTagDataSort = (a, b) => {
  let comp = compareUtil.compareString(a.centerCD, b.centerCD, true);
  if(comp == 0) {
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true);
  }
  //並び順
  if(comp == 0) {
    comp = compareUtil.compareNumber(a.itemTag?.sortOrder, b.itemTag?.sortOrder, true);
  }
  if(comp == 0) {
    comp = compareUtil.compareString(a.tagCD, b.tagCD, true);
  }
  return comp;
};

//編集後のデータ更新(refreshTable)
const fncAfterEditRowDatas = (state, editedRowsTop:number[], editRowKeysSet:Set<string>, commentRows:number[], pageInfo:PageInfo, colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string) => {
  if(!editedRowsTop?.length && !commentRows?.length) {
    return;
  }

  //再描写対象の保管　出庫と出庫(先付含)は同時に再描写する
  const has_shipping = editRowKeysSet.has("shipping");
  const has_shipping_storeOrder = editRowKeysSet.has("shipping_storeOrder");
  if(has_shipping && !has_shipping_storeOrder) {
    editRowKeysSet.add("shipping_storeOrder");
  }
  if(!has_shipping && has_shipping_storeOrder) {
    editRowKeysSet.add("shipping");
  }

  let convertRowIdxs:number[] = [];
  if(editedRowsTop?.length) {


    const ymds:YMD[] = state.ymds;
    const rowInfos:RowInfo[] = state.rowInfos;
    const newRows = state.rows;

    //編集したdataGroups
    editedRowsTop.forEach(row => {
      const idx = rowToIdx(row, pageInfo, state.fixedRowsTop);;
      let rowInfo = rowInfos[idx];

      switch (rowInfo.TP) {
        case "total":
          {
            let rowCount = state.fixedRowsTop;
            for (let index = 0; index < rowCount; index++) {
              if(editRowKeysSet.has(rowInfos[index].rowKey)) {
                convertRowIdxs.push(index);
              }
            }
          }
          break;
        case "maker":
        case "center":
        case "item":
        case "info":
          {
            let rowCount = rowInfos[idx].rowCount;
            for (let index = 0; index < rowCount; index++) {
              if(editRowKeysSet.has(rowInfos[idx + index].rowKey)) {
                convertRowIdxs.push(idx + index);
              }
            }
          }
          break;
        default:
          break;
      }
    });

    convertRowIdxs.forEach(idx => {
      //表示範囲ならば
      if(idx < state.fixedRowsTop || !pageInfo || (pageInfo.rowInfoIdxFrom <= idx && idx <= pageInfo.rowInfoIdxTo)) {
        const rowInfo = state.rowInfos[idx];
        const dataGroup = getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal);
        newRows[idxToRow(idx, pageInfo, state.fixedRowsTop)] = convertRow(dataGroup, rowInfo, colRowModel, ymds, visibleAttributes, numUnit, onlyNormal, result_type);
      }
    });
  }
  let commentRowIdxs:number[] = [];
  if(commentRows?.length) {
    commentRowIdxs = commentRows.map(row => rowToIdx(row, pageInfo, state.fixedRowsTop));
  }

  const tableVer_updateData_rows = [];
  const updateRowIdxs = arrayutil.union(convertRowIdxs, commentRowIdxs);
  updateRowIdxs.forEach(idx => {
    //表示範囲ならば
    tableVer_updateData_rows.push(idxToRow(idx, pageInfo, state.fixedRowsTop));
  });

  if(tableVer_updateData_rows.length) {
    state.tableVer_updateData_rows = tableVer_updateData_rows;
    state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
  }

}

const createReducerContent = ():MainPageTmpReducer => {return {
  setEditingStart(state:MainPageTmpState) {
    console.log('store.setEditingStart');
    state.editing = true;
    state.retrievedEditSeq = {};

    state.alertSetting = {}; //検索条件
    state.retrieveParam = null;  //検索条件
  },
  setEditingEnd(state:MainPageTmpState) {
    console.log('store.setEditingEnd');

    state.editing = false;
    state.retrievedEditSeq = {};

    state.alertSetting = {}; //検索条件
    state.retrieveParam = null;  //検索条件
  
    state.progress = {};
    state.retrievedParam = null;
  
    state.ymds = [];
    state.centerItems = [];
    state.itemTagDatas = [];
    state.makerTagDatas = [];
    state.makerLimitDatas = [];
    state.centerCDs = [];
    state.itemCDs = [];
  
    state.dataGroupTotal = null;
    state.dataGroups = [];
    state.rowInfos = [];
    state.fixedRowsTop = 0;
    state.rows = [];
    state.mergeCells = null;
    state.makerIndex = 0;
    state.selectedMaker = null;
    state.pageStatus = null;
    state.pagingInfo = {};

    // errorMessage: null,


    //テーブル更新
    state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

  },
  putProgress(state:MainPageTmpState, action: PayloadAction<string>) {
    const key = action.payload;
    const progressNew = {...state.progress};
    progressNew[key] = true;
    state.progress = progressNew;
  },
  removeProgress(state:MainPageTmpState, action: PayloadAction<string>) {
    const key = action.payload;
    const progressNew = {};
    Object.keys(state.progress).forEach(k => {
      if(key != k) {
        progressNew[k] = true;
      }
    })
    state.progress = progressNew;
  },
  setErrorMessage(state:MainPageTmpState, action: PayloadAction<string>) {
    state.errorMessage = action.payload;
  },
  // setBumonList(state:MainPageTmpState, action: PayloadAction<CodeName[]>) {
  //   state.bumonList = action.payload;
  // },
  setMakerList(state:MainPageTmpState, action: PayloadAction<CodeName[]>) {
    state.makerList = action.payload;
  },
  setAreaList(state:MainPageTmpState, action: PayloadAction<CodeName[]>) {
    state.areaList = action.payload;
  },
  setCenterList(state:MainPageTmpState, action: PayloadAction<CodeName[]>) {
    state.centerList = action.payload;
  },
  setTagsList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
    state.tagsList = action.payload;
  },
  setOrderUserList(state: MainPageTmpState, action: PayloadAction<CodeName[]>) {
    state.orderUserList = action.payload;
  },
  startRetrieve(state:MainPageTmpState, action: PayloadAction<{
    alertSetting: AlertSettings, //検索条件
    retrieveParam: mainPageRetrieve.RequestParam,  //検索条件
  }>) {
    state.alertSetting = action.payload.alertSetting;
    state.retrieveParam = action.payload.retrieveParam;
  },
  retrieveStart(state:MainPageTmpState, action: PayloadAction<{}>) {
    //検索開始
    state.searchedTargets = false;
    state.searchedBefore = false;
    state.searchedItemTags = false;
    state.searchedMakerTags = false;
    state.searchedComments = false;
    state.searchedResults = false;
  },
  setRetrievedParam(state:MainPageTmpState, action: PayloadAction<mainPageRetrieve.RequestParam>) {
    state.retrievedParam = action.payload;
    if (state.retrievedParam?.makerUnit) {
      const makerUnit = state.retrievedParam?.makerUnit;
      state.selectedMaker = makerUnit.selectedMaker;
      state.makerIndex = makerUnit.makerList.findIndex(codeName => codeName.code == makerUnit.selectedMaker.code);
    }
    else {
      state.selectedMaker = initialState.selectedMaker;
      state.makerIndex = initialState.makerIndex;
    }
    state.saveQueue = [];
    state.loadQueue = [];
  },
  initRetrievedEditSeq(state:MainPageTmpState, action: PayloadAction<DiffEditSeq>) {
    console.log('store.initRetrievedEditSeq');
    const diffEditSeq = action.payload;
    if(!state.retrievedEditSeq.itemTagEditSeq && diffEditSeq.itemTagEditSeq) {
      state.retrievedEditSeq.itemTagEditSeq = diffEditSeq.itemTagEditSeq;
    }
    if(!state.retrievedEditSeq.commentEditSeq && diffEditSeq.commentEditSeq) {
      state.retrievedEditSeq.commentEditSeq = diffEditSeq.commentEditSeq;
    }
    if(!state.retrievedEditSeq.transferEditSeq && diffEditSeq.transferEditSeq) {
      state.retrievedEditSeq.transferEditSeq = diffEditSeq.transferEditSeq;
    }
    if(!state.retrievedEditSeq.shippingEditSeq && diffEditSeq.shippingEditSeq) {
      state.retrievedEditSeq.shippingEditSeq = diffEditSeq.shippingEditSeq;
    }
    if(!state.retrievedEditSeq.warehousingEditSeq && diffEditSeq.warehousingEditSeq) {
      state.retrievedEditSeq.warehousingEditSeq = diffEditSeq.warehousingEditSeq;
    }
    if(!state.retrievedEditSeq.furikaeEditSeq && diffEditSeq.furikaeEditSeq) {
      state.retrievedEditSeq.furikaeEditSeq = diffEditSeq.furikaeEditSeq;
    }
  },
  updateRetrievedEditSeq(state:MainPageTmpState, action: PayloadAction<DiffEditSeq>) {
    console.log('store.updateRetrievedEditSeq');
    const diffEditSeq = action.payload;
    if(diffEditSeq.itemTagEditSeq) {
      state.retrievedEditSeq.itemTagEditSeq = diffEditSeq.itemTagEditSeq;
    }
    if(diffEditSeq.commentEditSeq) {
      state.retrievedEditSeq.commentEditSeq = diffEditSeq.commentEditSeq;
    }
    if(diffEditSeq.transferEditSeq) {
      state.retrievedEditSeq.transferEditSeq = diffEditSeq.transferEditSeq;
    }
    if(diffEditSeq.shippingEditSeq) {
      state.retrievedEditSeq.shippingEditSeq = diffEditSeq.shippingEditSeq;
    }
    if(diffEditSeq.warehousingEditSeq) {
      state.retrievedEditSeq.warehousingEditSeq = diffEditSeq.warehousingEditSeq;
    }
    if(diffEditSeq.furikaeEditSeq) {
      state.retrievedEditSeq.furikaeEditSeq = diffEditSeq.furikaeEditSeq;
    }
  },
  setFunctionKey(state:MainPageTmpState, action: PayloadAction<string>) {
    state.functionKeys = action.payload ? [action.payload] : [];
  },
  setOpenDialog(state:MainPageTmpState, action: PayloadAction<boolean>) {
    state.openDialog = action.payload;
  },
  setPageStatus(state: MainPageTmpState, action: PayloadAction<string>) {
    state.pageStatus = action.payload;
  },

  searchedTargets(state:MainPageTmpState, action: PayloadAction<{
    optionBumon: CodeName, 
    param: mainPageRetrieve.RequestParam, ymds: YMD[], centerItems: CenterItem[], 
    colRowModel:MainPageColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[],
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string}>) {

    console.log('store.searchedTargets');

    const optionBumon = action.payload.optionBumon;
    const colRowModel = action.payload.colRowModel;
    const listSortOrder = action.payload.listSortOrder;
    const listSortOrderDesc = action.payload.listSortOrderDesc;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    const visibleRowsKey = action.payload.visibleRowsKey;
    const pagingUnit = action.payload.pagingUnit;

    const param = action.payload.param;
    const onlyNormal = optionBumon.option5 == '1';
    const result_type = param.result_type;

    const itemCodes = state.retrievedParam?.itemCodes ?? [];

    let ymds = parseDataYMD(action.payload.ymds);
    let centerItems_tmp = parseDataCenterItem(action.payload.centerItems);
    
    let [dataGroups, centerItems] = convertDataGroups(ymds, centerItems_tmp);   //子センター分が追加されている
    
    // dataGroups = calcDataGroups(dataGroups, ymds, 0, ymds.length-1, true, true);  //計算
    // dataGroups = checkDataGroups(dataGroups, ymds, 0, ymds.length-1); //データチェック
    // dataGroups = highlightDataGroups(dataGroups, ymds, 0, ymds.length-1); //ハイライト

    const singleCenter = Array.from(new Set<string>(centerItems.map(centerItem => centerItem.centerCD))).length == 1; //単独センター
    dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc, singleCenter, itemCodes);  //並び替え
    let dataGroupTotal = createDataGroupTotal(ymds, dataGroups, showSubTotal, showGroupRow);  //合計計算
    const rowInfos = convertRowInfos(optionBumon, dataGroupTotal, dataGroups, listSortOrder, showSubTotal, showGroupRow, visibleRowsKey, null);

    //store更新
    state.ymds = ymds;
    state.centerItems = centerItems;
    state.centerCDs = Array.from(new Set<string>(centerItems.map(centerItem => centerItem.centerCD)));
    state.itemCDs = Array.from(new Set<string>(centerItems.map(centerItem => centerItem.itemCD)));

    const pagingInfo = pagingInit(dataGroups, dataGroupTotal, rowInfos, "", pagingUnit, listSortOrder, state.pageStatus);
    udpateDataGroupTotal(dataGroupTotal, dataGroups, rowKeys, ymds, 0, ymds.length-1, showSubTotal, showGroupRow);  //合計計算

    state.dataGroupTotal = dataGroupTotal;
    state.dataGroups = dataGroups;
    state.rowInfos = rowInfos;
    
    state.pagingInfo = pagingInfo;
    state.pageStatus = null;
    state.mergeCells = createMergeCells(rowInfos, pagingInfo, colRowModel);
    const [rows, fixedRowsTop] = convertRows(dataGroups, dataGroupTotal, rowInfos, pagingInfo, colRowModel, ymds, visibleAttributes, numUnit, onlyNormal, result_type);
    state.fixedRowsTop = fixedRowsTop;
    state.rows = rows;

    //検索完了
    state.searchedTargets = true;
    //テーブル更新
    state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    //実績検索トリガ
    state.retrieveBefore_ver = state.retrieveBefore_ver+1;
  },
  searchedBefore(state:MainPageTmpState, action: PayloadAction<{}>) {
    console.log('store.searchedBefore');
    //デフォルトスクロール
    state.tableVer_scrollDefault = state.tableVer_scrollDefault+1;
    
    //検索完了
    state.searchedBefore = true;

    //検索
    const pagingInfo:PagingInfo = state.pagingInfo;
    const targetRowInfos = state.rowInfos.filter((rowInfo, idx) => 
      rowInfo.TP == 'info'
      && rowInfo.rowIndex == 0 
      && (
        pagingInfo?.selectedPage?.value == 'ALL' ||  //全レコード
        (pagingInfo.selectedPage.rowInfoIdxFrom <= idx && idx <= pagingInfo.selectedPage.rowInfoIdxTo)  //ページのみ
      )
    );
    fnc_searchContents(state, targetRowInfos);
  },
  searchedItemTags(state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[], itemTagDatas: ItemTagData[], itemCommonDatas: ItemCommonData[], disableDateDatas: DisableDateData[],
    colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) {

    console.log('store.searchedItemTags');
    const targetCenterItems = action.payload.centerItems;
    const onlyNormal = action.payload.onlyNormal;

    // const listSortOrder = action.payload.listSortOrder;
    const colRowModel = action.payload.colRowModel;
    // const listSortOrderDesc = action.payload.listSortOrderDesc;
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    // const visibleRowsKey = action.payload.visibleRowsKey;

    const result_type = action.payload.result_type;

    const ymds: YMD[] = state.ymds;

    let itemTagDatas = parseItemTagData(action.payload.itemTagDatas);
    let itemCommonDatas = parseItemCommonData(action.payload.itemCommonDatas);
    let disableDateDatas = parseDisableDateData(action.payload.disableDateDatas);

    let dataGroups = state.dataGroups;
    let dataGroupTotal = state.dataGroupTotal;
    const [dataGroupsChanged, dataGroupsAll] = updateDataGroups_itemTags(targetCenterItems, dataGroups, itemTagDatas, itemCommonDatas, disableDateDatas);
    dataGroups = dataGroupsAll;

    // let dataGroupTotal = state.dataGroupTotal;

    let rowInfos = state.rowInfos;
    const [idxs, rowInfosAll] = updateRowInfos(state, rowInfos, null, dataGroups, null);
    rowInfos = rowInfosAll;

    //store更新
    state.itemTagDatas = itemTagDatas;
    state.dataGroups = dataGroups;
    state.rowInfos = rowInfos;

    //検索完了
    state.searchedItemTags = true;

    //コメント、タグが検索できれば実績を取得する
    if(state.searchedComments
      && state.searchedItemTags
      && state.searchedMakerTags
      && !state.searchedResults
    ) {
      //実績検索トリガ
      state.retrieveResult_centerItems = targetCenterItems; //表示分のみ
      state.retrieveResult_ver = state.retrieveResult_ver+1;
    }
    //タグの更新時はupdate
    else if(state.searchedComments
      && state.searchedItemTags
      && state.searchedMakerTags
      && state.searchedResults
    ) {
      const pagingInfo = state.pagingInfo;
      const pageInfo = pagingInfo?.selectedPage;
      const [rows, fixedRowsTop] = convertRows(dataGroups, dataGroupTotal, rowInfos, pagingInfo, colRowModel, ymds, visibleAttributes, numUnit, onlyNormal, result_type);
      state.fixedRowsTop = fixedRowsTop;
      state.rows = rows;
  
      let rowIndexes = [];
      idxs.forEach(idx => {
        rowIndexes.push(idxToRow(idx, pageInfo, state.fixedRowsTop));
      });
      if(rowIndexes.length) {
        state.tableVer_updateData_rows = rowIndexes;  //更新行のみ
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    }

  },

  searchedMakerTags(state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[], makerTagDatas: MakerTagData[],
    colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], holidays:any, alertSetting:AlertSettings
  }>) {

    console.log('store.searchedMakerTags');
    const targetCenterItems = action.payload.centerItems;

    // const listSortOrder = action.payload.listSortOrder;
    const colRowModel = action.payload.colRowModel;
    // const listSortOrderDesc = action.payload.listSortOrderDesc;
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    // const visibleRowsKey = action.payload.visibleRowsKey;
    const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;

    const ymds: YMD[] = state.ymds;

    let makerTagDatas = parseMakerTagData(action.payload.makerTagDatas);

    let dataGroups = state.dataGroups;
    const [dataGroupsChanged, dataGroupsAll] = updateDataGroups_makerTags(targetCenterItems, dataGroups, makerTagDatas);
    dataGroups = dataGroupsAll;
    dataGroups = highlightDataGroups(dataGroups, ymds, 0, ymds.length-1, holidays, alertSetting); //ハイライト

    // let dataGroupTotal = state.dataGroupTotal;

    // let rowInfos = state.rowInfos;
    // const [rows, rowInfosAll] = updateRowInfos(rowInfos, null, dataGroups);
    // rowInfos = rowInfosAll;

    //store更新
    state.makerTagDatas = makerTagDatas;
    state.dataGroups = dataGroups;

    //検索完了
    state.searchedMakerTags = true;

    //コメント、タグが検索できれば実績を取得する
    if(state.searchedComments
      && state.searchedItemTags
      && state.searchedMakerTags
      && !state.searchedResults
    ) {
      //実績検索トリガ
      state.retrieveResult_centerItems = targetCenterItems; //表示分のみ
      state.retrieveResult_ver = state.retrieveResult_ver+1;
    }
    // //タグの更新時はupdate
    // else if(state.searchedComments
    //   && state.searchedItemTags
    //   && state.searchedMakerTags
    //   && state.searchedResults
    // ) {
    //   const pagingInfo = state.pagingInfo;
    //   const pageInfo = pagingInfo?.selectedPage;
    //   const [rows, fixedRowsTop] = convertRows(rowInfos, pagingInfo, colRowModel, ymds, visibleAttributes, numUnit);
    //   state.fixedRowsTop = fixedRowsTop;
    //   state.rows = rows;
  
    //   let rowIndexes = [];
    //   idxs.forEach(idx => {
    //     if(!pageInfo || (pageInfo.rowInfoIdxFrom <= idx && idx <= pageInfo.rowInfoIdxTo)) {
    //       rowIndexes.push(idx - pageInfo.rowInfoIdxFrom);
    //     }
    //   });
    //   state.tableVer_updateData_rows = rowIndexes;  //更新行のみ
    //   state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
    // }

  },

  searchedComments(state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[], comments:CommentData[]}>) {

    console.log('store.searchedComments');
    const targetCenterItems = action.payload.centerItems;

    let comments = parseDataCommentData(action.payload.comments);

    let dataGroups = state.dataGroups;
    const [dataGroupsChanged, dataGroupsAll] = updateDataGroups_comments(targetCenterItems, dataGroups, comments);
    dataGroups = dataGroupsAll;

    // let dataGroupTotal = state.dataGroupTotal;

    let rowInfos = state.rowInfos;
    const [idxs, rowInfosAll] = updateRowInfos(state, rowInfos, null, dataGroups, null);
    rowInfos = rowInfosAll;

    const ymds: YMD[] = state.ymds;

    //store更新
    state.dataGroups = dataGroups;
    state.rowInfos = rowInfos;
    //検索完了
    state.searchedComments = true;

    //コメント、タグが検索できれば実績を取得する
    if(state.searchedComments
      && state.searchedItemTags
      && state.searchedMakerTags
      && !state.searchedResults
    ) {
      //実績検索トリガ
      state.retrieveResult_centerItems = targetCenterItems; //表示分のみ
      state.retrieveResult_ver = state.retrieveResult_ver+1;
    }
  },
  searchedResults(state:MainPageTmpState, action: PayloadAction<{
    centerItems: CenterItem[], 
    makerLimitDatas: MakerLimitData[],
    infosStoreSales:InfoDataStoreSales[], 
    infosTransferIn:InfoDataTransfer[], 
    infosTransferOut:InfoDataTransfer[], 
    infosWarehousing:InfoDataWarehousing[], 
    infosWarehousingLast:InfoDataWarehousing[], 
    infosOrder:InfoDataOrder[], 
    infosBestBefore:InfoDataBestBefore[], 
    infosStoreOrder:InfoDataStoreOrder[], 
    infosShipping:InfoDataShipping[], 
    infosShippingLast:InfoDataShipping[], 
    infosShippingTokubai:InfoDataShippingTokubai[], 
    infosShippingTokubaiLast:InfoDataShippingTokubai[], 
    infosStock:InfoDataStock[], 
    infosWeather: OpenMeteoDaily[],
    colRowModel:MainPageColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[], holidays:any, alertSetting:AlertSettings,
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string,
    onlyNormal:boolean,
    result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) {

    console.log('store.searchedResults');
    const targetCenterItems = action.payload.centerItems;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const listSortOrder = action.payload.listSortOrder;
    const colRowModel = action.payload.colRowModel;
    const listSortOrderDesc = action.payload.listSortOrderDesc;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    const visibleRowsKey = action.payload.visibleRowsKey;
    const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;
    const pagingUnit = action.payload.pagingUnit;

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;

    const ymds: YMD[] = state.ymds;
    const itemCodes = state.retrievedParam?.itemCodes ?? [];

    let makerLimitDatas = parseMakerLimitData(action.payload.makerLimitDatas);
    let infoStoreSales = parseDataInfoDataStoreSales(action.payload.infosStoreSales);
    let infoTransferIn = parseDataInfoDataTransfer(action.payload.infosTransferIn);
    let infoTransferOut = parseDataInfoDataTransfer(action.payload.infosTransferOut);
    let infoWarehousing = parseDataInfoDataWarehousing(action.payload.infosWarehousing);
    let infoWarehousingLast = parseDataInfoDataWarehousing(action.payload.infosWarehousingLast);
    let infoOrder = parseDataInfoDataOrder(action.payload.infosOrder);
    let infoBestBefore = parseDataInfoDataBestBefore(action.payload.infosBestBefore);
    let infoStoreOrder = parseDataInfoDataStoreOrder(action.payload.infosStoreOrder);
    let infoShipping = parseDataInfoDataShipping(action.payload.infosShipping);
    let infoShippingLast = parseDataInfoDataShipping(action.payload.infosShippingLast);
    let infoShippingTokubai = parseDataInfoDataShippingTokubai(action.payload.infosShippingTokubai);
    let infoShippingTokubaiLast = parseDataInfoDataShippingTokubai(action.payload.infosShippingTokubaiLast);
    let infoStock = parseDataInfoDataStock(action.payload.infosStock);
    let infosWeather = parseDataInfoDataWeather(action.payload.infosWeather);
    
    let dataGroups = state.dataGroups;
    dataGroups = updateDataGroups_infos(
        ymds,
        targetCenterItems, dataGroups, 
        infoStoreSales, 
        infoTransferIn, 
        infoTransferOut, 
        infoWarehousing, 
        infoWarehousingLast, 
        infoOrder,
        infoBestBefore, 
        infoStoreOrder, 
        infoShipping, 
        infoShippingLast, 
        infoShippingTokubai,
        infoShippingTokubaiLast,
        infoStock,
        infosWeather,
        state.retrievedParam?.todayType
    );
    const dataGroupsChanged = dataGroups.filter(dataGroup=> dataGroup.searchedResults && !dataGroup.calced3); //更新レコード
    dataGroups = calcDataGroups(dataGroups, rowKeys, ymds, 0, ymds.length-1, false, false, averageWeeks);  //計算
    dataGroups = updateDataGroups_makerLimits(targetCenterItems, dataGroups, makerLimitDatas);
    
    makerLimitDatas = calcMakerLimitDatas(makerLimitDatas, dataGroups);

    dataGroups = checkDataGroups(dataGroups, ymds, 0, ymds.length-1); //データチェック
    dataGroups = highlightDataGroups(dataGroups, ymds, 0, ymds.length-1, holidays, alertSetting); //ハイライト

    /*
    const doNarrow:boolean = alertSetting && !(
      (!alertSetting.stockMdaysOrMore && alertSetting.stockMdaysOrMore !== 0) &&
      (!alertSetting.stockMinusAfterNdays && alertSetting.stockMinusAfterNdays !== 0) &&
      (!alertSetting.storeOrderNover && alertSetting.storeOrderNover !== 0) &&
      (!alertSetting.storeOrderNtimes && alertSetting.storeOrderNtimes !== 0)
    )
    ;

    //絞り込み
    if(doNarrow) {
      dataGroups = dataGroups.filter(dataGroup => !dataGroup.highlighted || dataGroup.hasAlert);
    } 
    */
    const singleCenter = state.centerCDs?.length == 1; //単独センター
    dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc, singleCenter, itemCodes);  //並び替え
    let dataGroupTotal = createDataGroupTotal(ymds, dataGroups, showSubTotal, showGroupRow);  //合計計算

    //絞り込み
    let rowInfos:RowInfo[];
    let idxs;
    /*
    if(doNarrow) {
      rowInfos = convertRowInfos(dataGroupTotal, dataGroups, listSortOrder, showSubTotal, visibleRowsKey, null);
    } 
    else 
    */
    {
      rowInfos = state.rowInfos;
      // const [idxsTmp, rowInfosTmp] = updateRowInfos(rowInfos, dataGroupTotal, dataGroups, alertSetting);
      const [idxsTmp, rowInfosTmp] = updateRowInfos(state, rowInfos, dataGroupTotal, dataGroupsChanged, alertSetting);
      rowInfos = rowInfosTmp;
      idxs = idxsTmp;
    }

    let pagingInfo = state.pagingInfo;
    udpateDataGroupTotal(dataGroupTotal, dataGroups, rowKeys, ymds, 0, ymds.length-1, showSubTotal, showGroupRow);  //合計計算

    //store更新
    state.makerLimitDatas = makerLimitDatas;
    state.dataGroupTotal = dataGroupTotal;
    state.dataGroups = dataGroups;
    state.rowInfos = rowInfos;
    
    //絞り込み もしくは全選択
    //if(doNarrow || pagingInfo.selectedPage?.value == 'ALL') {
    if(pagingInfo.selectedPage?.value == 'ALL') {
      pagingInfo = pagingInit(dataGroups, dataGroupTotal, rowInfos, pagingInfo.selectedPage?.value, pagingUnit, listSortOrder, state.pageStatus);
      state.pagingInfo = pagingInfo;
      state.pageStatus = null;
    } 
    
    const pageInfo = pagingInfo?.selectedPage;
    const pageInfo2 = pagingInfo.pages.find(page => page.value == pageInfo.value);
    const fnc_setPageInfoSearched = (pageInfo:PageInfo) => {
      let searched = true;
      //実績取得済
      rowInfos
      .filter((rowInfo, index) => rowInfo.TP == 'info' && rowInfo.rowIndex == 0 && pageInfo.rowInfoIdxFrom <= index  && index <= pageInfo.rowInfoIdxTo)
      .forEach(rowInfo => {
        if(searched && !dataGroups[rowInfo.dataGroupIndex]?.searchedResults) {
          searched=false; //実績未取得
        }
      });
      if(searched) {
        pageInfo.searched = true;
      }
    }
    if(pageInfo) {
      fnc_setPageInfoSearched(pageInfo);
    }
    if(pageInfo2) {
      fnc_setPageInfoSearched(pageInfo2);
    }

    state.mergeCells = createMergeCells(rowInfos, pagingInfo, colRowModel);
    const [rows, fixedRowsTop] = convertRows(dataGroups, dataGroupTotal, rowInfos, pagingInfo, colRowModel, ymds, visibleAttributes, numUnit, onlyNormal, result_type);
    state.fixedRowsTop = fixedRowsTop;
    state.rows = rows;

    //検索完了
    state.searchedResults = true;
    state.searchedResults_weather = visibleRowsKey?.indexOf("weather") !== -1;      //天気
    state.searchedResults_storeSales = visibleRowsKey?.indexOf("storeSales") !== -1;   //店頭売上",
    state.searchedResults_bestBefore = visibleRowsKey?.indexOf("bestBefore") !== -1;   //賞味期限",
    state.searchedResults_warehousing_last = visibleRowsKey?.indexOf("warehousing_last") !== -1; 
    state.searchedResults_shipping_last = visibleRowsKey?.indexOf("shipping_last") !== -1; 
    
    //非表示行があればupdateSettings
    /*
    if(doNarrow) {
      //テーブル更新
      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    }
    else 
    */
    {
      let rows;
      if(idxs) {
        rows = [];
        idxs.forEach(idx => {
          rows.push(idxToRow(idx, pageInfo, state.fixedRowsTop));
        });
      }
      if(rows.length) {
        state.tableVer_updateData_rows = rows;  //更新行のみ
        state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    }


    //Excel出力予約があれば出力
    if(state.excelQueue) {
      state.excelVer = state.excelVer + 1;
    }
    //自動発注予約があれば実行
    if(state.autoOrderQueue) {
      state.autoOrderVer = state.autoOrderVer + 1;
    }
    //暫定発注取込予約があれば実行
    if(state.provisionalQueue) {
      state.provisionalVer = state.provisionalVer + 1;
    }

  },
  searchedShippingGroup1s (state:MainPageTmpState, action: PayloadAction<{
    optionBumon: CodeName, 
    centerItems: CenterItem[], 
    infosShippingGroup1s:InfoDataShippingGroup1[], 
    colRowModel:MainPageColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[], 
    // holidays:any, 
    alertSetting:AlertSettings,
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string,
    onlyNormal:boolean,
    result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) {
    console.log('store.searchedShippingGroup1s');
    const targetCenterItems = action.payload.centerItems;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const optionBumon = action.payload.optionBumon;
    const listSortOrder = action.payload.listSortOrder;
    const colRowModel = action.payload.colRowModel;
    const listSortOrderDesc = action.payload.listSortOrderDesc;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    const visibleRowsKey = action.payload.visibleRowsKey;
    // const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;
    const pagingUnit = action.payload.pagingUnit;

    const ymds: YMD[] = state.ymds;

    let infosShippingGroup1s = parseDataInfoDataShippingGroup1(action.payload.infosShippingGroup1s);
    
    let dataGroups = state.dataGroups;
    dataGroups = updateDataGroups_infos_shipping_group1(targetCenterItems, dataGroups, infosShippingGroup1s);

    let dataGroupTotal = state.dataGroupTotal;

    const dataGroupsChanged = []; //dataGroups.filter(dataGroup=> dataGroup.searchedResults && !dataGroup.calced3); //更新レコード

    const rowInfos = convertRowInfos(optionBumon, dataGroupTotal, dataGroups, listSortOrder, showSubTotal, showGroupRow, visibleRowsKey, alertSetting);

    const pagingInfo = pagingInit(dataGroups, dataGroupTotal, rowInfos, state.pagingInfo.selectedPage?.value, pagingUnit, listSortOrder, state.pageStatus);
    // udpateDataGroupTotal(dataGroupTotal, dataGroups, rowKeys, ymds, 0, ymds.length-1, showSubTotal, showGroupRow);  //合計計算

    //store更新
    state.dataGroupTotal = dataGroupTotal;
    state.dataGroups = dataGroups;
    state.rowInfos = rowInfos;

    state.pagingInfo = pagingInfo;
    state.pageStatus = null;
    state.mergeCells = createMergeCells(rowInfos, pagingInfo, colRowModel);
    const [rows, fixedRowsTop] = convertRows(dataGroups, dataGroupTotal, rowInfos, pagingInfo, colRowModel, ymds, visibleAttributes, numUnit, onlyNormal, result_type);
    state.fixedRowsTop = fixedRowsTop;
    state.rows = rows;

    //テーブル更新
    state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
  },
  searchContents (state:MainPageTmpState, action: PayloadAction<{type:'all'|'page'|'target'|string, centerItems?:CenterItem[]}>) {
   
    console.log('searchContents');
    const type = action.payload.type;
    const centerItems = action.payload.centerItems;
    const centerItemMap = {};
    if(type == 'target') {
      centerItems.forEach(centerItem => {
        const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
        centerItemMap[key] = key;
      })
    }
    //検索
    const pagingInfo = state.pagingInfo;
    const targetRowInfos = state.rowInfos.filter((rowInfo, idx) => {
      const rowInfo_dataGroup = getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal);

      return rowInfo.TP == 'info'
        && rowInfo.rowIndex == 0 
        && (
          (type == 'all') ||
          (type == 'page' && (
            pagingInfo?.selectedPage?.value == 'ALL' ||  //全レコード
            (pagingInfo.selectedPage.rowInfoIdxFrom <= idx && idx <= pagingInfo.selectedPage.rowInfoIdxTo)  //ページのみ
          )) ||
          (type == 'target' && centerItemMap[`${rowInfo_dataGroup?.centerItem?.itemCD} ${rowInfo_dataGroup?.centerItem?.centerCD}`])
        );
    }
    )
    const startRetrieve = fnc_searchContents(state, targetRowInfos);
    if(!startRetrieve) {
      //Excel出力予約があれば出力
      if(state.excelQueue) {
        state.excelVer = state.excelVer + 1;
      }
      //自動発注予約があれば実行
      if(state.autoOrderQueue) {
        state.autoOrderVer = state.autoOrderVer + 1;
      }
      //暫定発注取込予約があれば実行
      if(state.provisionalQueue) {
        state.provisionalVer = state.provisionalVer + 1;
      }
    }
    
  },
  reSearchResults(state:MainPageTmpState, action: PayloadAction<{result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) {
    console.log('store.reSearchResults');

    const result_type = action.payload.result_type;
    //検索条件を上書き
    state.retrievedParam.result_type = result_type;

    //再検索のフラグ設定
    // state.searchedBefore = false;
    // state.searchedItemTags = false;
    // state.searchedMakerTags = false;
    // state.searchedComments = false;
    state.searchedResults = false;

    state.dataGroups.forEach(dataGroup => {
      // dataGroup.searchedBefore = false;
      // dataGroup.searchedItemTags = false;
      // dataGroup.searchedMakerTags = false;
      // dataGroup.searchedComments = false;
      dataGroup.searchedResults = false;
      dataGroup.calced1 = false;
      dataGroup.calced2 = false;
      dataGroup.calced3 = false;

      dataGroup.shipping_group1s_key = null;
      dataGroup.shipping_group1s_name = null;
    });

    //編集Seqクリア
    // state.retrievedEditSeq = {};

    // state.retrieveResult_ver = state.retrieveResult_ver+1;
  },
  clearTable(state:MainPageTmpState, action: PayloadAction<{}>){
    console.log('clearTable');

    state.rowInfos = [];
    state.fixedRowsTop = 0;
    state.rows = [];
    state.mergeCells = null;

    const newDataGroups = [];
    state.dataGroups.forEach(dataGroup => {
      if (dataGroup.displayed) {
        const newDataGroup: RowDataGroup = {
          ymdInfos: dataGroup.ymdInfos.map(ymdInfo => {
            return { ...ymdInfo, comments: {}, infos: {}, highlightInfo: {} };
          }),
          centerItem: dataGroup.centerItem,
        };
        newDataGroups.push({
          ...newDataGroup,
          displayed: false,
        });
      }
      else {
        newDataGroups.push(dataGroup);
      }
    });
    state.dataGroups = newDataGroups;

    //テーブル更新
    state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    //デフォルトスクロール
    state.tableVer_scrollDefault = state.tableVer_scrollDefault+1;
  },
  refreshTableColumns(state:MainPageTmpState, action: PayloadAction<{ymds: YMD[]}>){
    console.log('refreshTableColumns');

    state.ymds = action.payload.ymds;

    //テーブル更新
    state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    //デフォルトスクロール
    // state.tableVer_scrollDefault = state.tableVer_scrollDefault+1;
  },
  refreshTable(state:MainPageTmpState, action: PayloadAction<{
    optionBumon: CodeName, 
    colRowModel:MainPageColRowModel, pageValue:string, listSortOrder:CodeName, listSortOrderDesc:boolean, 
    showSubTotal:boolean, 
    showGroupRow:boolean, 
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], visibleRowsKey:string[], holidays:any, alertSetting:AlertSettings,
    pagingUnit:'group'|'maker'|'50'|'100'|'200'|'300'|string,
    checkForced?: boolean,
    result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>){
    console.log('refreshTable');
    const optionBumon = action.payload.optionBumon;
    const listSortOrder = action.payload.listSortOrder;
    const listSortOrderDesc = action.payload.listSortOrderDesc;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    const visibleRowsKey = action.payload.visibleRowsKey;
    const colRowModel = action.payload.colRowModel;
    const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;
    const checkForced = action.payload.checkForced;
    const pagingUnit = action.payload.pagingUnit;
    const ymds = state.ymds;
    const itemCodes = state.retrievedParam?.itemCodes ?? [];

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;

    const onlyNormal = optionBumon.option5 == '1';
    const result_type = action.payload.result_type;

    let dataGroups = state.dataGroups;
    dataGroups = calcDataGroups(dataGroups, rowKeys, ymds, 0, ymds.length-1, true, true, averageWeeks);  //計算
    dataGroups = checkDataGroups(dataGroups, ymds, 0, ymds.length-1); //データチェック
    dataGroups = highlightDataGroups(dataGroups, ymds, 0, ymds.length-1, holidays, alertSetting, checkForced); //ハイライト
    // dataGroups = narrowDataGroups(dataGroups, alertSetting);  //絞り込み
    const singleCenter = state.centerCDs?.length == 1; //単独センター
    dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc, singleCenter, itemCodes);  //並び替え
    let dataGroupTotal = createDataGroupTotal(ymds, dataGroups, showSubTotal, showGroupRow);  //合計計算
    const rowInfos = convertRowInfos(optionBumon, dataGroupTotal, dataGroups, listSortOrder, showSubTotal, showGroupRow, visibleRowsKey, alertSetting);

    //ページ更新
    const pagingInfo = pagingInit(dataGroups, dataGroupTotal, rowInfos, action.payload.pageValue, pagingUnit, listSortOrder, state.pageStatus);
    udpateDataGroupTotal(dataGroupTotal, dataGroups, rowKeys, ymds, 0, ymds.length-1, showSubTotal, showGroupRow);  //合計計算
    //store更新
    state.dataGroupTotal = dataGroupTotal;
    state.dataGroups = dataGroups;
    state.rowInfos = rowInfos;
    state.pagingInfo = pagingInfo;
    state.pageStatus = null;
    
    state.mergeCells = createMergeCells(rowInfos, pagingInfo, colRowModel);
    const [rows, fixedRowsTop] = convertRows(dataGroups, dataGroupTotal, rowInfos, pagingInfo, colRowModel, state.ymds, visibleAttributes, numUnit, onlyNormal, result_type);
    state.fixedRowsTop = fixedRowsTop;
    state.rows = rows;

    state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    //デフォルトスクロール
    state.tableVer_scrollDefault = state.tableVer_scrollDefault+1;

    //検索
    const targetRowInfos = state.rowInfos.filter((rowInfo, idx) => 
      rowInfo.TP == 'info'
      && rowInfo.rowIndex == 0 
      && (
        pagingInfo?.selectedPage?.value == 'ALL' ||  //全レコード
        (pagingInfo.selectedPage.rowInfoIdxFrom <= idx && idx <= pagingInfo.selectedPage.rowInfoIdxTo)  //ページのみ
      )
    );
    fnc_searchContents(state, targetRowInfos);
  },
  renderTable(state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, numUnit:'CS'|'BL'|'PS'|string, visibleAttributes:string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>){
    console.log('renderTable');
    const numUnit = action.payload.numUnit;
    const visibleAttributes = action.payload.visibleAttributes;
    const colRowModel = action.payload.colRowModel;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const rowInfos = state.rowInfos;
    const dataGroups = state.dataGroups;
    const dataGroupTotal = state.dataGroupTotal;

    //store更新
    const pagingInfo = state.pagingInfo;
    const [rows, fixedRowsTop] = convertRows(dataGroups, dataGroupTotal, rowInfos, pagingInfo, colRowModel, state.ymds, visibleAttributes, numUnit, onlyNormal, result_type);
    state.fixedRowsTop = fixedRowsTop;
    state.rows = rows;

    state.tableVer_updateData_rows = null;
    state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
  },
    
  editRowDatas(state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, 
    showSubTotal:boolean,
    showGroupRow:boolean,
    numUnit_view:'CS'|'BL'|'PS'|string, 
    numUnit_input:'LOT'|'CS'|'BL'|'PS'|'auto'|string, 
    wholeOrDiff:'whole'|'diff'|'auto'|string, //全量or差分
    visibleAttributes: string[][], holidays:any, alertSetting:AlertSettings, doConfirm:boolean, editDatas:EditCellInfo[], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) {
    console.log('store.editRowDatas');

    const visibleAttributes = action.payload.visibleAttributes;
    const colRowModel = action.payload.colRowModel;
    const editDatas = action.payload.editDatas;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const numUnit_input = action.payload.numUnit_input;
    const numUnit_view = action.payload.numUnit_view;
    const wholeOrDiff = action.payload.wholeOrDiff;
    const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;
    const doConfirm = action.payload.doConfirm;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;

    const editInfo:EditInfo = {
      numUnit_view: numUnit_view, 
      numUnit_input: numUnit_input, 
      wholeOrDiff: wholeOrDiff, 
      editDatas: editDatas,
    };

    //編集処理本体
    fnc_editRowDatas(
      state, 
      editInfo,
      colRowModel, visibleAttributes, holidays, alertSetting, doConfirm, showSubTotal, showGroupRow, averageWeeks, onlyNormal, result_type);
  },
  confirmdEditInfo (state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, 
    showSubTotal:boolean, 
    showGroupRow:boolean,
    visibleAttributes: string[][], holidays:any, alertSetting:AlertSettings, editInfo:EditInfo, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) {
    console.log('store.confirmdEditInfo');
    const editInfo = action.payload.editInfo;
    if(!editInfo || !editInfo?.editDatas?.length) {
      return ;
    }

    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const visibleAttributes = action.payload.visibleAttributes;
    const colRowModel = action.payload.colRowModel;
    const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;
    // const editDatas = editInfo.editDatas;
    // const numUnit_input = editInfo.numUnit_input;
    // const numUnit_view = editInfo.numUnit_view;
    // const wholeOrDiff = editInfo.wholeOrDiff;
    const doConfirm = false;

    //編集処理本体
    fnc_editRowDatas(
      state, 
      editInfo,
      colRowModel, visibleAttributes, holidays, alertSetting, doConfirm, showSubTotal, showGroupRow, averageWeeks, onlyNormal, result_type);
  },
  clearConfirmEditInfo_warehousing_clear (state:MainPageTmpState) {
    console.log('store.clearConfirmEditInfo_warehousing_clear');
    state.confirmEditInfo_warehousing_clear = null;
  },
  clearConfirmEditInfo_warehousing_holiday (state:MainPageTmpState) {
    console.log('store.clearConfirmEditInfo_warehousing_holiday');
    state.confirmEditInfo_warehousing_holiday = null;
  },
  clearConfirmEditInfo_warehousing_warn(state: MainPageTmpState) {
    console.log('store.clearConfirmEditInfo_warehousing_warn');
    state.confirmEditInfo_warehousing_warn = null;
  },

  editComments(state: MainPageTmpState, action: PayloadAction<{
    colRowModel: MainPageColRowModel, numUnit: 'CS' | 'BL' | 'PS' | string, visibleAttributes: string[][],
    editDatas: EditCellInfo[],
    onlyNormal: boolean, result_type: 'general_teiban' | 'general' | 'general_yoyaku' | 'general_tokubai' | 'all' | string
  }>) {
    console.log('store.editComments');
    const visibleAttributes = action.payload.visibleAttributes;
    const colRowModel = action.payload.colRowModel;
    const editDatas = action.payload.editDatas;
    const numUnit = action.payload.numUnit;
    const rowInfos = state.rowInfos;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    //登録用のキュー
    const addSaveQueue:SaveInfoData[] = [];

    const pagingInfo:PagingInfo = state.pagingInfo;
    const pageInfo:PageInfo = pagingInfo?.selectedPage;

    let editedIdxsTop:Set<number> = new Set<number>();
    let editRowKeysSet:Set<string> = new Set<string>();
    let commentIdxs:Set<number> = new Set<number>();
    editDatas.forEach((editData) => {
      const idx = rowToIdx(editData.row, pageInfo, state.fixedRowsTop);
      const rowInfo_edit:RowInfo = rowInfos[idx]; //編集行
      const rowInfo_top:RowInfo = rowInfos[idx - rowInfo_edit.rowIndex]; //一番上のデータを書き換える
      if (!rowInfo_edit || !rowInfo_top) {
        return;
      }
      const dataGroup = getRowInfoDataGroup(rowInfo_top, state.dataGroups, state.dataGroupTotal);
      if (!dataGroup) {
        return;
      }
      const colKey: string = colRowModel.keyFromCol(editData.col);
      const rowKey: string = rowInfo_edit.rowKey;
      const termIndex = termColKeys.indexOf(colKey);
      const ymdInfo = dataGroup.ymdInfos[termIndex];
      // if (!ymdInfo) {
      //   return;
      // }
      const groupCommentFlg = !ymdInfo;
      let comment:CommentData;
      //データグループのコメント
      if(groupCommentFlg) {
        //offset
        let _offset = 0;
        //メーカー残在庫
        if(dataGroup?.makerLimitData) {
          _offset ++;
        }
        //在庫期限アラート
        if (dataGroup.freshnessAlert) {
          _offset++;
        }
        const itemTagDatas = dataGroup?.itemTagDatas;
        if(itemTagDatas?.length) {
          _offset += itemTagDatas?.length;
        }
        const commentIndex = rowInfo_edit.rowIndex - _offset;

        comment = dataGroup.comments?.[commentIndex];
        if(!comment) {
          let comments = dataGroup.comments;
          if(!comments) {
            comments = [];
          }
          comment = {
            itemCD: dataGroup.centerItem.itemCD,
            centerCD: dataGroup.centerItem.centerCD,
            ymdID: null,
            keyCD: `G${`0${commentIndex}`.slice(-2)}` ,  // //G00形式はグループコメント
          };
          comments[commentIndex] = comment;
          dataGroup.comments = comments;
        }
        const relatedValue = editData.relatedValues[0];
        comment.tag = relatedValue?.value as TagData;
        comment.comment = editData.value as string;
        comment.state = "edit_own";

        //編集行の記録
        editedIdxsTop.add(rowInfo_top.index);
        rowKeys.forEach(rowKey => {
          editRowKeysSet.add(rowKey);
        });
      }
      //セルのコメント
      else {
        comment = {
          itemCD: dataGroup.centerItem.itemCD,
          centerCD: dataGroup.centerItem.centerCD,
          ymdID: ymdInfo.ymdID,
          keyCD: rowKey,
          comment: editData.value as string,
          state: "edit_own",
        };
        let comments = dataGroup.ymdInfos[termIndex].comments;
        if(!comments) {
          comments = {};
          dataGroup.ymdInfos[termIndex].comments = comments;
        }
        comments[rowKey] = comment;
      }

      //編集行の記録
      commentIdxs.add(idx);

      //キューに追加
      state.saveSeq = state.saveSeq+1;  //カウントアップ
      const saveInfo:SaveInfoData = {
        seq: state.saveSeq,
        centerItem: {
          bumonCD: dataGroup.centerItem.bumonCD, //--部門
          centerCD: dataGroup.centerItem.centerCD, //倉庫(4桁コード)
          centerCD2: dataGroup.centerItem.centerCD2, //倉庫(2桁コード)
          
          itemCD: dataGroup.centerItem.itemCD, //商品CD
          itemCD5: dataGroup.centerItem.itemCD5, //商品CD(5桁コード)
          makerCD: dataGroup.centerItem.makerCD, //メーカー
          blIrisu: dataGroup.centerItem.blIrisu,  //容量1(BL入数) 
        },
        comments: {},
      };
      //データグループのコメント
      if(groupCommentFlg) {
        saveInfo.comment = {...comment};
      }
      //セルのコメント
      else {
        saveInfo.comments[rowKey] = {...comment};
      }
      addSaveQueue.push(saveInfo);
    });

    if(commentIdxs.size > 0) {
      const editedRowsTop:number[] = [];
      const commentRows:number[] = [];
      Array.from(editedIdxsTop).forEach(idx => {
        const row = idxToRow(idx, pageInfo, state.fixedRowsTop);
        if(row >= 0) {
          editedRowsTop.push(row);
        }
      });
      Array.from(commentIdxs).forEach(idx => {
        const row = idxToRow(idx, pageInfo, state.fixedRowsTop);
        if(row >= 0) {
          commentRows.push(row);
        }
      });
      fncAfterEditRowDatas(state, editedRowsTop, editRowKeysSet, commentRows, pageInfo, colRowModel, numUnit, visibleAttributes, onlyNormal, result_type);

      //キューに追加
      state.saveQueue = [...state.saveQueue, ...addSaveQueue];
      state.saveVer = state.saveVer+1;  //カウントアップし登録処理を開始する

    }
  },
  editItemTags(state:MainPageTmpState, action: PayloadAction<{centerItem: CenterItem}>) {
    console.log('store.editItemTags');
    //タグ検索トリガ
    state.retrieveItemTag_centerItems = [action.payload.centerItem];
    state.retrieveItemTag_ver = state.retrieveItemTag_ver+1;
  },
  editMakerTags(state:MainPageTmpState, action: PayloadAction<{centerItem: CenterItem}>) {
    console.log('store.editMakerTags');
    //タグ検索トリガ
    state.retrieveMakerTag_centerItems = [action.payload.centerItem];
    state.retrieveMakerTag_ver = state.retrieveMakerTag_ver+1;
  },
  editOrderInstruction(state:MainPageTmpState, action: PayloadAction<{centerItems: CenterItem[]}>) {
    console.log('store.editOrderInstruction');

    //再検索のフラグ設定
    state.searchedBefore = false;
    state.searchedItemTags = false;
    state.searchedMakerTags = false;
    state.searchedComments = false;
    state.searchedResults = false;

    state.dataGroups.forEach(dataGroup => {
      // dataGroup.searchedBefore = false;
      dataGroup.searchedItemTags = false;
      dataGroup.searchedMakerTags = false;
      dataGroup.searchedComments = false;
      dataGroup.searchedResults = false;  
    });

    //編集Seqクリア
    state.retrievedEditSeq = {};

    state.retrieveBefore_ver = state.retrieveBefore_ver+1;
  },
  setAutoOrderQueue(state:MainPageTmpState, action: PayloadAction<{exec:boolean}>) {
    console.log('store.setAutoOrderQueue ' + JSON.stringify(action.payload));

    state.autoOrderQueue = action.payload.exec;
  },
  editAutoOrder(state:MainPageTmpState, action: PayloadAction<{leadTimeNo:''|'1'|'2'|'3'|'4'|'5'|string, excludeItemTags:string[], centerMakers:settingMakerCommon.CenterMaker[], infoDatas:settingMakerCommon.InfoData[], holidays:any, alertSetting:AlertSettings, colRowModel:MainPageColRowModel, 
  showSubTotal:boolean, 
  showGroupRow:boolean,
  numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][],
  onlyNormal:boolean, 
  result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  }>) {
    console.log('store.editAutoOrder');

    const leadTimeNo:number = parseInt(action.payload.leadTimeNo);
    const excludeItemTags:string[] = action.payload.excludeItemTags;
    const centerMakers:settingMakerCommon.CenterMaker[] = action.payload.centerMakers;
    const infoDatas:settingMakerCommon.InfoData[] = action.payload.infoDatas;
    const holidays:any = action.payload.holidays;
    const alertSetting:AlertSettings = action.payload.alertSetting;
    const doConfirm = false;
    const colRowModel = action.payload.colRowModel;
    const numUnit = action.payload.numUnit;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const visibleAttributes = action.payload.visibleAttributes;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;

    const centerMakersMap = {};
    centerMakers.forEach(centerMaker => {
      const key = `${centerMaker.centerCD} ${centerMaker.makerCD}`;
      centerMakersMap[key] = centerMaker;
    });

    const infoDatasMap = {};
    infoDatas.forEach(infoData => {
      const key = `${infoData.centerCD} ${infoData.makerCD}`;
      infoDatasMap[key] = infoData;
    });

    const ymds:YMD[] = state.ymds;
    const ymdToday = ymds.find(ymd => !ymd.week && ymd.type == 'today');
    const ymdFutures = ymds.filter(ymd => !ymd.week && ymd.type == 'future');

    const pagingInfo:PagingInfo = state.pagingInfo;
    const pageInfo:PageInfo = pagingInfo?.selectedPage;
  
    //列
    const colIndexStart = colRowModel.colFromKey('T001');

    //編集データ
    const editDatas:EditCellInfo[] = [];

    const dataGroups:RowDataGroup[] = state.dataGroups;
    dataGroups
    //指定商品属性は対象外
    .filter(dataGroup => {
      let checkOK = true;
      if(excludeItemTags && excludeItemTags.length > 0) {
        excludeItemTags.forEach(code => {
          if(!checkOK) return;

          switch (code) {
            case 'NEW':
              checkOK = !(dataGroup?.itemTag_NEW?.validFuture || dataGroup?.itemTag_NEW?.validToday);
              break;
            case 'CPN':
              checkOK = !(dataGroup?.itemTag_CPN?.validFuture || dataGroup?.itemTag_CPN?.validToday);
              break;
            case 'CUT':
              checkOK = !(dataGroup?.itemTag_CUT?.validFuture || dataGroup?.itemTag_CUT?.validToday);
              break;
            case 'EMC':
              checkOK = !(dataGroup?.itemTag_EMC?.validFuture || dataGroup?.itemTag_EMC?.validToday);
              break;
            case 'PKG':
              checkOK = !(dataGroup?.itemTag_PKG?.validFuture || dataGroup?.itemTag_PKG?.validToday);
              break;
            case 'OTH':
              checkOK = !(dataGroup?.itemTag_OTH?.validFuture || dataGroup?.itemTag_OTH?.validToday);
              break;
            default:
              break;
          }
        });
      }

      return checkOK;
    })
    .forEach(dataGroup => {
      //自動発注対象のみ
      const key = `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.makerCD}`;
      const centerMaker:settingMakerCommon.CenterMaker = centerMakersMap[key];
      const infoData:settingMakerCommon.InfoData = infoDatasMap[key];
      if(!centerMaker || !infoData) {
        return;
      }

      //最小のリードタイム
      const enableBusiness = infoData?.leadTimeFlg && infoData?.leadTimeDays > 0;
      let minLeadYmd = null;
      if(enableBusiness && ymdToday) {
        const baseDate = moment(`${ymdToday.ymdID}`); //基準日

        let leadTimeDays = infoData?.leadTimeDays;
        //締め時刻を過ぎていたら、基準日を１日プラスする
        if(leadTimeDays && infoData.closeTime) {
          //締め時刻超過判定
          const close = moment(`${moment(new Date()).format('YYYY-MM-DD')} ${infoData.closeTime}`);
          const now:Moment = moment(new Date());
          if(now.isAfter(close)) {
            baseDate.add(1, 'day');
          }
        }
  
        minLeadYmd = momentUtil.plusDaysInBusinessLeadTime(
          baseDate, 
          leadTimeDays, 
          true, //!infoData?.specifiedDateFlg || infoData?.specifiedDateMonFlg,
          true, //!infoData?.specifiedDateFlg || infoData?.specifiedDateTueFlg,
          true, //!infoData?.specifiedDateFlg || infoData?.specifiedDateWedFlg,
          true, //!infoData?.specifiedDateFlg || infoData?.specifiedDateThrFlg,
          true, //!infoData?.specifiedDateFlg || infoData?.specifiedDateFriFlg,
          !infoData?.leadTimeExcludeSaturdayFlg, //!infoData?.specifiedDateFlg || infoData?.specifiedDateSatFlg,
          false, //日曜は除く//!infoData?.specifiedDateFlg || infoData?.specifiedDateSunFlg,
          infoData?.leadTimeContainHolidayFlg,
          holidays,
          dataGroup.disableDateData?.disableDates
          );
      }

      if(minLeadYmd) {
        let leadTimeCount = 0;
        ymdFutures.forEach(ymd => {
          //指定リードタイム日の検索
          if(leadTimeNo > leadTimeCount) {
            const momentYmd = moment(`${ymd.ymdID}`);
            if(!momentYmd.isBefore(minLeadYmd)) {
              const ymdIndex = ymds.findIndex(ymd => ymd.ymdID == parseInt(momentYmd.format('YYYYMMDD')));

              if(momentUtil.enableInBusiness(momentYmd, 
                !infoData?.specifiedDateFlg || infoData?.specifiedDateMonFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateTueFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateWedFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateThrFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateFriFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateSatFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateSunFlg,
                !infoData?.specifiedDateFlg || infoData?.specifiedDateHolFlg,
                holidays,
                dataGroup.disableDateData?.disableDates //納品不可日
                )) {

                leadTimeCount++;
                //指定リードタイム日
                if(leadTimeNo == leadTimeCount) {

                  const ymdIdLead = parseInt(momentYmd.format('YYYYMMDD'));
                  //子センター出荷しない商品は自動発注入力の対象外
                  if(
                    (!dataGroup.centerItem.oyaCenter1 || ymdIdLead < dataGroup.centerItem.oyaCenter1.fromYMD || dataGroup.centerItem.oyaCenter1.toYMD < ymdIdLead) &&
                    (!dataGroup.centerItem.oyaCenter2 || ymdIdLead < dataGroup.centerItem.oyaCenter2.fromYMD || dataGroup.centerItem.oyaCenter2.toYMD < ymdIdLead) &&
                    (!dataGroup.centerItem.oyaCenter3 || ymdIdLead < dataGroup.centerItem.oyaCenter3.fromYMD || dataGroup.centerItem.oyaCenter3.toYMD < ymdIdLead)
                  ) {
                    let exec = !infoData?.autoOrderExecDays;
                    if(!exec) {
                      //N営業日後の在庫取得
                      const momentYmdExec = moment(momentYmd.format('YYYYMMDD'));
                      const momentZaikoExec = momentUtil.plusDaysInBusiness(momentYmdExec,
                        infoData?.autoOrderExecDays,
                        true,true,true,true,true,true,
                        infoData?.autoOrderContainSundayFlg,  //日曜含むフラグ
                        false, null, //祝日は無視
                        dataGroup.disableDateData?.disableDates //納品不可日
                        );
                      const ymdIndexZaikoExec = ymds.findIndex(ymd => ymd.ymdID == parseInt(momentZaikoExec.format('YYYYMMDD')));
                      const ymdInfoZaikoExec:YMDInfo = dataGroup.ymdInfos?.[ymdIndexZaikoExec];
                      const stockNormalBLExec = ymdInfoZaikoExec?.infos?.stock?.stockNormalBL;
                      exec = (!stockNormalBLExec || stockNormalBLExec <= 0);
                    }

                    if(exec) {
                      //N営業日後の在庫取得
                      const momentZaiko = momentUtil.plusDaysInBusiness(momentYmd,
                        infoData?.autoOrderDays,
                        true,true,true,true,true,true,
                        infoData?.autoOrderContainSundayFlg,  //日曜含むフラグ
                        false, null, //祝日は無視
                        dataGroup.disableDateData?.disableDates //納品不可日
                        );

                      const ymdIndexZaiko = ymds.findIndex(ymd => ymd.ymdID == parseInt(momentZaiko.format('YYYYMMDD')));
                      const ymdInfoZaiko:YMDInfo = dataGroup.ymdInfos?.[ymdIndexZaiko];
                      if(ymdInfoZaiko) {
                        //在庫数
                        const stockNormalBL = ymdInfoZaiko?.infos?.stock?.stockNormalBL;
                        //在庫がなければ補充する
                        if(!stockNormalBL || stockNormalBL <= 0) {
                          const ymdIndexLead = ymds.findIndex(ymd => ymd.ymdID == ymdIdLead);
                          const ymdInfoLead:YMDInfo = dataGroup.ymdInfos?.[ymdIndexLead];
                          const stockNormalBLLead = ymdInfoLead?.infos?.stock?.stockNormalBL;
    
                          const orderNum = calcUtil.plus(stockNormalBLLead, calcUtil.plus(calcUtil.multiply(-1, stockNormalBL), 1));  //在庫数1

                          //編集データ
                          const col = colIndexStart + ymdIndex;
                          const idx = findRowInfoIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.centerCD, ['warehousing'], state.dataGroups, state.rowInfos);
                    
                          editDatas.push({row:idxToRow(idx, pageInfo, state.fixedRowsTop), col:col, rowKey:"warehousing", value:orderNum, auto:true});
                          
                        }

                      }
                    }
                  }
                }
              }
            }
          }
        });
      }

    })

    if(editDatas.length > 0) {
      //編集処理本体
      const editInfo:EditInfo = {
        numUnit_view: numUnit, 
        numUnit_input: "LOT", 
        wholeOrDiff: "whole", 
        editDatas: editDatas,
      };
  
      //編集処理本体
      fnc_editRowDatas(state,
        editInfo, 
        colRowModel, visibleAttributes, holidays, alertSetting, doConfirm, showSubTotal, showGroupRow, averageWeeks, onlyNormal, result_type);
    }
  },
  setProvisionalQueue(state:MainPageTmpState, action: PayloadAction<{exec:boolean}>) {
    console.log('store.setProvisionalQueue ' + JSON.stringify(action.payload));

    state.provisionalQueue = action.payload.exec;
  },
  editProvisional(state:MainPageTmpState, action: PayloadAction<{provisionalDatas:ProvisionalData[], holidays:any, alertSetting:AlertSettings, colRowModel:MainPageColRowModel, 
    showSubTotal:boolean, 
    showGroupRow:boolean,
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][], onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>) {
    console.log('store.editProvisional');

    const provisionalDatas:ProvisionalData[] = action.payload.provisionalDatas;
    const holidays:any = action.payload.holidays;
    const alertSetting:AlertSettings = action.payload.alertSetting;
    const doConfirm = false;
    const colRowModel = action.payload.colRowModel;
    const numUnit = action.payload.numUnit;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const visibleAttributes = action.payload.visibleAttributes;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const pagingInfo:PagingInfo = state.pagingInfo;
    const pageInfo:PageInfo = pagingInfo?.selectedPage;

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;
  
    const dataGroupsMap = {};
    state.dataGroups
    // //「カット」「緊急カット」「新規」商品は対象外です
    // .filter(dataGroup => !dataGroup?.itemTag_CUT?.validFuture && !dataGroup?.itemTag_EMC?.validFuture && !dataGroup?.itemTag_NEW?.validFuture)
    .forEach(dataGroup => {
      const key = `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.itemCD}`;
      dataGroupsMap[key] = dataGroup;
    });

    const ymds:YMD[] = state.ymds;

    //列
    const colIndexStart = colRowModel.colFromKey('T001');

    //編集データ
    const editDatas:EditCellInfo[] = [];

    provisionalDatas
    .forEach(provisionalData => {
      //自動発注対象のみ
      const key = `${provisionalData.centerCD} ${provisionalData.itemCD}`;
      const dataGroup:RowDataGroup = dataGroupsMap[key];
      if(!dataGroup) {
        return;
      }

      const ymdIndex = ymds.findIndex(ymd => ymd.ymdID == provisionalData.ymdId);
      const orderNum = provisionalData.numBL;

      //編集データ
      const col = colIndexStart + ymdIndex;
      const idx = findRowInfoIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.centerCD, ['shipping','shipping_storeOrder'], state.dataGroups, state.rowInfos);
      const rowKey = state.rowInfos[idx]?.rowKey;

      editDatas.push({row:idxToRow(idx, pageInfo, state.fixedRowsTop), col:col, rowKey:rowKey ? rowKey : "shipping", value:orderNum});
    })
    
    if(editDatas.length > 0) {
      //編集処理本体
      const editInfo:EditInfo = {
        numUnit_view: numUnit, 
        numUnit_input: "BL", 
        wholeOrDiff: "whole", 
        editDatas: editDatas,
      };
  
      //編集処理本体
      fnc_editRowDatas(state,
        editInfo, 
        colRowModel, visibleAttributes, holidays, alertSetting, doConfirm, showSubTotal, showGroupRow, averageWeeks, onlyNormal, result_type);
    }    
  },
  refrectLoadDatas(state:MainPageTmpState, action: PayloadAction<{colRowModel:MainPageColRowModel, 
    showSubTotal:boolean, 
    showGroupRow:boolean,
    numUnit:'CS'|'BL'|'PS'|string, visibleAttributes: string[][], holidays:any, alertSetting:AlertSettings, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string}>
    ) {
    console.log('store.refrectLoadDatas');
    const ymds = state.ymds;
    const rowInfos = state.rowInfos;
    const numUnit = action.payload.numUnit;
    const showSubTotal = action.payload.showSubTotal;
    const showGroupRow = action.payload.showGroupRow;
    const visibleAttributes = action.payload.visibleAttributes;
    const colRowModel = action.payload.colRowModel;
    const holidays = action.payload.holidays;
    const alertSetting = action.payload.alertSetting;
    const onlyNormal = action.payload.onlyNormal;
    const result_type = action.payload.result_type;

    const averageWeeks = state.retrievedParam?.termShipping ?? 3;

    const pagingInfo:PagingInfo = state.pagingInfo;
    const pageInfo:PageInfo = pagingInfo?.selectedPage;
  
    let editedIdxsTop:Set<number> = new Set<number>();
    let editedIdxsTop_calcAll:Set<number> = new Set<number>();
    let editRowKeysSet:Set<string> = new Set<string>();
    let commentIdxs:Set<number> = new Set<number>();
    let itemTagCenterItems:CenterItem[] = [];

    let editedFromTermIndex:number = 999;
    let editedToTermIndex:number = -1;
    let editedFromTermIndexMap:Map<string,number> = new Map<string,number>();
    let editedToTermIndexMap:Map<string,number> = new Map<string,number>();

    const diffDatas:DiffData[] = state.loadQueue;
    diffDatas.forEach(diffData => {
      const termIndex = ymds.findIndex(ymd => ymd.ymdID == diffData.ymdID);
      const dataGroupIndex = findDataGroupIndex(diffData.itemCD, diffData.centerCD, state.dataGroups);
      const rowInfoIdxTop = findRowInfoTopIndex(diffData.itemCD, diffData.centerCD, state.dataGroups, rowInfos);
      if((diffData.ymdID && termIndex < 0) || dataGroupIndex < 0) {
        return;
      }
      let dataGroup:RowDataGroup = state.dataGroups[dataGroupIndex];

      //infos
      if(diffData.infos && dataGroup.searchedResults) {
        Object.keys(diffData.infos).forEach(key => {
          let edited = false;
          switch (key) {
            // case 'storeSales':
            //   break;
            case 'transfer':
              {
                // const dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[rowInfoIdxTop], state.dataGroups, state.dataGroupTotal);
                const ymdInfo:YMDInfo = dataGroup.ymdInfos[termIndex];

                const infoDataOld:InfoDataTransfer = {...ymdInfo.infos.transferIn};
                const infoData:InfoDataTransfer = ymdInfo.infos.transferIn;
                const infoData_diff:InfoDataTransfer = diffData.infos.transfer;
                infoData.transferBL_edit = infoData_diff.transferBL_edit;
                switch (infoData_diff?.state) {
                  case "edit_own":
                  case "edit_mix":
                  case "edit_other":
                    infoData.state = infoData_diff.state;
                    break;
                  default:
                    if(infoData.transferBL_result) {
                      infoData.state = "result"
                    }
                    else {
                      infoData.state = ""
                    }
                    break;
                }

                //変更されている場合のみ
                if(infoDataOld?.state != infoData.state ||
                  infoDataOld?.transferBL_edit != infoData.transferBL_edit ||
                  infoDataOld?.transferBL_result != infoData.transferBL_result
                  
                ) {
                  ymdInfo.infos.transferIn = infoData;
                  dataGroup.ymdInfos[termIndex].infos.transferIn = infoData;
                  dataGroup.calced1 = false; //未計算
                  dataGroup.calced3 = false; //未計算
                  dataGroup.checked = false; //未チェック
                  dataGroup.highlighted = false; //未ハイライトチェック
                  edited = true;
                }
              }
              break;
            case 'warehousing':
              {
                // const dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[rowInfoIdxTop], state.dataGroups, state.dataGroupTotal);
                const ymdInfo:YMDInfo = dataGroup.ymdInfos[termIndex];

                const infoDataOld:InfoDataWarehousing = {...ymdInfo.infos.warehousing};
                const infoData:InfoDataWarehousing = ymdInfo.infos.warehousing;
                const infoData_diff:InfoDataWarehousing = diffData.infos.warehousing;
                infoData.warehousingBL_edit = infoData_diff.warehousingBL_edit;
                infoData.warehousingBL_if = infoData_diff.warehousingBL_if;
                infoData.warehousingPS_result = calcUtil.plus(infoData.warehousingPS_result, infoData_diff.warehousingPS_result); //実績のみ差分で取得する
                infoData.warehousingPS_result1 = calcUtil.plus(infoData.warehousingPS_result1, infoData_diff.warehousingPS_result1); //実績のみ差分で取得する
                infoData.warehousingPS_result2 = calcUtil.plus(infoData.warehousingPS_result2, infoData_diff.warehousingPS_result2); //実績のみ差分で取得する
                infoData.warehousingPS_result3 = calcUtil.plus(infoData.warehousingPS_result3, infoData_diff.warehousingPS_result3); //実績のみ差分で取得する
                switch (infoData_diff?.state) {
                  case "edit_own":
                  case "edit_mix":
                  case "edit_other":
                  case "edit_auto":
                    infoData.state = infoData_diff.state;
                    break;
                  default:
                    if(infoData.warehousingPS_result || infoData.warehousingBL_if) {
                      infoData.state = "result"
                    }
                    else {
                      infoData.state = ""
                    }
                    break;
                }

                //変更されている場合のみ
                if(infoDataOld?.state != infoData.state ||
                  infoDataOld?.warehousingBL_edit != infoData.warehousingBL_edit ||
                  infoDataOld?.warehousingBL_if != infoData.warehousingBL_if ||
                  infoDataOld?.warehousingPS_result != infoData.warehousingPS_result
                  
                ) {
                  ymdInfo.infos.warehousing = infoData;
                  dataGroup.ymdInfos[termIndex].infos.warehousing = infoData;
                  dataGroup.calced1 = false; //未計算
                  dataGroup.calced3 = false; //未計算
                  dataGroup.checked = false; //未チェック
                  dataGroup.highlighted = false; //未ハイライトチェック
                  edited = true;

                  //バンドルをもっているなら、バンドルも再計算対象
                  {
                    const func = (bundleDataGrop:RowDataGroup) => {
                      if(bundleDataGrop) {
                        const bundleRowInfoIndex = findRowInfoTopIndex(bundleDataGrop.centerItem.itemCD, bundleDataGrop.centerItem.centerCD, state.dataGroups, rowInfos);
                        if(bundleRowInfoIndex >= 0) {
                          editedIdxsTop.add(bundleRowInfoIndex);
                          editedIdxsTop_calcAll.add(bundleRowInfoIndex);  //全体再計算
                          editRowKeysSet.add('warehousing');

                          const dataGroupKey = `${bundleDataGrop.centerItem.centerCD} ${bundleDataGrop.centerItem.itemCD}`;
                          let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
                          let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
                          editedFromTermIndexMap.set(dataGroupKey, Math.min(editedFromTermIndexOfDataGroup == null ? 999 : editedFromTermIndexOfDataGroup, termIndex));
                          editedToTermIndexMap.set(dataGroupKey, Math.max(editedToTermIndexOfDataGroup == null ? -1 : editedToTermIndexOfDataGroup, termIndex));
                          // newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
                          // state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
                      
                          bundleDataGrop.calced1 = false; //未計算
                          bundleDataGrop.calced2 = false; //未計算
                          bundleDataGrop.calced3 = false; //未計算
                          bundleDataGrop.checked = false; //未チェック
                          bundleDataGrop.highlighted = false; //未ハイライトチェック
                        }
                      }
                    };
                    // const termIndex = ymds.findIndex(ymd => ymd.ymdID == diffData.ymdID);
                    if(dataGroup.centerItem.bundleItemCD) {
                      const bundleDataGroupIndex = findDataGroupIndex(dataGroup.centerItem.bundleItemCD, dataGroup.centerItem.centerCD, state.dataGroups);
                      if(bundleDataGroupIndex >= 0) {
                        func(state.dataGroups[bundleDataGroupIndex]);
                      }
                    }
                  }

                }
              }
              break;
            // case 'bestBefore':
            //   break;
            // case 'storeOrder':
            //   break;
            case 'shipping':
              {
                // const dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[rowInfoIdxTop], state.dataGroups, state.dataGroupTotal);
                const ymdInfo:YMDInfo = dataGroup.ymdInfos[termIndex];
                const infoDataOld:InfoDataShipping = {...ymdInfo.infos.shipping};
                const infoData:InfoDataShipping = ymdInfo.infos.shipping;
                const infoData_diff:InfoDataShipping = diffData.infos.shipping;
                infoData.shippingBL_edit = infoData_diff.shippingBL_edit;
                switch (infoData_diff?.state) {
                  case "edit_own":
                  case "edit_mix":
                  case "edit_other":
                    infoData.state = infoData_diff.state;
                    break;
                  default:
                    if(infoData.shippingPS_result) {
                      infoData.state = "result"
                    }
                    else {
                      infoData.state = ""
                    }
                    break;
                }

                //変更されている場合のみ
                if(infoDataOld?.state != infoData.state ||
                  infoDataOld?.shippingBL_edit != infoData.shippingBL_edit
                ) {
                  ymdInfo.infos.shipping = infoData;
                  dataGroup.ymdInfos[termIndex].infos.shipping = infoData;

                  if(infoData.state == "") {
                    dataGroup.calced1 = false; //未計算
                    dataGroup.calced3 = false; //未計算
                    dataGroup = calcDataGroupSingle(dataGroup, ['shipping'], ymds, termIndex, termIndex, true, false, editRowKeysSet, averageWeeks);
                  }
      
                  dataGroup.calced1 = false; //未計算
                  dataGroup.calced3 = false; //未計算
                  dataGroup.checked = false; //未チェック
                  dataGroup.highlighted = false; //未ハイライトチェック
                  edited = true;

                  //親センターをもっているなら、親センターも再計算対象
                  {
                    const func = (oyaDataGrop:RowDataGroup) => {
                      if(oyaDataGrop) {
                        const oyaRowInfoIndex = findRowInfoTopIndex(oyaDataGrop.centerItem.itemCD, oyaDataGrop.centerItem.centerCD, state.dataGroups, rowInfos);
                        if(oyaRowInfoIndex >= 0) {
                          editedIdxsTop.add(oyaRowInfoIndex);
                          editedIdxsTop_calcAll.add(oyaRowInfoIndex);  //全体再計算
                          editRowKeysSet.add('transfer');

                          const dataGroupKey = `${oyaDataGrop.centerItem.centerCD} ${oyaDataGrop.centerItem.itemCD}`;
                          let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
                          let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
                          editedFromTermIndexMap.set(dataGroupKey, Math.min(editedFromTermIndexOfDataGroup == null ? 999 : editedFromTermIndexOfDataGroup, termIndex));
                          editedToTermIndexMap.set(dataGroupKey, Math.max(editedToTermIndexOfDataGroup == null ? -1 : editedToTermIndexOfDataGroup, termIndex));
                          // newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
                          // state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
                      
                          oyaDataGrop.calced1 = false; //未計算
                          oyaDataGrop.calced2 = false; //未計算
                          oyaDataGrop.calced3 = false; //未計算
                          oyaDataGrop.checked = false; //未チェック
                          oyaDataGrop.highlighted = false; //未ハイライトチェック
                        }
                      }
                    };
                    // const termIndex = ymds.findIndex(ymd => ymd.ymdID == diffData.ymdID);
                    if(dataGroup.centerItem.oyaCenter1) {
                      const oyaDataGroupIndex1 = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.oyaCenter1.code, state.dataGroups);
                      if(oyaDataGroupIndex1 >= 0) {
                        func(state.dataGroups[oyaDataGroupIndex1]);
                      }
                    }
                    if(dataGroup.centerItem.oyaCenter2) {
                      const oyaDataGroupIndex2 = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.oyaCenter2.code, state.dataGroups);
                      if(oyaDataGroupIndex2 >= 0) {
                        func(state.dataGroups[oyaDataGroupIndex2]);
                      }
                    }
                    if(dataGroup.centerItem.oyaCenter3) {
                      const oyaDataGroupIndex3 = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.oyaCenter3.code, state.dataGroups);
                      if(oyaDataGroupIndex3 >= 0) {
                        func(state.dataGroups[oyaDataGroupIndex3]);
                      }
                    }
                  }

                }
              }
              break;
            // case 'stock':
            //   break;
            default:
              break;
          }

          if(edited) {
            //表示中のページ判定
            if(rowInfoIdxTop >= 0) {
              editedIdxsTop.add(rowInfoIdxTop);
              editRowKeysSet.add(key);
            }
            
            //編集列の記録
            editedFromTermIndex = Math.min(editedFromTermIndex, termIndex);
            editedToTermIndex = Math.max(editedToTermIndex, termIndex);

            const dataGroupKey = `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.itemCD}`;
            let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
            let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
            editedFromTermIndexMap.set(dataGroupKey, Math.min(editedFromTermIndexOfDataGroup == null ? 999 : editedFromTermIndexOfDataGroup, termIndex));
            editedToTermIndexMap.set(dataGroupKey, Math.max(editedToTermIndexOfDataGroup == null ? -1 : editedToTermIndexOfDataGroup, termIndex));
            // newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
            // state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];

          }

        });
      }
      //commment　（グループコメント）
      if(diffData.comment && dataGroup.searchedComments) {
        const rowInfoIdxTop = findRowInfoTopIndex(diffData.itemCD, diffData.centerCD, state.dataGroups, rowInfos);

        const commentData:CommentData = diffData.comment;
        const commentIndex = parseInt(commentData.keyCD.slice(-2));  //'G00形式'はグループコメント
        let comments = dataGroup.comments;
        if(!comments) {
          comments = {};
          dataGroup.comments = comments;
        }
        comments[commentIndex] = commentData;

        if(rowInfoIdxTop >= 0) {
          commentIdxs.add(rowInfoIdxTop);

          editedIdxsTop.add(rowInfoIdxTop);
          rowKeys.forEach(rowKey => {
            editRowKeysSet.add(rowKey);
          });

        }
      }
      //commments
      if(diffData.comments && dataGroup.searchedComments) {
        Object.keys(diffData.comments).forEach(key => {
          const rowInfoIdx = findRowInfoIndex(diffData.itemCD, diffData.centerCD, [key], state.dataGroups, rowInfos);

          const commentData:CommentData = diffData.comments[key];
          // const dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[rowInfoIdxTop], state.dataGroups, state.dataGroupTotal);
          const ymdInfo:YMDInfo = dataGroup.ymdInfos[termIndex];
          if(!ymdInfo.comments) {
            ymdInfo.comments = {};
          }
          ymdInfo.comments[commentData.keyCD] = commentData;
          dataGroup.ymdInfos[termIndex].comments[commentData.keyCD] = commentData;

          if(rowInfoIdx >= 0) {
            commentIdxs.add(rowInfoIdx);
          }
        });
      }
      //furikae
      if(diffData.furikae && dataGroup.searchedResults) {
          const furikaeData:DiffFurikaeData = diffData.furikae;
          // let dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[rowInfoIdxTop], state.dataGroups, state.dataGroupTotal);
          const ymdInfo:YMDInfo = dataGroup.ymdInfos[termIndex];
          const stock: InfoDataStock = ymdInfo.infos.stock;
          //'1'=一般、'2'=特売、'3'=予約、'4'=出切、'5'=返品
          switch (furikaeData.zaikoKbnTo) {
            case '4':
              stock.stockUseUpBL = furikaeData.numBL;
              break;
            case '5':
              stock.stockReturnedBL = furikaeData.numBL;
              break;
            default:
              break;
          }
          //一般在庫の再計算
          dataGroup.calced3 = false; //未計算
          dataGroup.checked = false; //未チェック
          dataGroup.highlighted = false; //未ハイライトチェック
          dataGroup = calcDataGroup3(dataGroup, ['stockNormal'], ymds, termIndex, termIndex, true, true, editRowKeysSet);

          if(rowInfoIdxTop >= 0) {
            //表示中のページ判定
            editedIdxsTop.add(rowInfoIdxTop);
            switch (furikaeData.zaikoKbnTo) {
              case '4':
                editRowKeysSet.add('stockUseUp');
                break;
              case '5':
                editRowKeysSet.add('stockReturned');
                break;
              default:
                break;
            }
          }
      }
      //タグ
      if(diffData.itemTag && dataGroup.searchedResults) {
        //TODO
        const diffItemTag:DiffItemTagData = diffData.itemTag;

        //タグ検索トリガ
        let targetCenterItem = state.centerItems.find(centerItem => centerItem.centerCD == diffItemTag.centerCD && centerItem.itemCD == diffItemTag.itemCD);
        if(targetCenterItem) {
          itemTagCenterItems.push(targetCenterItem);
        }
      }
      //キューから削除
      fnc_removeLoadQueue(state, [diffData]);
    });

    if(itemTagCenterItems.length > 0) {
        //タグ検索トリガ
        state.retrieveItemTag_centerItems = itemTagCenterItems;
        state.retrieveItemTag_ver = state.retrieveItemTag_ver+1;
    }
    if(!editedIdxsTop.size && !commentIdxs.size) {
      return;
    }
    const editedRowsTop:number[] = [];
    if(editedIdxsTop.size > 0) {
      //ハイライトの再計算は、未来分をすべてとする
      const highlightRecalcYmdFromIndex = ymds.findIndex(ymd => ymd.type == 'future');
      const highlightRecalcYmdToIndex = ymds.length-1;

      editedIdxsTop.forEach(idx => {
        let new_dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[idx], state.dataGroups, state.dataGroupTotal);
        const dataGroupKey = `${new_dataGroup.centerItem.centerCD} ${new_dataGroup.centerItem.itemCD}`;
        let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
        // let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);

        if(editedIdxsTop_calcAll.has(idx)) {
          new_dataGroup = calcDataGroupAll(new_dataGroup, state.dataGroups, rowKeys, ymds, editedFromTermIndexOfDataGroup, ymds.length-1, true, true, editRowKeysSet, averageWeeks);
        }
        else {
          new_dataGroup = calcDataGroupSingle(new_dataGroup, rowKeys, ymds, editedFromTermIndexOfDataGroup, ymds.length-1, true, true, editRowKeysSet, averageWeeks);
        }
  
        new_dataGroup = checkDataGroup(new_dataGroup, ymds, editedFromTermIndexOfDataGroup, ymds.length-1); //データチェック
        new_dataGroup = highlightDataGroup(new_dataGroup, ymds, highlightRecalcYmdFromIndex, highlightRecalcYmdToIndex, holidays, alertSetting); //ハイライト
        // rowInfos[row].dataGroup = new_dataGroup;

        //stateを置換する
        // const index = state.dataGroups.findIndex(dataGroup => `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.itemCD}` == dataGroupKey);
        // state.dataGroups[index] = new_dataGroup;
        // const idx = rowToIdx(row, pageInfo, state.fixedRowsTop);;

        const row = idxToRow(idx, pageInfo, state.fixedRowsTop);
        if(row >= 0) {
          editedRowsTop.push(row);
        }
      });

      //合計
      if(state.fixedRowsTop > 0) {
        rowInfos.filter(rowInfo => rowInfo.TP)
        const dataGroups = state.dataGroups;
        let dataGroupTotal = udpateDataGroupTotal(state.dataGroupTotal, dataGroups, rowKeys, ymds, editedFromTermIndex, ymds.length-1, showSubTotal, showGroupRow);  //合計計算
        state.dataGroupTotal = dataGroupTotal;
        editedRowsTop.push(0);
      }

    }
    const commentRows:number[] = [];
    if(commentIdxs.size > 0) {
      commentIdxs.forEach(idx => {
        const row = idxToRow(idx, pageInfo, state.fixedRowsTop);
        if(row >= 0) {
          commentRows.push(row);
        }
      });
    }
    fncAfterEditRowDatas(state, editedRowsTop, editRowKeysSet, commentRows, pageInfo, colRowModel, numUnit, visibleAttributes, onlyNormal, result_type);
  },
  execSaveQueue(state:MainPageTmpState) {
    console.log('store.execSaveQueue');
    state.saveVer = state.saveVer+1;  //カウントアップし登録処理を開始する
  },
  addSaveQueue(state:MainPageTmpState, action: PayloadAction<SaveInfoData[]>) {
    console.log('store.addSaveQueue');
    let saveDatas:SaveInfoData[] = action.payload;
    if(saveDatas.length > 0) {
      state.saveQueue = [...state.saveQueue, ...saveDatas];
      state.saveVer = state.saveVer+1;  //カウントアップし登録処理を開始する
    }
  },
  removeSaveQueue(state:MainPageTmpState, action: PayloadAction<SaveInfoData[]>) {
    console.log('store.removeSaveQueue');
    const saveInfos:SaveInfoData[] = action.payload;
    fnc_removeSaveQueue(state, saveInfos);
  },
  execLoadQueueCorrect(state:MainPageTmpState) {
    console.log('store.execLoadQueueCorrect');
    state.loadVerCorrect = state.loadVerCorrect+1;  //カウントアップし読込処理を開始する
  },
  execLoadQueueRefrect(state:MainPageTmpState) {
    console.log('store.execLoadQueueRefrect');
    state.loadVerRefrect = state.loadVerRefrect+1;  //カウントアップし読込処理を開始する
  },
  addLoadQueue(state:MainPageTmpState, action: PayloadAction<DiffData[]>) {
    console.log('store.addLoadQueue');
    let loadDatas:DiffData[] = action.payload;
    loadDatas = loadDatas.map(loadData => {
      if(!loadData.seq) {
        loadData.seq = state.loadSeq+1;  //カウントアップ
      }
      return loadData;
    });
    
    if(loadDatas.length > 0) {
      state.loadQueue = [...state.loadQueue, ...loadDatas];
      // state.loadVerRefrect = state.loadVerRefrect+1;  //カウントアップし登録処理を開始する
    }
  },
  removeLoadQueue(state:MainPageTmpState, action: PayloadAction<DiffData[]>) {
    console.log('store.removeLoadQueue');

    const loadInfos:DiffData[] = action.payload;
    fnc_removeLoadQueue(state, loadInfos);
  },
  setExcelQueue(state:MainPageTmpState, action: PayloadAction<{exec:boolean, pageOnly?:boolean, withDataSheet?:boolean}>) {
    console.log('store.setExcelQueue ' + JSON.stringify(action.payload));
    state.excelQueue = action.payload.exec;
    state.excelQueuePageOnly = action.payload.pageOnly;
    state.excelQueueWithDataSheet = action.payload.withDataSheet;
  },
}};

const fnc_searchContents = (state:MainPageTmpState, targetRowInfos:RowInfo[]):boolean => {
  console.log('store.fnc_searchContents');

  //検索対象の存在有無をチェック
  const dataGroups:RowDataGroup[] = [];
  const dataGroupsKeyMap = {};
  const fnc = (dataGroup:RowDataGroup) => {
    if(!dataGroup) {
      return;
    }
    const key = `${dataGroup.centerItem.itemCD} ${dataGroup.centerItem.centerCD}`;
    if(!dataGroupsKeyMap[key]
      //検索未完了のみ
      && !(
        dataGroup.searchedItemTags &&
        dataGroup.searchedMakerTags &&
        dataGroup.searchedComments &&
        dataGroup.searchedResults
      )
    ) {
      dataGroups.push(dataGroup);
      dataGroupsKeyMap[key] = key;
    };
  };

  targetRowInfos.forEach((rowInfo, idx) => {

    if(rowInfo.TP == 'info'
      && rowInfo.rowIndex == 0 
    ) {
      //本体
      let rowInfo_dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal);
      fnc(rowInfo_dataGroup);
      //子センター
      let rowInfo_dataGroup_ko1:RowDataGroup = getRowInfoDataGroupKoCneter1(rowInfo, state.dataGroups);
      fnc(rowInfo_dataGroup_ko1);
      let rowInfo_dataGroup_ko2:RowDataGroup = getRowInfoDataGroupKoCneter2(rowInfo, state.dataGroups);
      fnc(rowInfo_dataGroup_ko2);
      let rowInfo_dataGroup_ko3:RowDataGroup = getRowInfoDataGroupKoCneter3(rowInfo, state.dataGroups);
      fnc(rowInfo_dataGroup_ko3);
    };

  });
  // const dataGroups:RowDataGroup[] = rowInfos.map(rowInfo => rowInfo.dataGroup);

  //検索対象ナシはreturn
  if(dataGroups.length == 0) {
    return false;
  }

  let centerItems:CenterItem[] = dataGroups.map(dataGroup => dataGroup.centerItem);

  state.searchedItemTags = false;
  state.searchedMakerTags = false;
  state.searchedComments = false;
  state.searchedResults = false;

  //商品タグ検索トリガ
  state.retrieveItemTag_centerItems = centerItems; //表示分のみ
  state.retrieveItemTag_ver = state.retrieveItemTag_ver+1;
  //メーカータグ検索トリガ
  state.retrieveMakerTag_centerItems = centerItems; //表示分のみ
  state.retrieveMakerTag_ver = state.retrieveMakerTag_ver+1;
  // コメント検索トリガ
  state.retrieveComment_centerItems = centerItems; //表示分のみ
  state.retrieveComment_ver = state.retrieveComment_ver+1;

  return true;
};

const fnc_editRowDatas = (
  state:MainPageTmpState, 
  editInfo:EditInfo,
  colRowModel:MainPageColRowModel, 
  visibleAttributes: string[][], 
  holidays:any, 
  alertSetting:AlertSettings,
  doConfirm:boolean,
  showSubTotal:boolean,
  showGroupRow:boolean,
  averageWeeks:number,
  onlyNormal:boolean,
  result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string
  ) => {
  console.log('store.fnc_editRowDatas');

  //editInfo
  const numUnit_view = editInfo.numUnit_view;
  const numUnit_input = editInfo.numUnit_input;
  const wholeOrDiff = editInfo.wholeOrDiff;
  const editDatas:EditCellInfo[] = editInfo.editDatas;
  
  //バラ数入力は無効
  if(numUnit_input == 'PS') {
    return;
  }

  const pagingInfo:PagingInfo = state.pagingInfo;
  const pageInfo:PageInfo = pagingInfo?.selectedPage;

  const ymds = state.ymds;
  const rowInfos = state.rowInfos;
  
  let editedIdxsTopSet:Set<number> = new Set<number>();
  let editedIdxsTopSet_calcAll:Set<number> = new Set<number>();
  let editRowKeysSet:Set<string> = new Set<string>();
  let editedFromTermIndex:number = 999;
  let editedToTermIndex:number = -1;
  let editedFromTermIndexMap:Map<string,number> = new Map<string,number>();
  let editedToTermIndexMap:Map<string,number> = new Map<string,number>();

  //登録用のキュー
  const addSaveQueue:SaveInfoData[] = [];
  
  const confirmEditDatas_warehousing_clear:EditCellInfo[] = [];
  const confirmEditDatas_warehousing_holiday:EditCellInfo[] = [];
  const confirmEditDatas_warehousing_warn: EditCellInfo[] = [];

  editDatas.forEach((editData)=>{
    const idx = rowToIdx(editData.row, pageInfo, state.fixedRowsTop);
    const rowInfo_edit:RowInfo = rowInfos[idx]; //編集行
    const rowInfo_top:RowInfo = rowInfos[idx - rowInfo_edit.rowIndex]; //一番上のデータを書き換える

    if(!rowInfo_edit || !rowInfo_top) {
      return;
    }
    let dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo_top, state.dataGroups, state.dataGroupTotal);
    if(!dataGroup) {
      return;
    }

    // const rowKey:string = rowInfo_edit.rowKey;
    const rowKey:string = editData.rowKey ? editData.rowKey : rowInfo_edit.rowKey;
    const colKey:string = colRowModel.keyFromCol(editData.col);
    const termIndex = termColKeys.indexOf(colKey);
    const ymdInfo:YMDInfo = termIndex >= 0 ? dataGroup.ymdInfos[termIndex] : null;
    if(!ymdInfo) {
      return;
    }

    let dataType = rowDataType[`${rowKey}BL`];
    if(!dataType) {
      dataType = colDataType[colKey];
    }

    const valueO = editorUtil.parseValue(editData.value, dataType.type,
      dataType.type == 'numeric' ? dataType.numericFormat.pattern :
      dataType.type == 'date' ? dataType.dateFormat :
      null);
    const value:number = typeof valueO == 'number' ? valueO : null;
    const itemCD = dataGroup.centerItem.itemCD;
    const blIrisu = dataGroup.centerItem.blIrisu;
    const csIrisu = dataGroup.centerItem.csIrisu;
    const lot = dataGroup.centerItem.lot;
    const csIrisu_lot = dataGroup.centerItem.csIrisu_lot;
    const loadedQty = dataGroup.itemCommonData?.loadedQty;  //積付数
    const valueBL = value; //少数１位

    switch (rowKey) {

      case "transfer": //"転送",
      case "transfer_storeOrder": //"転送(先付含)",
        const transfer_old = {...ymdInfo.infos.transferIn};
        const transfer = ymdInfo.infos.transferIn;

        const transferBL_old = transfer_old.transferBL_edit;
        const transferBL_diff = valueBL === null ? calcUtil.multiply(transfer.transferBL_edit, -1) 
          : ['diff'].indexOf(wholeOrDiff) !== -1 ? (valueBL) //転送は通常全量入力。空白入力時は、入力値のクリアとする
          : calcUtil.minus(valueBL, transferBL_old);
        let new_transferBL = calcUtil.plus(transfer.transferBL_edit, transferBL_diff);
        // //マイナスは何もしない
        // if(new_transferBL < 0) {
        //   return;
        // }

        switch (numUnit_input) {
          case 'LOT':
            //発注単位の倍数とする
            new_transferBL= calcUtil.smallestMultiple(new_transferBL, csIrisu_lot);
            break;
          case 'CS':
            //CS入り数の倍数とする
            new_transferBL= calcUtil.smallestMultiple(new_transferBL, csIrisu);
            break;
          case 'BL':
          case 'auto':
              //入力値そのまま
              break;
          case 'PS':
          default:
            //無効
            return;
        }

        //同じならば何もしない
        if(!calcUtil.minus(new_transferBL, transfer_old.transferBL_edit)) {
          return;
        }

        transfer.transferBL_edit = new_transferBL;

        // if(!transfer.transferBL_edit) {
        //   transfer.state = "";
        // }
        // else {
          switch (transfer_old?.state) {
            case "edit_other":
            case "edit_mix":
              transfer.state = "edit_mix";
              break;
            default:
              transfer.state = "edit_own";
              break;
          }
        // }

        //空白入力時
        if(['whole','auto'].indexOf(wholeOrDiff) !== -1 && valueBL === null) {
          //編集値ならば計算値に戻す
          if(['edit_mix', 'edit_other', 'edit_own'].indexOf(transfer_old?.state) !== -1) {
            dataGroup.calced1 = false; //未計算
            dataGroup.calced3 = false; //未計算
            transfer.state = "";
            dataGroup = calcDataGroupSingle(dataGroup, ['transfer'], ymds, termIndex, termIndex, true, false, editRowKeysSet, averageWeeks);
          }
        }
        
        //キューに追加
        state.saveSeq = state.saveSeq+1;  //カウントアップ
        addSaveQueue.push({
          seq: state.saveSeq,
          centerItem: {
            bumonCD: dataGroup.centerItem.bumonCD, //--部門
            centerCD: dataGroup.centerItem.centerCD, //倉庫(4桁コード)
            centerCD2: dataGroup.centerItem.centerCD2, //倉庫(2桁コード)
            
            itemCD: dataGroup.centerItem.itemCD, //商品CD
            itemCD5: dataGroup.centerItem.itemCD5, //商品CD(5桁コード)
            makerCD: dataGroup.centerItem.makerCD, //メーカー
            blIrisu: dataGroup.centerItem.blIrisu,  //容量1(BL入数) 
          },
          infos: {transfer:  {
            newVal: {...transfer},
            oldVal: transfer_old,
          }},
        });

        //再計算対象
        dataGroup.calced1 = false; //未計算
        dataGroup.calced3 = false; //未計算
        dataGroup.checked = false; //未チェック
        dataGroup.highlighted = false; //未ハイライトチェック

        break;


      case "warehousing": //"入庫",
        const warehousing_old = {...ymdInfo.infos.warehousing};
        const warehousing = ymdInfo.infos.warehousing;
  
        const warehousingBL_old = warehousing_old?.state == 'calc' ? null : getWarehousingBL(warehousing_old, blIrisu);
        const warehousingBL_diff = valueBL === null ? calcUtil.multiply(warehousing.warehousingBL_edit, -1)
          : ['diff','auto'].indexOf(wholeOrDiff) !== -1 ? ( valueBL) //入庫は通常差分入力。空白入力時は、入力値のクリアとする
          : calcUtil.minus(valueBL, warehousingBL_old);
        let new_warehousingBL = calcUtil.plus(warehousing.warehousingBL_edit, warehousingBL_diff);
        //マイナスは何もしない
        if(new_warehousingBL < 0) {
          return;
        }

        switch (numUnit_input) {
          case 'CS':
            {
            //発注単位があれば、CS入り数との最小公倍数とする
            //積付数が指定されていれば、CS入り数との最小公倍数とする
            let times = loadedQty ? calcUtil.lcm(csIrisu, loadedQty) : csIrisu;
            new_warehousingBL　= calcUtil.smallestMultiple(new_warehousingBL, times);
            }
            break;
          case 'LOT':
          case 'auto':
            {
            //CS入り数の倍数とする
            //発注単位があれば、発注単位×CS入り数とする
            //積付数が指定されていれば、CS入り数との最小公倍数とする
            let times = loadedQty ? calcUtil.lcm(csIrisu, loadedQty) : lot && lot > 1 ? csIrisu_lot : csIrisu;
            new_warehousingBL = dataGroup?.makerTagData?.blInputFlg ? new_warehousingBL : calcUtil.smallestMultiple(new_warehousingBL, times);
            }
            break;
          case 'BL':
            //入力値そのまま
              break;
          case 'PS':
          default:
            //無効
            return;
        }

        //同じならば何もしない
        if(!calcUtil.minus(new_warehousingBL　, warehousing_old?.warehousingBL_edit)) {
          return;
        }

        if(doConfirm) {
          //空白入力時は確認ダイアログを表示する
          if(['diff','auto'].indexOf(wholeOrDiff) !== -1 && valueBL === null) {
            confirmEditDatas_warehousing_clear.push(editData);
            return;
          }

          //祝日は確認ダイアログを表示
          if(ymdInfo.holidayDiv == 'holiday') {
            if (!(dataGroup?.makerTagData?.specifiedDateFlg && dataGroup?.makerTagData?.specifiedDateHolFlg)) {
              //入庫可能日指定有効、かつ祝日可ではない場合警告を出す
              confirmEditDatas_warehousing_holiday.push(editData);
              return;
            }
          }
  
          if (dataGroup?.centerItem?.weekdayWarnFlg && dataGroup?.makerTagData?.specifiedDateFlg) {
            const flgs = [
              dataGroup?.makerTagData?.specifiedDateSunFlg,
              dataGroup?.makerTagData?.specifiedDateMonFlg,
              dataGroup?.makerTagData?.specifiedDateTueFlg,
              dataGroup?.makerTagData?.specifiedDateWedFlg,
              dataGroup?.makerTagData?.specifiedDateThrFlg,
              dataGroup?.makerTagData?.specifiedDateFriFlg,
              dataGroup?.makerTagData?.specifiedDateSatFlg,
            ];
            if (!flgs[moment(ymdInfo.ymdID, 'YYYYMMDD').weekday()]) {
              confirmEditDatas_warehousing_warn.push(editData);
              return;
            }
          }
        }

        
        //メーカー残在庫の計算
        let makerLimitData = dataGroup.makerLimitData;
        if(makerLimitData) {
          const new_warehousingBL_diff = calcUtil.minus(new_warehousingBL, warehousing_old?.warehousingBL_edit);
          // let dataGroups:RowDataGroup[] = state.dataGroups.filter(dataGroup => dataGroup.makerLimitData && dataGroup.centerItem.itemCD == itemCD);
          let rowInfos4MakerLimit:RowInfo[] = rowInfos.filter(rowInfo => {
            let rowInfo_dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal);
            return rowInfo.TP == 'info' && rowInfo.rowIndex == 0 && rowInfo_dataGroup.makerLimitData && rowInfo_dataGroup.centerItem.itemCD == itemCD && rowInfo_dataGroup.centerItem.makerCenterName == makerLimitData.makerCenterName;
          });
          let dataGroups4MakerLimit:RowDataGroup[] = rowInfos4MakerLimit.map(rowInfo => getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal));
          makerLimitData = calcMakerLimitData(makerLimitData, dataGroups4MakerLimit, new_warehousingBL_diff);
          dataGroup.makerLimitData = makerLimitData;

          //編集行に追加しておく（再描写用）
          rowInfos4MakerLimit.forEach(rowInfo => {
            let rowInfo_dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal);
            rowInfo_dataGroup.makerLimitData = makerLimitData;
            editedIdxsTopSet.add(rowInfo.index);
          });
        }


        warehousing.warehousingBL_edit = new_warehousingBL;
        if(!warehousing.warehousingBL_edit) {
          warehousing.state = "result";
        }
        else {
          if(editData.auto) {
            warehousing.state = "edit_auto";
          }
          else {
            switch (warehousing_old?.state) {
              case "edit_other":
              case "edit_mix":
              case "edit_auto":
                warehousing.state = "edit_mix";
                break;
              default:
                warehousing.state = "edit_own";
                break;
            }
          }
        }

        //キューに追加
        state.saveSeq = state.saveSeq+1;  //カウントアップ
        addSaveQueue.push({
          seq: state.saveSeq,
          centerItem: {
            bumonCD: dataGroup.centerItem.bumonCD, //--部門
            centerCD: dataGroup.centerItem.centerCD, //倉庫(4桁コード)
            centerCD2: dataGroup.centerItem.centerCD2, //倉庫(2桁コード)
            
            itemCD: dataGroup.centerItem.itemCD, //商品CD
            itemCD5: dataGroup.centerItem.itemCD5, //商品CD(5桁コード)
            makerCD: dataGroup.centerItem.makerCD, //メーカー
            blIrisu: dataGroup.centerItem.blIrisu,  //容量1(BL入数) 
          },
          infos: {warehousing: {
            newVal: {...warehousing},
            oldVal: warehousing_old,
          }},
        });
        //再計算対象
        dataGroup.calced1 = false; //未計算
        dataGroup.calced3 = false; //未計算
        dataGroup.checked = false; //未チェック
        dataGroup.highlighted = false; //未ハイライトチェック

        //バンドルをもっているなら、バンドルも再計算対象
        {
          const func = (bundleDataGrop:RowDataGroup) => {
            if(bundleDataGrop) {
              const bundleRowInfoIndex = findRowInfoTopIndex(bundleDataGrop.centerItem.itemCD, bundleDataGrop.centerItem.centerCD, state.dataGroups, rowInfos);
              if(bundleRowInfoIndex >= 0) {
                editedIdxsTopSet.add(bundleRowInfoIndex);
                editedIdxsTopSet_calcAll.add(bundleRowInfoIndex);  //全体再計算
                editRowKeysSet.add('warehousing');

                const dataGroupKey = `${bundleDataGrop.centerItem.centerCD} ${bundleDataGrop.centerItem.itemCD}`;
                let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
                let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
                editedFromTermIndexMap.set(dataGroupKey, Math.min(editedFromTermIndexOfDataGroup == null ? 999 : editedFromTermIndexOfDataGroup, termIndex));
                editedToTermIndexMap.set(dataGroupKey, Math.max(editedToTermIndexOfDataGroup == null ? -1 : editedToTermIndexOfDataGroup, termIndex));
                // newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
                // state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
            
                bundleDataGrop.calced1 = false; //未計算
                bundleDataGrop.calced2 = false; //未計算
                bundleDataGrop.calced3 = false; //未計算
                bundleDataGrop.checked = false; //未チェック
                bundleDataGrop.highlighted = false; //未ハイライトチェック
              }
            }
          };
          console.log(rowInfo_edit.bundleItemDataGroupIndex);
          if(rowInfo_edit.bundleItemDataGroupIndex >= 0) {
            const bundleDataGrop = getRowInfoDataGroupBundleItem(rowInfo_edit, state.dataGroups);
            func(bundleDataGrop);
          }
        }        
        break;

      case "shipping": //"出庫",
      case "shipping_storeOrder": //"出庫(先付含)",
        const shipping_old = {...ymdInfo.infos.shipping};
        const shipping = ymdInfo.infos.shipping;

        const shippingBL_old = shipping_old?.state == 'calc' ? null : getShippingBL(shipping_old, blIrisu);
        const shippingBL_diff = valueBL === null ? calcUtil.multiply(shipping.shippingBL_edit, -1) 
          : ['diff'].indexOf(wholeOrDiff) !== -1 ? (valueBL) //出庫は通常全量入力。空白入力時は、入力値のクリアとする
          : calcUtil.minus(valueBL, shippingBL_old);
        let new_shippingBL = calcUtil.plus(shipping.shippingBL_edit, shippingBL_diff);
        // //マイナスは何もしない
        // if(new_shippingBL < 0) {
        //   return;
        // }

        switch (numUnit_input) {
          case 'LOT':
            //発注単位の倍数とする
            new_shippingBL= calcUtil.smallestMultiple(new_shippingBL, csIrisu_lot);
            break;
          case 'CS':
            //CS入り数の倍数とする
            new_shippingBL= calcUtil.smallestMultiple(new_shippingBL, csIrisu);
            break;
          case 'BL':
          case 'auto':
              //入力値そのまま
              break;
          case 'PS':
          default:
            //無効
            return;
        }

        //同じならば何もしない
        if(!calcUtil.minus(new_shippingBL, getShippingBL(shipping_old, blIrisu))) {
          return;
        }

        shipping.shippingBL_edit = new_shippingBL;

        // if(!shipping.shippingBL_edit) {
        //   shipping.state = "";
        // }
        // else {
          switch (shipping_old?.state) {
            case "edit_other":
            case "edit_mix":
              shipping.state = "edit_mix";
              break;
            default:
              shipping.state = "edit_own";
              break;
          }
        // }

        //空白入力時
        if(['whole','auto'].indexOf(wholeOrDiff) !== -1 && valueBL === null) {
          //編集値ならば計算値に戻す
          if(['edit_mix', 'edit_other', 'edit_own'].indexOf(shipping_old?.state) !== -1) {
            dataGroup.calced1 = false; //未計算
            dataGroup.calced3 = false; //未計算
            shipping.state = "";
            dataGroup = calcDataGroupSingle(dataGroup, ['shipping'], ymds, termIndex, termIndex, true, false, editRowKeysSet, averageWeeks);
          }
        }
        
        //キューに追加
        state.saveSeq = state.saveSeq+1;  //カウントアップ
        addSaveQueue.push({
          seq: state.saveSeq,
          centerItem: {
            bumonCD: dataGroup.centerItem.bumonCD, //--部門
            centerCD: dataGroup.centerItem.centerCD, //倉庫(4桁コード)
            centerCD2: dataGroup.centerItem.centerCD2, //倉庫(2桁コード)
            
            itemCD: dataGroup.centerItem.itemCD, //商品CD
            itemCD5: dataGroup.centerItem.itemCD5, //商品CD(5桁コード)
            makerCD: dataGroup.centerItem.makerCD, //メーカー
            blIrisu: dataGroup.centerItem.blIrisu,  //容量1(BL入数) 
          },
          infos: {shipping:  {
            newVal: {...shipping},
            oldVal: shipping_old,
          }},
        });

        //再計算対象
        dataGroup.calced1 = false; //未計算
        dataGroup.calced3 = false; //未計算
        dataGroup.checked = false; //未チェック
        dataGroup.highlighted = false; //未ハイライトチェック

        //親センターをもっているなら、親センターも再計算対象
        {
          const func = (oyaDataGrop:RowDataGroup) => {
            if(oyaDataGrop) {
              const oyaRowInfoIndex = findRowInfoTopIndex(oyaDataGrop.centerItem.itemCD, oyaDataGrop.centerItem.centerCD, state.dataGroups, rowInfos);
              if(oyaRowInfoIndex >= 0) {
                editedIdxsTopSet.add(oyaRowInfoIndex);
                editedIdxsTopSet_calcAll.add(oyaRowInfoIndex);  //全体再計算
                editRowKeysSet.add('transfer');

                const dataGroupKey = `${oyaDataGrop.centerItem.centerCD} ${oyaDataGrop.centerItem.itemCD}`;
                let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
                let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
                editedFromTermIndexMap.set(dataGroupKey, Math.min(editedFromTermIndexOfDataGroup == null ? 999 : editedFromTermIndexOfDataGroup, termIndex));
                editedToTermIndexMap.set(dataGroupKey, Math.max(editedToTermIndexOfDataGroup == null ? -1 : editedToTermIndexOfDataGroup, termIndex));
                // newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
                // state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
            
                oyaDataGrop.calced1 = false; //未計算
                oyaDataGrop.calced2 = false; //未計算
                oyaDataGrop.calced3 = false; //未計算
                oyaDataGrop.checked = false; //未チェック
                oyaDataGrop.highlighted = false; //未ハイライトチェック
              }
            }
          };
          if(rowInfo_edit.oyaCenterDataGroupIndex1 >= 0) {
            const oyaDataGrop = getRowInfoDataGroupOyaCneter1(rowInfo_edit, state.dataGroups);
            func(oyaDataGrop);
          }
          if(rowInfo_edit.oyaCenterDataGroupIndex2 >= 0) {
            const oyaDataGrop = getRowInfoDataGroupOyaCneter2(rowInfo_edit, state.dataGroups);
            func(oyaDataGrop);
          }
          if(rowInfo_edit.oyaCenterDataGroupIndex3 >= 0) {
            const oyaDataGrop = getRowInfoDataGroupOyaCneter3(rowInfo_edit, state.dataGroups);
            func(oyaDataGrop);
          }
        }

        break;
      default:
        //何もしない
        return;
    }

    //編集行の記録
    editedIdxsTopSet.add(idx - rowInfo_edit.rowIndex);
    editRowKeysSet.add(rowKey);

    

    //編集列の記録
    editedFromTermIndex = Math.min(editedFromTermIndex, termIndex);
    editedToTermIndex = Math.max(editedToTermIndex, termIndex);

    const dataGroupKey = `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.itemCD}`;
    let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
    let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
    editedFromTermIndexMap.set(dataGroupKey, Math.min(editedFromTermIndexOfDataGroup == null ? 999 : editedFromTermIndexOfDataGroup, termIndex));
    editedToTermIndexMap.set(dataGroupKey, Math.max(editedToTermIndexOfDataGroup == null ? -1 : editedToTermIndexOfDataGroup, termIndex));
    // newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
    // state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
  });

  //確認ダイアログの表示
  if(confirmEditDatas_warehousing_clear.length) {
    state.confirmEditInfo_warehousing_clear = {...editInfo, ...{editDatas: confirmEditDatas_warehousing_clear}};
  }
  if(confirmEditDatas_warehousing_holiday.length) {
    state.confirmEditInfo_warehousing_holiday = {...editInfo, ...{editDatas: confirmEditDatas_warehousing_holiday}};
  }
  if (confirmEditDatas_warehousing_warn.length) {
    state.confirmEditInfo_warehousing_warn = { ...editInfo, ...{ editDatas: confirmEditDatas_warehousing_warn } };
  }

  const editedIdxsTop:number[] = Array.from(editedIdxsTopSet);
  if(editedIdxsTop.length > 0) {
    const editedRowsTop:number[] = [];
    editedIdxsTop.forEach(idx => {
      let new_dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfos[idx], state.dataGroups, state.dataGroupTotal);
      const dataGroupKey = `${new_dataGroup.centerItem.centerCD} ${new_dataGroup.centerItem.itemCD}`;
      let editedFromTermIndexOfDataGroup = editedFromTermIndexMap.get(dataGroupKey);
      let editedToTermIndexOfDataGroup = editedToTermIndexMap.get(dataGroupKey);
      if(editedIdxsTopSet_calcAll.has(idx)) {
        new_dataGroup = calcDataGroupAll(new_dataGroup, state.dataGroups, rowKeys, ymds, editedFromTermIndexOfDataGroup, ymds.length-1, true, true, editRowKeysSet, averageWeeks);
      }
      else {
        new_dataGroup = calcDataGroupSingle(new_dataGroup, rowKeys, ymds, editedFromTermIndexOfDataGroup, ymds.length-1, true, true, editRowKeysSet, averageWeeks);
      }
      new_dataGroup = checkDataGroup(new_dataGroup, ymds, editedFromTermIndexOfDataGroup, ymds.length-1); //データチェック
      new_dataGroup = highlightDataGroup(new_dataGroup, ymds, editedFromTermIndexOfDataGroup, ymds.length-1, holidays, alertSetting); //ハイライト
      // rowInfos[row].dataGroup = new_dataGroup;

      //stateを置換する
      // const index = state.dataGroups.findIndex(dataGroup => `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.itemCD}` == dataGroupKey);
      // state.dataGroups[index] = new_dataGroup;

      const row = idxToRow(idx, pageInfo, state.fixedRowsTop);
      if(row >= 0) {
        editedRowsTop.push(row);
      }
    });

    //合計
    if(state.fixedRowsTop > 0) {
      const dataGroups = state.dataGroups;
      let dataGroupTotal = udpateDataGroupTotal(state.dataGroupTotal, dataGroups, rowKeys, ymds, editedFromTermIndex, ymds.length-1, showSubTotal, showGroupRow);  //合計計算
      state.dataGroupTotal = dataGroupTotal;
      editedRowsTop.push(0);
    }


    fncAfterEditRowDatas(state, editedRowsTop, editRowKeysSet, [], pageInfo, colRowModel, numUnit_view, visibleAttributes, onlyNormal, result_type);

    //キューに追加
    state.saveQueue = [...state.saveQueue, ...addSaveQueue];
    state.saveVer = state.saveVer+1;  //カウントアップし登録処理を開始する

  }
};






const fnc_removeSaveQueue = (state, saveInfos:SaveInfoData[]) => {
  console.log('fnc_removeSaveQueue');

  if(!saveInfos || !saveInfos.length) {
    return;
  }
  const saveInfosSeq:number[] = saveInfos.map(saveInfo => saveInfo.seq);
  state.saveQueue = state.saveQueue.filter(saveInfo => saveInfosSeq.indexOf(saveInfo.seq) == -1);
};
const fnc_removeLoadQueue = (state, loadInfos:DiffData[]) => {
  console.log('fnc_removeLoadQueue');

  if(!loadInfos || !loadInfos.length) {
    return;
  }

  const loadInfosSeq:number[] = loadInfos.map(loadInfo => loadInfo.seq);
  state.loadQueue = state.loadQueue.filter(loadInfo => loadInfosSeq.indexOf(loadInfo.seq) == -1);
};



//RowDataGroupに変換
const convertDataGroups = (ymds: YMD[], centerItems: CenterItem[]): [RowDataGroup[], CenterItem[]] => {

  //indexでソート
  ymds = ymds.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareNumber(a.index, b.index, true)
    return comp;
  });  


  //商品、センター順でソート
  centerItems = centerItems.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    return comp;
  });

  return [centerItems.map(centerItem => {

    return {
      centerItem: centerItem,
    
      ymdInfos: ymds.map((ymd, ymdIndex):YMDInfo => {
        const keyInfo = `${centerItem.itemCD} ${centerItem.centerCD} ${ymd.ymdID}`;
        return {
          ymdID: ymd.ymdID, 
          type: ymd.type,
          holidayDiv: ymd.holidayDiv,
          week_id: ymd.week_id,
          week: ymd.week,
          infos: {},
          comments: {},
        }
      }),
    };
  }), centerItems];
}
const updateDataGroups_infos = (
  ymds: YMD[], 
  targetCenterItems: CenterItem[], 
  dataGroups: RowDataGroup[], 
  infosStoreSales:InfoDataStoreSales[], 
  infosTransferIn:InfoDataTransfer[], 
  infosTransferOut:InfoDataTransfer[], 
  infosWarehousing:InfoDataWarehousing[], 
  infosWarehousingLast:InfoDataWarehousing[], 
  infosOrder:InfoDataOrder[], 
  infosBestBefore:InfoDataBestBefore[], 
  infosStoreOrder:InfoDataStoreOrder[], 
  infosShipping:InfoDataShipping[], 
  infosShippingLast:InfoDataShipping[], 
  infosShippingTokubai:InfoDataShippingTokubai[], 
  infosShippingTokubaiLast:InfoDataShippingTokubai[], 
  infosStock:InfoDataStock[],
  infosWeather: OpenMeteoDaily[],
  todayType: 'today' | 'now' | string,
): RowDataGroup[] => {

  const tokubaiMinus:boolean = (infosShippingTokubai?.length ?? 0) > 0;
  const tokubaiMinusLast:boolean = (infosShippingTokubaiLast?.length ?? 0) > 0;

  //特売マイナス処理用の週番号坂引きマップ
  const weekIdMap = new Map<number, number>();
  if(tokubaiMinus){
    let weekYmd:YMD;
    [...ymds].reverse().forEach(ymd => {
      if(ymd.week) {
        weekYmd = ymd;
      }
      else if(weekYmd) {
        weekIdMap.set(ymd.ymdID, weekYmd.ymdID);
      }
    });
  }

  //map作成
  const infoStoreSalesMap = {};
  const infoTransferInMap = {};
  const infoTransferOutMap = {};
  const infoWarehousingMap = {};
  const infoWarehousingLastMap = {};
  const infoOrderMap = {};
  const infoBestBeforeMap = {};
  const infoStoreOrderMap = {};
  const infoShippingMap = {};
  const infoShippingLastMap = {};
  const infoStockMap = {};
  const infosWeatherMap = {};

  const mapShippingForTokubaiMinus = new Map<string, InfoDataShipping[]>(); //特売マイナス処理用のマップ
  const mapStoreOrderForTokubaiMinus = new Map<string, InfoDataStoreOrder[]>(); //特売マイナス処理用のマップ
  const mapShippingForTokubaiMinusLast = new Map<string, InfoDataShipping[]>(); //特売マイナス処理用のマップ

  infosStoreSales.forEach(info => {
    infoStoreSalesMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosTransferIn.forEach(info => {
    infoTransferInMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosTransferOut.forEach(info => {
    infoTransferOutMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosWarehousing.forEach(info => {
    infoWarehousingMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosWarehousingLast.forEach(info => {
    infoWarehousingLastMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosOrder.forEach(info => {
    infoOrderMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosBestBefore.forEach(info => {
    infoBestBeforeMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosStoreOrder.forEach(info => {
    infoStoreOrderMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;

    //特売マイナス処理用のマップ
    if(tokubaiMinus){
      const key = `${info.centerCD} ${info.itemCD}`;
      let datas = mapStoreOrderForTokubaiMinus.get(key);
      if(!datas) {
        datas = [];
      }
      datas.push(info);
      mapStoreOrderForTokubaiMinus.set(key, datas);
    }
  });
  infosShipping.forEach(info => {
    infoShippingMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;

    //特売マイナス処理用のマップ
    if(tokubaiMinus){
      const key = `${info.centerCD} ${info.itemCD}`;
      let datas = mapShippingForTokubaiMinus.get(key);
      if(!datas) {
        datas = [];
      }
      datas.push(info);
      mapShippingForTokubaiMinus.set(key, datas);
    }
  });
  infosShippingLast.forEach(info => {
    infoShippingLastMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;

    //特売マイナス処理用のマップ
    if(tokubaiMinus){
      const key = `${info.centerCD} ${info.itemCD}`;
      let datas = mapShippingForTokubaiMinusLast.get(key);
      if(!datas) {
        datas = [];
      }
      datas.push(info);
      mapShippingForTokubaiMinusLast.set(key, datas);
    }
  });
  infosStock.forEach(info => {
    infoStockMap[`${info.itemCD} ${info.centerCD} ${info.ymdID}`] = info;
  });
  infosWeather.forEach(info => {
    infosWeatherMap[`${info.centerCD} ${info.ymdID}`] = info;
  });
  //特売管理
  if(tokubaiMinus) {
    infosShippingTokubai.forEach(info => {
      const key = `${info.centerCD} ${info.itemCD}`;
      let ymd_f = info.ymdID_f;
      let ymd_t = info.ymdID_t;
      //出庫からマイナス
      {
        let datas = mapShippingForTokubaiMinus.get(key);
        if(datas) {
          //週マップ作製
          const datasWeekMap = new Map<number, InfoDataShipping>();
          datas.filter(data => data.ymdID <= 999999).forEach(data => {
            datasWeekMap.set(data.ymdID, data);
          });
          //特売期間でフィルタ
          datas = datas.filter(data => data.ymdID >= ymd_f && data.ymdID <= ymd_t && data.shippingPS_result > 0);
          if(datas?.length){
            let minus_num = info.shippingPS_result;
            if(minus_num > 0) {

              //順番に削る
              datas.forEach(data => {
                data.groups?.filter(group => group.group2CD == info.group2CD && group.group1CD == info.group1CD)
                  .forEach(group => {
                    const num_old = group.shippingPS_result;
                    const num_new = Math.max(num_old - minus_num, 0);
                    const num_dif = num_old - num_new;
                    if(num_dif > 0) {
                      group.shippingPS_result -= num_dif;
                      data.shippingPS_result -= num_dif;
                      minus_num -= num_dif;

                      //週からもマイナスする
                      const weekYmdId = weekIdMap.get(data.ymdID);
                      if(weekYmdId) {
                        const weekData = datasWeekMap.get(weekYmdId);
                        if(weekData) {
                          weekData.shippingPS_result -= num_dif;
                        }
                      }

                    }
                  });
              });
            }
          }
        }
      }
      //受注からマイナス
      {
        let datas = mapStoreOrderForTokubaiMinus.get(key);
        if(datas) {
          //週マップ作製
          const datasWeekMap = new Map<number, InfoDataStoreOrder>();
          datas.filter(data => data.ymdID <= 999999).forEach(data => {
            datasWeekMap.set(data.ymdID, data);
          });
          //特売期間でフィルタ
          datas = datas.filter(data => data.ymdID >= ymd_f && data.ymdID <= ymd_t && data.storeOrderPS > 0);
          if(datas?.length){
            let minus_num = info.shippingPS_result;
            if(minus_num > 0) {
              //順番に削る
              datas.forEach(data => {
                data.groups?.filter(group => group.group2CD == info.group2CD && group.group1CD == info.group1CD)
                  .forEach(group => {

                    const num_old = group.storeOrderPS;
                    const num_new = Math.max(num_old - minus_num, 0);
                    const num_dif = num_old - num_new;
                    if(num_dif > 0) {
                      group.storeOrderPS -= num_dif;
                      data.storeOrderPS -= num_dif;
                      minus_num -= num_dif;

                      //週からもマイナスする
                      const weekYmdId = weekIdMap.get(data.ymdID);
                      if(weekYmdId) {
                        const weekData = datasWeekMap.get(weekYmdId);
                        if(weekData) {
                          weekData.storeOrderPS -= num_dif;
                        }
                      }
                    }
                  });
              });
            }
          }
        }
      }
    });
    //不要な内訳は削除
    infosShipping.forEach(info => {info.groups = null});
    infosStoreOrder.forEach(info => {info.groups = null});
  }
  //特売管理
  if(tokubaiMinusLast) {
    //出庫からマイナス
    infosShippingTokubaiLast.forEach(info => {
      const key = `${info.centerCD} ${info.itemCD}`;
      let ymd_f = info.ymdID_f;
      let ymd_t = info.ymdID_t;
      //出庫からマイナス
      {
        let datas = mapShippingForTokubaiMinusLast.get(key);
        if(datas) {
          //週マップ作製
          const datasWeekMap = new Map<number, InfoDataShipping>();
          datas.filter(data => data.ymdID <= 999999).forEach(data => {
            datasWeekMap.set(data.ymdID, data);
          });
          //特売期間でフィルタ
          datas = datas.filter(data => data.ymdID >= ymd_f && data.ymdID <= ymd_t && data.shippingPS_result > 0);
          if(datas?.length){
            let minus_num = info.shippingPS_result;
            //順番に削る
            datas.forEach(data => {
              data.groups?.filter(group => group.group2CD == info.group2CD && group.group1CD == info.group1CD)
                .forEach(group => {
                  if(minus_num > 0) {
                    const num_old = group.shippingPS_result;
                    const num_new = Math.max(num_old - minus_num, 0);
                    const num_dif = num_old - num_new;
                    if(num_dif > 0) {
                      group.shippingPS_result -= num_dif;
                      data.shippingPS_result -= num_dif;
                      minus_num -= num_dif;

                      //週からもマイナスする
                      const weekYmdId = weekIdMap.get(data.ymdID);
                      if(weekYmdId) {
                        const weekData = datasWeekMap.get(weekYmdId);
                        if(weekData) {
                          weekData.shippingPS_result -= num_dif;
                        }
                      }
                    }
                  }
                });
            });
          }
        }
      }
    });
    //不要な内訳は削除
    infosShippingLast.forEach(info => {info.groups = null});
  }

  //map作成
  const targetKeys = {};
  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    targetKeys[key] = key;
  });

  const dataGroupsMap = {};
  dataGroups.forEach(dataGroup => {
    const centerItem = dataGroup.centerItem;
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    //更新対象じゃなければreturn
    if(!targetKeys[key]) {
      return;
    }
    dataGroupsMap[key] = dataGroup;
    //検索済みを記録
    dataGroup.searchedResults = true;
  });

  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    const dataGroup = dataGroupsMap[key];
    if(!dataGroup) {
      return;
    }

    let infoStockBefore: InfoDataStock;
    let infoStockBefore_similarItem: InfoDataStock;

    dataGroup.ymdInfos.forEach((ymdInfo:YMDInfo) => {

      const keyInfo = `${centerItem.itemCD} ${centerItem.centerCD} ${ymdInfo.ymdID}`;
      const keyInfo_similarItemCD = centerItem?.similarItemCD ? `${centerItem?.similarItemCD} ${centerItem.centerCD} ${ymdInfo.ymdID}` : null;
      const keyWeather = `${centerItem.centerCD} ${ymdInfo.ymdID}`;
      // 実績
      const infos = {
        storeSales: infoStoreSalesMap[keyInfo],
        transferIn: infoTransferInMap[keyInfo],
        transferOut: infoTransferOutMap[keyInfo],
        warehousing: infoWarehousingMap[keyInfo],
        warehousing_last: infoWarehousingLastMap[keyInfo],
        order: infoOrderMap[keyInfo],
        bestBefore: infoBestBeforeMap[keyInfo],
        storeOrder: infoStoreOrderMap[keyInfo],
        shipping: infoShippingMap[keyInfo],
        shipping_last: infoShippingLastMap[keyInfo],
        stock: infoStockMap[keyInfo],
        weather: infosWeatherMap[keyWeather],

        similarItem_warehousing: null,
        similarItem_shipping:null,
        similarItem_stock: null,
        similarItem_storeOrder: null,
      };
      //類似商品は単位を変更してセット
      if(keyInfo_similarItemCD) {
        if(infoWarehousingMap[keyInfo_similarItemCD]) {
          infos.similarItem_warehousing = {...infoWarehousingMap[keyInfo_similarItemCD]};
          //新アイテムのBL数で計算しなおす
          infos.similarItem_warehousing.warehousingBL_plan = getNumBLfromPS(getNumPS(infos.similarItem_warehousing.warehousingBL_plan, centerItem.similarItemBlIrisu), centerItem.blIrisu);
        }
        if(infoShippingMap[keyInfo_similarItemCD]) {
          infos.similarItem_shipping = {...infoShippingMap[keyInfo_similarItemCD]};
          //新アイテムのBL数で計算しなおす
          infos.similarItem_shipping.shippingBL_zantei = getNumBLfromPS(getNumPS(infos.similarItem_shipping.shippingBL_zantei, centerItem.similarItemBlIrisu), centerItem.blIrisu);
        }
        if(infoStockMap[keyInfo_similarItemCD]) {
          infos.similarItem_stock = {...infoStockMap[keyInfo_similarItemCD],};
          //新アイテムのBL数で計算しなおす
          infos.similarItem_stock.stockNormalBL = getNumBLfromPS(getNumPS(infos.similarItem_stock.stockNormalBL, centerItem.similarItemBlIrisu), centerItem.blIrisu);
          infos.similarItem_stock.stockUseUpBL = getNumBLfromPS(getNumPS(infos.similarItem_stock.stockUseUpBL, centerItem.similarItemBlIrisu), centerItem.blIrisu);
          infos.similarItem_stock.stockReturnedBL = getNumBLfromPS(getNumPS(infos.similarItem_stock.stockReturnedBL, centerItem.similarItemBlIrisu), centerItem.blIrisu);
        
        }
        if(infoStoreOrderMap[keyInfo_similarItemCD]) {
          infos.similarItem_storeOrder = {...infoStoreOrderMap[keyInfo_similarItemCD]};
        }

      }

      if(!infos.transferIn) {
        //未来分は初期化
        if(ymdInfo.type == 'future' || ymdInfo.type == 'today') {
          infos.transferIn = {
            ymdID: ymdInfo.ymdID, //年月日
            centerCD: centerItem.centerCD, //倉庫
            itemCD: centerItem.itemCD, //商品
          };
        }
      }
      if(!infos.warehousing) {
        //未来分は初期化
        if(ymdInfo.type == 'future') {
          infos.warehousing = {
            ymdID: ymdInfo.ymdID, //年月日
            centerCD: centerItem.centerCD, //倉庫
            itemCD: centerItem.itemCD, //商品
          };
        }
      }
      // if(!infos.order) {
      //   //未来分は初期化
      //   if(ymdInfo.type == 'future') {
      //     infos.order = {
      //       ymdID: ymdInfo.ymdID, //年月日
      //       centerCD: centerItem.centerCD, //倉庫
      //       itemCD: centerItem.itemCD, //商品
      //     };
      //   }
      // }
      if(!infos.shipping) {
        //未来分は初期化
        if(ymdInfo.type == 'future') {
          infos.shipping = {
            ymdID: ymdInfo.ymdID, //年月日
            centerCD: centerItem.centerCD, //倉庫
            itemCD: centerItem.itemCD, //商品
          };
        }
      }
      {//在庫
        if (!infos.stock) {
        // //過去で在庫レコードが無ければ、前日分を利用する
        // if((ymdInfo.type == 'past') && infoStockBefore) {
        //   infos.stock = infoStockBefore;
        // }
        // else {
          infos.stock = {
            ymdID: ymdInfo.ymdID, //年月日
            centerCD: centerItem.centerCD, //倉庫
            itemCD: centerItem.itemCD, //商品
          };
        // }
        }
        if (ymdInfo.type === 'today') {
          infos.stock = {
            ...infos.stock,
            todayType: todayType,
          };
        }
      }
      if(!infos.similarItem_stock) {
        // //過去で在庫レコードが無ければ、前日分を利用する
        // if((ymdInfo.type == 'past') && infoStockBefore_similarItem) {
        //   infos.similarItem_stock = infoStockBefore_similarItem;
        // }
        // else {
          infos.similarItem_stock = {
            ymdID: ymdInfo.ymdID, //年月日
            centerCD: centerItem.centerCD, //倉庫
            itemCD: centerItem.itemCD, //商品
          };
        // }
      }


      ymdInfo.infos = infos;

      //前日分を保存
      infoStockBefore = infos.stock;
      infoStockBefore_similarItem = infos.similarItem_stock;

    });
    //再計算対象
    dataGroup.calced = false; //未計算
    dataGroup.checked = false; //未チェック
    dataGroup.highlighted = false; //未ハイライトチェック


    //在庫賞味期限の設定
    if(dataGroup?.centerItem?.zaikoKigenUketsukeYmd) {
      const keyZaikoKigen = `${dataGroup.centerItem.itemCD} ${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.zaikoKigenUketsukeYmd}`;
      const infoBestBeforeZaikoKigen:InfoDataBestBefore = infoBestBeforeMap[keyZaikoKigen];
      if(infoBestBeforeZaikoKigen && infoBestBeforeZaikoKigen.bestBefore) {
        dataGroup.centerItem.zaikoKigen = infoBestBeforeZaikoKigen.bestBefore;
      
      }
    }
    {
      const today = moment(dataGroup.ymdInfos.find((ymdInfo: YMDInfo) => ymdInfo.type == 'today')?.ymdID, 'YYYYMMDD');
      const infos = dataGroup.ymdInfos
        .filter((ymdInfo: YMDInfo) => ymdInfo.type == 'future')
        .map((ymdInfo: YMDInfo) => {
          const future = moment(ymdInfo.ymdID, 'YYYYMMDD');
          return {
            //ymd: future.format('YYYY/MM/DD'),
            diff: future.diff(today, 'day'),
            //weathercode: ymdInfo.infos.weather?.weathercode,
            state: ymdInfo.infos.weather?.state,
          };
        })
        .filter(info => info.diff <= 14 && info.state) // 14日以内で警告表示
        ;
      dataGroup.weatherAlert = (infos.length > 0);
    }

  });



  return dataGroups;
}
const updateDataGroups_infos_shipping_group1 = (
  targetCenterItems: CenterItem[], 
  dataGroups: RowDataGroup[], 
  infosShippingGroup1:InfoDataShippingGroup1[], 
): RowDataGroup[] => {
  //map作成
  const infosShippingGroup1Map = {};
  //企業G１ヘッダー
  const group1sSet = new Set<string>();
  const group1sNameMap = {};

  infosShippingGroup1.forEach(info => {
    const key = `${info.itemCD} ${info.centerCD} ${info.ymdID}`;
    const group1key = `${info.group2CD}_${info.group1CD}`;
    let infos = infosShippingGroup1Map[key];
    if(!infos) {
      infos = {};
      infosShippingGroup1Map[key] = infos;
    }
    infos[group1key] = info;

    group1sSet.add(group1key);
    group1sNameMap[group1key] = info.group1NM;
  });

  //map作成
  const targetKeys = {};
  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    targetKeys[key] = key;
  });

  const dataGroupsMap = {};
  dataGroups.forEach(dataGroup => {
    const centerItem = dataGroup.centerItem;
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    //更新対象じゃなければreturn
    if(!targetKeys[key]) {
      return;
    }
    dataGroupsMap[key] = dataGroup;
    //検索済みを記録
    dataGroup.shipping_group1s_key = Array.from(group1sSet);
    dataGroup.shipping_group1s_name = group1sNameMap;
  });

  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    const dataGroup = dataGroupsMap[key];
    if(!dataGroup) {
      return;
    }

    dataGroup.ymdInfos.forEach((ymdInfo:YMDInfo) => {

      const keyInfo = `${centerItem.itemCD} ${centerItem.centerCD} ${ymdInfo.ymdID}`;
      // 実績
      const shipping_group1s = infosShippingGroup1Map[keyInfo];
      ymdInfo.infos.shipping_group1s = shipping_group1s;
    });
  });

  return dataGroups;
}
const updateDataGroups_comments = (
  targetCenterItems: CenterItem[], 
  dataGroups: RowDataGroup[], 
  comments:CommentData[]
  ): [RowDataGroup[], RowDataGroup[]] => {

  //map作成
  const targetKeys = {};
  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    targetKeys[key] = key;
  });
  //map作成
  const dataGroupsMap = {};
  dataGroups.forEach(dataGroup => {
    const centerItem = dataGroup.centerItem;
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    //更新対象じゃなければreturn
    if(!targetKeys[key]) {
      return;
    }
    dataGroupsMap[key] = dataGroup;
    //コメント初期化
    dataGroup.ymdInfos.forEach((ymdInfo:YMDInfo) => {
      ymdInfo.comments = null;
    });
    //検索済みを記録
    dataGroup.searchedComments = true;
  });

  //map作成
  const commentDataMap = {};
  comments.forEach(comment => {
    const key = `${comment.itemCD} ${comment.centerCD}`;

    let commentDataSubMap = commentDataMap[key];
    if(!commentDataSubMap) {
      commentDataSubMap = {};
      commentDataMap[key] = commentDataSubMap;
    }

    //セルコメント
    if(comment.ymdID && comment.keyCD) {
      const keyComment = `${comment.itemCD} ${comment.centerCD} ${comment.ymdID} ${comment.keyCD}`;
      commentDataSubMap[keyComment] = comment;
    }
    //グループコメント
    else {
      const keyComment = `${comment.itemCD} ${comment.centerCD} ${comment.keyCD}`;  //'G00形式'はグループコメント
      commentDataSubMap[keyComment] = comment;
    }
  });
  

  const dataGroupsChanged:RowDataGroup[] = [];

  Object.keys(commentDataMap).forEach(key => {
    const dataGroup:RowDataGroup = dataGroupsMap[key];
    if(dataGroup) {
      const centerItem = dataGroup.centerItem;

      const commentDataSubMap = commentDataMap[key];
      if(commentDataSubMap) {
        Object.keys(commentDataSubMap).forEach(keyComment => {
          const comment: CommentData = commentDataSubMap[keyComment];

          const keyCommentSplit:string[] = keyComment.split(' ');
          //セルコメント
          if(keyCommentSplit.length == 4) {
            const ymdID = parseInt(keyCommentSplit[2]);
            const rowKey = keyCommentSplit[3];
            const ymdInfo = dataGroup.ymdInfos.find(ymdInfo => ymdInfo.ymdID == ymdID);
            let comments = ymdInfo.comments;
            if(!comments) {
              comments = {};
            }
            comments[rowKey] = comment;
            ymdInfo.comments = comments;
          }
          //グループコメント
          else if(keyCommentSplit.length == 3) {
            const rowKey = keyCommentSplit[2];  //'G00形式'はグループコメント
            const commentIndex = parseInt(rowKey.slice(-2));
            let comments = dataGroup.comments;
            if(!comments) {
              comments = {};
              dataGroup.comments = comments;
            }
            comments[commentIndex] = comment;
          }
        });
      }

      dataGroupsChanged.push(dataGroup);
    }
  });

  return [dataGroupsChanged, dataGroups];
}
const updateDataGroups_itemTags = (
  targetCenterItems: CenterItem[], 
  dataGroups: RowDataGroup[], 
  itemTagDatas: ItemTagData[],
  itemCommonDatas: ItemCommonData[],
  disableDateDatas: DisableDateData[],
): [RowDataGroup[], RowDataGroup[]] => {

  //map作成
  const targetKeys = {};
  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    targetKeys[key] = key;
  });

  const dataGroupsMap = {};
  dataGroups.forEach(dataGroup => {
    const centerItem = dataGroup.centerItem;
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    //更新対象じゃなければreturn
    if(!targetKeys[key]) {
      return;
    }
    dataGroupsMap[key] = dataGroup;
    //検索済みを記録
    dataGroup.searchedItemTags = true;
  });

  //map作成
  const keys = new Set<string>();

  const itemTagDatasMap = {};
  const itemTagDataMap_NEW = {};
  const itemTagDataMap_CPN = {};
  const itemTagDataMap_CUT = {};
  const itemTagDataMap_EMC = {};
  const itemTagDataMap_PKG = {};
  const itemTagDataMap_OTH = {};
  const itemCommonDataMap = {};
  const disableDateDataMap = {};

  itemTagDatas = itemTagDatas.sort(fncItemTagDataSort);
  itemTagDatas.forEach(itemTagData => {
    const key = `${itemTagData.itemCD} ${itemTagData.centerCD}`;
    keys.add(key);

    if(!itemTagData.delFlg) {
      //itemTagDatasMap
      if(itemTagData.validToday || itemTagData.validFuture) {
        let list = itemTagDatasMap[key];
        if(!list) {
          list = [];
          itemTagDatasMap[key] = list;
        }
        list.push(itemTagData);
      }

      switch (itemTagData.tagCD) {
        case "NEW":
          itemTagDataMap_NEW[key] = itemTagData;
          break;
        case "CPN":
          itemTagDataMap_CPN[key] = itemTagData;
          break;
        case "CUT":
          itemTagDataMap_CUT[key] = itemTagData;
          break;
        case "EMC":
          itemTagDataMap_EMC[key] = itemTagData;
          break;
        case "PKG":
          itemTagDataMap_PKG[key] = itemTagData;
          break;
        case "OTH":
          itemTagDataMap_OTH[key] = itemTagData;
          break;
        default:
          break;
      }

    }

  });

  itemCommonDatas.forEach(itemCommonData => {
    const key = `${itemCommonData.itemCD} ${itemCommonData.centerCD}`;
    keys.add(key);

    if(!itemCommonData.delFlg) {
      itemCommonDataMap[key] = itemCommonData;
    }
  });

  disableDateDatas.forEach(disableDateData => {
    const key = `${disableDateData.itemCD} ${disableDateData.centerCD}`;
    keys.add(key);

    disableDateDataMap[key] = disableDateData;
  });

  const dataGroupsChanged:RowDataGroup[] = [];

  Array.from(keys).forEach(key => {
    const dataGroup = dataGroupsMap[key];
    if(dataGroup) {
      dataGroup.itemTagDatas = itemTagDatasMap[key];

      const itemTag_NEW:ItemTagData = itemTagDataMap_NEW[key];
      dataGroup.centerItem.similarItemCD = itemTag_NEW?.similarItemCD;
      dataGroup.centerItem.similarItemBlIrisu = itemTag_NEW?.similarItemBlIrisu;
      dataGroup.itemTag_NEW = itemTag_NEW;
      dataGroup.itemTag_CPN = itemTagDataMap_CPN[key];
      dataGroup.itemTag_CUT = itemTagDataMap_CUT[key];
      dataGroup.itemTag_EMC = itemTagDataMap_EMC[key];
      dataGroup.itemTag_PKG = itemTagDataMap_PKG[key];
      dataGroup.itemTag_OTH = itemTagDataMap_OTH[key];

      dataGroup.itemCommonData = itemCommonDataMap[key];

      dataGroup.disableDateData = disableDateDataMap[key];

      dataGroupsChanged.push(dataGroup);
    }
  });

  return [dataGroupsChanged, dataGroups];
}

const updateDataGroups_makerTags = (
  targetCenterItems: CenterItem[], 
  dataGroups: RowDataGroup[], 
  makerTagDatas: MakerTagData[]
): [RowDataGroup[], RowDataGroup[]] => {

  //map作成
  const targetKeys = {};
  targetCenterItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    targetKeys[key] = key;
  });

  const dataGroupsMap = {};
  dataGroups.forEach(dataGroup => {
    const centerItem = dataGroup.centerItem;
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    //更新対象じゃなければreturn
    if(!targetKeys[key]) {
      return;
    }
    dataGroupsMap[key] = dataGroup;
    //検索済みを記録
    dataGroup.searchedMakerTags = true;
  });


  //map作成
  const makerTagDatasMap = {};
  makerTagDatas.forEach(makerTagData => {
    const key = `${makerTagData.makerCD} ${makerTagData.centerCD}`;
    makerTagDatasMap[key] = makerTagData;
  });


  const dataGroupsChanged:RowDataGroup[] = [];

  targetCenterItems.forEach(centerItem => {
    const dataGroupKey = `${centerItem.itemCD} ${centerItem.centerCD}`;
    const dataGroup = dataGroupsMap[dataGroupKey];
    if(!dataGroup) {
      return;
    }

    const makerTagKey = `${centerItem.makerCD} ${centerItem.centerCD}`;
    const makerTagData = makerTagDatasMap[makerTagKey];
    if(!makerTagData) {
      return;
    }

    dataGroup.makerTagData = !makerTagData.delFlg ? makerTagData : null;

    dataGroupsChanged.push(dataGroup);
  });

  return [dataGroupsChanged, dataGroups];
}

const updateDataGroups_makerLimits = (
  targetCenterItems: CenterItem[], 
  dataGroups: RowDataGroup[], 
  makerLimitDatas: MakerLimitData[]
): RowDataGroup[] => {

  
  //map作成
  const makerLimitDatasMap = {};
  makerLimitDatas.forEach(makerLimitData => {
    const key = `${makerLimitData.itemCD} ${makerLimitData.bumonCD} ${makerLimitData.makerCenterName}`;
    makerLimitDatasMap[key] = makerLimitData;
  });


  //map作成
  dataGroups.forEach(dataGroup => {
    const centerItem = dataGroup.centerItem;
    const key = `${centerItem.itemCD} ${centerItem.bumonCD} ${centerItem.makerCenterName}`;
    const makerLimitData = makerLimitDatasMap[key];

    if(makerLimitData) {
      dataGroup.makerLimitData = makerLimitData;
    }
  });

  return dataGroups;
}

//合計行の計算
const createDataGroupTotal = (ymds: YMD[], dataGroups:RowDataGroup[], 
  showSubTotal:boolean,
  showGroupRow:boolean
  ): RowDataGroup => {
  console.log('store.createDataGroupTotal');
  //indexでソート
  ymds = ymds.sort((a,b) => {
    let comp = 0;
    comp = compareUtil.compareNumber(a.index, b.index, true)
    return comp;
  });

  const dataGroupTotal:RowDataGroup = {
    centerItem: {},
    itemTagDatas: [],
    ymdInfos: ymds.map((ymd, ymdIndex):YMDInfo => {
      return {
        ymdID: ymd.ymdID, 
        type: ymd.type,
        holidayDiv: ymd.holidayDiv,
        week_id: ymd.week_id,
        week: ymd.week,
        infos: {
          weather: {
            ymdID: ymd.ymdID,
          },
          storeSales:{
            ymdID: ymd.ymdID,
            state: '',
          },
          transferIn:{
            ymdID: ymd.ymdID,
            state: '',
          },
          transferOut:{
            ymdID: ymd.ymdID,
            state: '',
          },
          warehousing:{
            ymdID: ymd.ymdID,
            state: '',
          },
          // order:{
          //   ymdID: ymd.ymdID,
          //   state: '',
          // },
          // bestBefore:{
          //   ymdID: ymd.ymdID,
          //   state: '',
          // },
          storeOrder:{
            ymdID: ymd.ymdID,
            state: '',
          },
          shipping:{
            ymdID: ymd.ymdID,
            state: '',
          },
          stock:{
            ymdID: ymd.ymdID,
            stateNormal: '',
            stateUseUp: '',
            stateReturned: '',
          },
        },
      }
    }),
  };

  return dataGroupTotal;
}
//合計行の再計算
const udpateDataGroupTotal = (dataGroupTotal:RowDataGroup, dataGroups:RowDataGroup[], rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, 
  showSubTotal:boolean,
  showGroupRow:boolean
  ): RowDataGroup => {
  if(!showSubTotal) {
    return dataGroupTotal;
  }

  
  const targetYmds = ymds.filter((ymd, index) => fromIndex <= index && index <= toIndex);
  const targetDataGroups = dataGroups.filter(dataGroup => dataGroup.displayed);
  console.log(`udpateDataGroupTotal[ymd=${targetYmds.length}, dataGroup=${targetDataGroups.length}]`);

  rowKeys.forEach(rowKey => {
    switch (rowKey) {
      case 'weather':
        break;
      case 'storeSales':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let storeSalesCS:number;
          let storeSalesBL:number;
          let storeSalesPS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numBL = dataGroup?.ymdInfos?.[ymdIndex]?.infos.storeSales?.storeSalesBL;
            storeSalesCS = calcUtil.plus(storeSalesCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            storeSalesBL = calcUtil.plus(storeSalesBL, numBL);
            storeSalesPS = calcUtil.plus(storeSalesPS, getNumPS(numBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          const storeSales = ymdInfo.infos.storeSales;
          storeSales.storeSalesCS_total = storeSalesCS;
          storeSales.storeSalesBL_total = storeSalesBL;
          storeSales.storeSalesPS_total = storeSalesPS;
          storeSales.state = 'calc';
        });
        break;
      case 'transfer':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let transferInPS:number;
          let transferInBL:number;
          let transferInCS:number;
          let transferOutPS:number;
          let transferOutBL:number;
          let transferOutCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numInBL = getTransferBL(dataGroup?.ymdInfos?.[ymdIndex]?.infos.transferIn, null);
            transferInCS = calcUtil.plus(transferInCS, getNumCS(numInBL, dataGroup.centerItem?.csIrisu));
            transferInBL = calcUtil.plus(transferInBL, numInBL);
            transferInPS = calcUtil.plus(transferInPS, getNumPS(numInBL, dataGroup.centerItem?.blIrisu));

            const numOutBL = -1 * getTransferBL(null, dataGroup?.ymdInfos?.[ymdIndex]?.infos.transferOut);
            transferOutCS = calcUtil.plus(transferOutCS, getNumCS(numOutBL, dataGroup.centerItem?.csIrisu));
            transferOutBL = calcUtil.plus(transferOutBL, numOutBL);
            transferOutPS = calcUtil.plus(transferOutPS, getNumPS(numOutBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          {
            const transferIn = ymdInfo.infos.transferIn;
            transferIn.transferPS_total = transferInPS;
            transferIn.transferBL_total = transferInBL;
            transferIn.transferCS_total = transferInCS;
            transferIn.state = 'calc';
          }
          {
            const transferOut = ymdInfo.infos.transferOut;
            transferOut.transferPS_total = transferOutPS;
            transferOut.transferBL_total = transferOutBL;
            transferOut.transferCS_total = transferOutCS;
            transferOut.state = 'calc';
          }
        });
        break;
      case 'warehousing':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let warehousingPS:number;
          let warehousingBL:number;
          let warehousingCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numBL = getWarehousingBL(dataGroup?.ymdInfos?.[ymdIndex]?.infos.warehousing, dataGroup.centerItem?.blIrisu);
            warehousingCS = calcUtil.plus(warehousingCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            warehousingBL = calcUtil.plus(warehousingBL, numBL);
            warehousingPS = calcUtil.plus(warehousingPS, getNumPS(numBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          const warehousing = ymdInfo.infos.warehousing;
          warehousing.warehousingPS_total = warehousingPS;
          warehousing.warehousingBL_total = warehousingBL;
          warehousing.warehousingCS_total = warehousingCS;
          warehousing.state = 'calc';

          //容量（積載）を計算
          const capacityCS = {};
          targetDataGroups.forEach(dataGroup => {
            const numBL = getWarehousingBL(dataGroup?.ymdInfos?.[ymdIndex]?.infos.warehousing, dataGroup.centerItem?.blIrisu);
            const numCS = getNumCS(numBL, dataGroup.centerItem?.csIrisu);
            const maker = dataGroup.centerItem.makerCD;
            if (!capacityCS[maker]) {
              capacityCS[maker] = 0;
            }
            capacityCS[maker] = calcUtil.plus(capacityCS[maker], calcUtil.multiply(numCS, dataGroup.centerItem?.capacityCS));
          });
          warehousing.capacityCS = capacityCS;
        });
        break;
      case 'bestBefore':
        break;
      case 'storeOrder':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let storeOrderPS:number;
          let storeOrderBL:number;
          let storeOrderCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numPS = dataGroup?.ymdInfos?.[ymdIndex]?.infos.storeOrder?.storeOrderPS;
            const numBL = getNumBLfromPS(numPS, dataGroup.centerItem?.blIrisu);
            storeOrderCS = calcUtil.plus(storeOrderCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            storeOrderBL = calcUtil.plus(storeOrderBL, numBL);
            storeOrderPS = calcUtil.plus(storeOrderPS, numPS);
          });
          //更新
          const storeOrder = ymdInfo.infos.storeOrder;
          storeOrder.storeOrderPS_total = storeOrderPS;
          storeOrder.storeOrderBL_total = storeOrderBL;
          storeOrder.storeOrderCS_total = storeOrderCS;
          storeOrder.state = 'calc';
        });
        break;
      case 'shipping':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let shippingPS:number;
          let shippingBL:number;
          let shippingCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numBL = getShippingBL(dataGroup?.ymdInfos?.[ymdIndex]?.infos.shipping, dataGroup.centerItem?.blIrisu);
            shippingCS = calcUtil.plus(shippingCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            shippingBL = calcUtil.plus(shippingBL, numBL);
            shippingPS = calcUtil.plus(shippingPS, getNumPS(numBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          const shipping = ymdInfo.infos.shipping;
          shipping.shippingPS_total = shippingPS;
          shipping.shippingBL_total = shippingBL;
          shipping.shippingCS_total = shippingCS;
          shipping.state = 'calc';
        });
        break;
      case 'shipping_storeOrder':
        break;
      case 'stockNormal':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let stockNormalPS:number;
          let stockNormalBL:number;
          let stockNormalCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numBL = dataGroup?.ymdInfos?.[ymdIndex]?.infos.stock?.stockNormalBL;
            stockNormalCS = calcUtil.plus(stockNormalCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            stockNormalBL = calcUtil.plus(stockNormalBL, numBL);
            stockNormalPS = calcUtil.plus(stockNormalPS, getNumPS(numBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          const stock = ymdInfo.infos.stock;
          stock.stockNormalPS_total = stockNormalPS;
          stock.stockNormalBL_total = stockNormalBL;
          stock.stockNormalCS_total = stockNormalCS;
          stock.stateNormal = 'calc';
        });
        break;
      case 'stockUseUp':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let stockUseUpPS:number;
          let stockUseUpBL:number;
          let stockUseUpCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numBL = dataGroup?.ymdInfos?.[ymdIndex]?.infos.stock?.stockUseUpBL;
            stockUseUpCS = calcUtil.plus(stockUseUpCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            stockUseUpBL = calcUtil.plus(stockUseUpBL, numBL);
            stockUseUpPS = calcUtil.plus(stockUseUpPS, getNumPS(numBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          const stock = ymdInfo.infos.stock;
          stock.stockUseUpPS_total = stockUseUpPS;
          stock.stockUseUpBL_total = stockUseUpBL;
          stock.stockUseUpCS_total = stockUseUpCS;
          stock.stateUseUp = 'calc';
        });
        break;
      case 'stockReturned':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroupTotal.ymdInfos[ymdIndex];
          let stockReturnedPS:number;
          let stockReturnedBL:number;
          let stockReturnedCS:number;
          //合計
          targetDataGroups.forEach(dataGroup => {
            const numBL = dataGroup?.ymdInfos?.[ymdIndex]?.infos.stock?.stockReturnedBL;
            stockReturnedCS = calcUtil.plus(stockReturnedCS, getNumCS(numBL, dataGroup.centerItem?.csIrisu));
            stockReturnedBL = calcUtil.plus(stockReturnedBL, numBL);
            stockReturnedPS = calcUtil.plus(stockReturnedPS, getNumPS(numBL, dataGroup.centerItem?.blIrisu));
          });
          //更新
          const stock = ymdInfo.infos.stock;
          stock.stockReturnedPS_total = stockReturnedPS;
          stock.stockReturnedBL_total = stockReturnedBL;
          stock.stockReturnedCS_total = stockReturnedCS;
          stock.stateReturned = 'calc';
        });
        break;
      case "similarItem_warehousing":
        break;
      case "similarItem_shipping":
        break;
      case "similarItem_stockNormal":
        break;
      
      default:
        break;
    }

  });

  return dataGroupTotal;
}

//並び順変更
const sortDataGroups = (dataGroups: RowDataGroup[], listSortOrder: CodeName, listSortOrderDesc: boolean, singleCenter: boolean, itemCodes: string[]): RowDataGroup[] => {
  console.log('store.sortDataGroups');
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    listSortOrder.code == 'itemCD' ? o.centerItem.itemCD :
    listSortOrder.code == 'specifiedOrder' ? itemCodes.indexOf(o.centerItem.itemCD) :
    listSortOrder.code == 'itemNM' ? o.centerItem.itemNM :
    listSortOrder.code == 'janCD' ? o.centerItem.janCD :
    listSortOrder.code == 'maker' ? o.centerItem.makerCD :
    listSortOrder.code == 'category' ? o.centerItem.categoryCD :
    listSortOrder.code == 'bestBefore' ? o.centerItem.bestBefore :
    listSortOrder.code == 'allowablePeriod' ? o.centerItem.allowablePeriod :
    listSortOrder.code == 'teika' ? o.centerItem.teika :
    listSortOrder.code == 'tatene' ? o.centerItem.tatene :
    listSortOrder.code == 'shubai_date' ? o.centerItem.shubai_date :
    listSortOrder.code == 'tanaban_item' ? (singleCenter ? o.centerItem.tanaban : o.centerItem.itemCD) :
    listSortOrder.code == 'maker_tanaban' ? o.centerItem.makerCD :
    listSortOrder.code == 'center' ? o.centerItem.centerSortInput :
    listSortOrder.code == 'tanaban' ? o.centerItem.centerSortInput :
    listSortOrder.code == 'area' ? o.centerItem.areaCD :
    listSortOrder.code == 'pref' ? o.centerItem.prefCD :
    o.centerItem.itemCD
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    listSortOrder.group_code == 'item' && listSortOrder.code == 'maker_tanaban' ? (singleCenter ? o.centerItem.tanaban : o.centerItem.itemCD) :
    listSortOrder.group_code == 'item' ? o.centerItem.itemCD :
    listSortOrder.group_code == 'center' ? o.centerItem.centerCD :
    o.centerItem.itemCD
  ;
  let getSortKey3 = (o:RowDataGroup):string|number =>  {
    // var o:RowDataGroup = oo;

    // //棚番の場合、バンドル商品の棚番を取得
    // if(listSortOrder?.group_code == 'center' && listSortOrder?.code == 'tanaban' 
    //   && o.centerItem?.bundleGroup && o.centerItem.bundledItemCD) {
    //     o = dataGroups.find(d => d.centerItem.centerCD == o.centerItem.centerCD && d.centerItem.itemCD == o.centerItem.bundledItemCD);
    //     if(!o) {
    //       o = oo;
    //     }
    // }

    return !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    listSortOrder.group_code == 'item' ? o.centerItem.itemCD :
    listSortOrder.group_code == 'center' && listSortOrder.code == 'tanaban' ? o.centerItem.tanaban :
    listSortOrder.group_code == 'center' ? o.centerItem.centerCD :
    o.centerItem.itemCD
  }
  ;
  let getSortKey4 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.centerCD :
    listSortOrder.group_code == 'item' ? o.centerItem.centerSortInput :
    listSortOrder.group_code == 'center' ? o.centerItem.bundleGroup : //バンドルグループでまとめる
    o.centerItem.centerCD
  ;
  let getSortKey5 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.centerCD :
    listSortOrder.group_code == 'item' ? o.centerItem.centerCD :
    listSortOrder.group_code == 'center' ? o.centerItem.bundledItemCD : //バンドルグループでまとめる
    o.centerItem.centerCD
  ;

  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第5弾ソート項目
    if(comp == 0) {
      va = getSortKey5(a);
      vb = getSortKey5(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }

    return comp;
  });
  return dataGroups;
}
  //行情報に変換
export const convertRowInfos = (
  optionBumon: CodeName, 
  dataGroupTotal:RowDataGroup, dataGroups:RowDataGroup[], listSortOrder:CodeName, 
  showSubTotal:boolean, 
  showGroupRow:boolean,
  visibleRowsKey:string[], alertSetting:AlertSettings
  ): RowInfo[] => {
  console.log('store.convertRowInfos');
  //部門
  const orderable = optionBumon?.option1 == "1";
  const visibleStoreOrder = optionBumon?.option2 == "1";
  const hiddenZaikoNormal = optionBumon?.option3 == "1";
  const sej = optionBumon?.option4 == "1";

  let targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);
  //行項目　部門設定発注不可非表示
  if(!orderable) {
    targetRowsKeys = arrayutil.not(targetRowsKeys, rowKeysUnOrderableHidden);
  }
  //行項目　部門設定店頭発注非表示
  if(!visibleStoreOrder) {
    targetRowsKeys = arrayutil.not(targetRowsKeys, rowKeysBumonHiddenStoreOrder);
  }
  //行項目　部門設定在庫（良品）内訳非表示
  if(hiddenZaikoNormal) {
    targetRowsKeys = arrayutil.not(targetRowsKeys, rowKeysBumonHiddenZaikoNormal);
  }
  //もし全項目非表示ならばデフォルトだけにする
  if(targetRowsKeys.length == 0) {
    targetRowsKeys = defaultVisibleRowsKey;
  }

  const rowInfos:RowInfo[] = [];

  //合計行
  if(showSubTotal) {
    const rowKeyInfosTotal:{
      rowKey: string,
      rowIndex:number,
      rowIndexLast:boolean,
      rowCount:number,
    }[] = [];

    const targetRowsKeysTotal = targetRowsKeys
      .filter(visibleRowKey => rowDataTotalVisible[visibleRowKey])  //合計行に表示できるもののみ
      ;

    targetRowsKeysTotal
    .forEach((visibleRowKey, index) => {
      rowKeyInfosTotal.push({
        rowKey: visibleRowKey,
        rowIndex:index,
        rowIndexLast: targetRowsKeysTotal.length - 1 == index,
        rowCount: targetRowsKeysTotal.length
      });
    });

    rowKeyInfosTotal.forEach((rowKeyInfo) => {
      const rowInfo:RowInfo = {
        ...{
          TP: "total",
          top: false,
          index: rowInfos.length,
        }, 
        ...rowKeyInfo
      };
      // rowInfo.dataGroup = dataGroupTotal;
      rowInfo.dataGroupIndex = -1;
      rowInfos.push(rowInfo);
    })
  }

  //類似商品あり
  const rowKeyInfos_hasSimilar:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
    rowCount:number,
  }[] = [];
  targetRowsKeys.forEach((visibleRowKey, index) => {
    rowKeyInfos_hasSimilar.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast: targetRowsKeys.length - 1 == index,
      rowCount: targetRowsKeys.length
    });
  });
  //類似商品なし
  const targetRowsKeysNoSimilar = targetRowsKeys
    .filter(visibleRowKey => rowDataNoSimilarVisible[visibleRowKey])  //合計行に表示できるもののみ
    ;
  const rowKeyInfos_noSimilar:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
    rowCount:number,
  }[] = [];
  targetRowsKeysNoSimilar.forEach((visibleRowKey, index) => {
    rowKeyInfos_noSimilar.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast: targetRowsKeysNoSimilar.length - 1 == index,
      rowCount: targetRowsKeysNoSimilar.length
    });
  });


  let beforeDataGroup:RowDataGroup;
  const groupingParent = listSortOrder?.option1;
  const grouping = (!listSortOrder || listSortOrder.group_code == 'item') ? 'item' : 'center';
  let no = 0;
  let subno = 0;

  const drilldowwn_insert_rowkey = visibleRowsKey.indexOf("shipping_storeOrder") !== -1 ? "shipping_storeOrder" : "shipping";

  dataGroups.forEach((dataGroup, dataGroupIndex) => {
    //計算用の子センター分は非表示とする
    if(dataGroup.centerItem?.notTarget) {
      return;
    }

    // 親グルーピング
    let groupedParent = false;
    if(groupingParent == 'maker' && (!beforeDataGroup || beforeDataGroup.centerItem.makerCD != dataGroup.centerItem.makerCD)) {
      // no++;
      subno = 0;
      const rowInfo:RowInfo = {
        TP: "maker",
        top: true,
        no: ``,
        index: rowInfos.length,
      };        
      // rowInfo.dataGroup = dataGroup;
      rowInfo.dataGroupIndex = dataGroupIndex;
      if(showGroupRow) {
        rowInfos.push(rowInfo);
      }
      groupedParent = true;
    }

    // グルーピング
    if(grouping == 'item' && (!beforeDataGroup || beforeDataGroup.centerItem.itemCD != dataGroup.centerItem.itemCD)) {
      no++;
      subno = 0;
      const rowInfo:RowInfo = {
        TP: "item",
        top: !groupedParent,
        no: `${no}`,
        index: rowInfos.length,
      };        
      // rowInfo.dataGroup = dataGroup;
      rowInfo.dataGroupIndex = dataGroupIndex;
      if(showGroupRow) {
        rowInfos.push(rowInfo);
      }
    }
    else if(grouping == 'center' && (!beforeDataGroup || beforeDataGroup.centerItem.centerCD != dataGroup.centerItem.centerCD)) {
      no++;
      subno = 0;
      const rowInfo:RowInfo = {
        TP: "center",
        top: !groupedParent,
        no: `${no}`,
        index: rowInfos.length,
      };        
      // rowInfo.dataGroup = dataGroup;
      rowInfo.dataGroupIndex = dataGroupIndex;
      if(showGroupRow) {
        rowInfos.push(rowInfo);
      }
    }


    //明細行
    subno++;

    const drilldown_rowCount = dataGroup.shipping_group1s_key?.length ?? 0;
    let rowIndex = 0;
    (dataGroup.centerItem?.similarItemCD ? rowKeyInfos_hasSimilar : rowKeyInfos_noSimilar).forEach((rowKeyInfo) => {
      {
        const rowInfo:RowInfo = {
          ...{
            TP: "info",
            top: !showGroupRow && subno == 1,
            no: `${no}`,
            subno: `${subno}`,
            index: rowInfos.length,
          },
          ...rowKeyInfo,
          //ドリルダウンあり
          ...drilldown_rowCount ? {
            rowIndex: rowIndex++,
            rowCount: rowKeyInfo.rowCount + drilldown_rowCount,
          } : {}
        };
        // rowInfo.dataGroup = dataGroup;
        rowInfo.dataGroupIndex = dataGroupIndex;
        if(dataGroup.centerItem.koCenter1) {
           const tmp = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.koCenter1.code, dataGroups);
           if(tmp != null && tmp >= 0) {
            rowInfo.koCenterDataGroupIndex1 = tmp;
          }
        }
        if(dataGroup.centerItem.koCenter2) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.koCenter2.code, dataGroups);
          if(tmp != null && tmp >= 0) {
            rowInfo.koCenterDataGroupIndex2 = tmp;
          }
        }
        if(dataGroup.centerItem.koCenter3) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.koCenter3.code, dataGroups);
          if(tmp != null && tmp >= 0) {
            rowInfo.koCenterDataGroupIndex3 = tmp;
          }
        }
        if(dataGroup.centerItem.oyaCenter1) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.oyaCenter1.code, dataGroups);
          if(tmp != null && tmp >= 0) {
            rowInfo.oyaCenterDataGroupIndex1 = tmp;
          }
        }
        if(dataGroup.centerItem.oyaCenter2) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.oyaCenter2.code, dataGroups);
          if(tmp != null && tmp >= 0) {
            rowInfo.oyaCenterDataGroupIndex2 = tmp;
          }
        }
        if(dataGroup.centerItem.oyaCenter3) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.itemCD, dataGroup.centerItem.oyaCenter3.code, dataGroups);
          if(tmp != null && tmp >= 0) {
            rowInfo.oyaCenterDataGroupIndex3 = tmp;
          }
        }
        if(dataGroup.centerItem.bundleItemCD) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.bundleItemCD, dataGroup.centerItem.centerCD, dataGroups);
          if(tmp != null && tmp >= 0) {
           rowInfo.bundleItemDataGroupIndex = tmp;
          }
        }
        if(dataGroup.centerItem.bundledItemCD) {
          const tmp = findDataGroupIndex(dataGroup.centerItem.bundledItemCD, dataGroup.centerItem.centerCD, dataGroups);
          if(tmp != null && tmp >= 0) {
           rowInfo.bundledItemDataGroupIndex = tmp;
          }
        }
        rowInfos.push(rowInfo);
      }

      //ドリルダウンあり
      if(rowKeyInfo.rowKey == drilldowwn_insert_rowkey && drilldown_rowCount) {
        for (let index = 0; index < drilldown_rowCount; index++) {
          const rowInfo:RowInfo = {
            ...{
              TP: "info",
              top: !showGroupRow && subno == 1,
              no: `${no}`,
              subno: `${subno}`,
              index: rowInfos.length,
            }, 
            ...rowKeyInfo,
            ...{
              rowKey: "shipping_group1",
              drilldownKey: dataGroup.shipping_group1s_key[index],
              // drilldownIndex: index,
              rowIndex: rowIndex++,
              rowCount: rowKeyInfo.rowCount + drilldown_rowCount,
            }
          };
          // rowInfo.dataGroup = dataGroup;
          rowInfo.dataGroupIndex = dataGroupIndex;
          rowInfos.push(rowInfo);
        }
      }
    })

    beforeDataGroup = dataGroup;
  });
  return rowInfos;
}
  //行情報に変換
  const updateRowInfos = (state:MainPageTmpState, rowInfos:RowInfo[], dataGroupTotal:RowDataGroup, dataGroups:RowDataGroup[], alertSetting:AlertSettings): [number[], RowInfo[]] => {
    console.log('store.updateRowInfos');
    
    const rowInfoMap = {};
    rowInfos.forEach((rowInfo, idx) => {
      let infoKey = ``;
      switch (rowInfo.TP) {
        case "total":
          infoKey = `total`;
          break;
        case "maker":
          // infoKey = `${rowInfo?.dataGroup?.centerItem?.itemCD}`;
          break;
        case "item":
          // infoKey = `${rowInfo?.dataGroup?.centerItem?.itemCD}`;
          break;
        case "center":
          // infoKey = `${rowInfo?.dataGroup?.centerItem?.centerCD}`;
          break;
        case "info":
          let rowInfo_dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo, state.dataGroups, state.dataGroupTotal);
          infoKey = `${rowInfo_dataGroup?.centerItem?.centerCD} ${rowInfo_dataGroup?.centerItem?.itemCD}`;
          break;
        default:
          break;
      }
      if(infoKey) {
        let idxs:number[] = rowInfoMap[infoKey];
        if(!idxs) {
          idxs = [];
          rowInfoMap[infoKey] = idxs;
        }
        idxs.push(idx);
      }

    })

    const changeIdxs:number[] = [];
    if(dataGroupTotal) {
      const infoKey = `total`;

      const idxs:number[] = rowInfoMap[infoKey];
      if(idxs) {
        idxs.forEach(idx => {
          // rowInfos[idx].dataGroup = dataGroupTotal;
          changeIdxs.push(idx);
        });
      }
    }

    dataGroups.forEach(dataGroup => {
      const infoKey = `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.itemCD}`;

      const idxs:number[] = rowInfoMap[infoKey];
      if(idxs) {
        idxs.forEach(idx => {
          // rowInfos[idx].dataGroup = dataGroup;
          changeIdxs.push(idx);
        });
      }
    })
    return [changeIdxs, rowInfos];
  }

//配列データに変換
const convertRows = (dataGroups:RowDataGroup[], dataGroupTotal:RowDataGroup, rowInfos:RowInfo[], pagingInfo:PagingInfo, colRowModel:MainPageColRowModel, ymds: YMD[], visibleAttributes: string[][], numUnit:'CS'|'BL'|'PS'|string, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string): [any[][], number] => {
  console.log('store.convertRows');
  const pageInfo = pagingInfo?.selectedPage;

  let fixedRowsTop = 0;
  const rows:any[][] = rowInfos
          .filter((rowInfo, index) => rowInfo.TP == "total" || !pageInfo || (pageInfo.rowInfoIdxFrom <= index && pageInfo.rowInfoIdxTo >= index))
          .map((rowInfo) => {
            //小計業を固定
            if(rowInfo.TP == 'total') {
              fixedRowsTop++;
            }
            let dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo, dataGroups, dataGroupTotal);

            return convertRow(dataGroup, rowInfo, colRowModel, ymds, visibleAttributes, numUnit, onlyNormal, result_type);
          });
  return [rows, fixedRowsTop];
}

//配列データに変換
const convertRow = (dataGroup:RowDataGroup, rowInfo:RowInfo, colRowModel:MainPageColRowModel, ymds: YMD[], visibleAttributes: string[][], numUnit:'CS'|'BL'|'PS'|string, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string): any[] => {
  //set No.
  //出庫(先付含)は画面では出庫の値を返す
  const info_rowKey = rowInfo.rowKey == "shipping_storeOrder" ? "shipping" : rowInfo.rowKey;
  return colRowModel.colKeys
    //配列軽量化。T060形式以外もしくは、日付配列数以下のみ限定
    .filter((colKey, index) => colKey.substring(0,1) != 'T' || colKey.length != 4 || parseInt(colKey.substring(1, 4)) <= ymds.length)
    .map(colKey => valueFromKey(colKey, rowInfo, info_rowKey, dataGroup, ymds, visibleAttributes, numUnit, onlyNormal, result_type));
}

export const valueFromKey = (colKey: string, rowInfo:RowInfo, info_rowKey:string, dataGroup: RowDataGroup, ymds: YMD[], visibleAttributes: string[][], numUnit: 'CS' | 'BL' | 'PS' | string, onlyNormal:boolean, result_type: 'general_teiban'|'general'|'general_yoyaku'|'general_tokubai'|'all'|string): any => {
  if(!info_rowKey) {
    info_rowKey = rowInfo.rowKey;
  }
  //合計行
  if (rowInfo.TP == "total") {
    switch (colKey) {
      case "no":
        return `小計`;
      case "attribute1Label":
      case "attribute1Value":
      case "attribute2Label":
      case "attribute2Value":
        return null;
      case "itemTag":
        return null;
      case "rowHeader":
        const bumonCD = dataGroup.centerItem?.bumonCD;
        const headersRow = 
          bumonCD == '31' ? headersRowFM : 
          bumonCD == '55' ? headersRowSEJ : 
          onlyNormal ? headersRowGeneral : 
          result_type == 'general_teiban' ? headersRowGeneralTeibal : 
          result_type == 'general' ? headersRowGeneral : 
          result_type == 'general_yoyaku' ? headersRowGeneralYoyaku : 
          result_type == 'general_tokubai' ? headersRowGeneralTokubai : 
          headersRowAll;
        return headersRow[rowInfo.rowKey];
      default: {
        //期間列
        const termIndex = termColKeys.indexOf(colKey);
        if (termIndex >= 0) {
          const ymd = ymds[termIndex];
          const ymdID: number = ymd ? ymd.ymdID : -1;
          const ymdInfo: YMDInfo = ymd ? dataGroup.ymdInfos.find(ymdInfo => ymdInfo.ymdID == ymdID) : null;
          switch (info_rowKey) {
            case "weather": //天気
              return null;
            case "storeSales": //"店頭売上",
              const storeSales = ymdInfo?.infos?.storeSales;
              return numUnit == 'CS' ? storeSales?.storeSalesCS_total : numUnit == 'BL' ? storeSales?.storeSalesBL_total : numUnit == 'PS' ? storeSales?.storeSalesPS_total : null;
            case "transfer": //"転送予定",
              const transferIn = ymdInfo?.infos?.transferIn;
              const transferOut = ymdInfo?.infos?.transferOut;
              return numUnit == 'CS' ? calcUtil.minus(transferIn?.transferCS_total, transferOut?.transferCS_total) :
                  numUnit == 'BL' ? calcUtil.minus(transferIn?.transferBL_total, transferOut?.transferBL_total) :
                  numUnit == 'PS' ? calcUtil.minus(transferIn?.transferPS_total, transferOut?.transferPS_total) : null;
            case "warehousing": //"入庫",
              const warehousing = ymdInfo?.infos?.warehousing;
              return numUnit == 'CS' ? warehousing?.warehousingCS_total : numUnit == 'BL' ? warehousing?.warehousingBL_total : numUnit == 'PS' ? warehousing?.warehousingPS_total : null;
            case "warehousing_last": //"入庫",
              const warehousing_last = ymdInfo?.infos?.warehousing_last;
              return numUnit == 'CS' ? warehousing_last?.warehousingCS_total : numUnit == 'BL' ? warehousing_last?.warehousingBL_total : numUnit == 'PS' ? warehousing_last?.warehousingPS_total : null;
            case "bestBefore": //"賞味期限",
              return ymdInfo?.infos?.bestBefore?.bestBefore;
            case "storeOrder": //"受注",
              const storeOrder = ymdInfo?.infos?.storeOrder;
              return numUnit == 'CS' ? storeOrder?.storeOrderCS_total : numUnit == 'BL' ? storeOrder?.storeOrderBL_total : numUnit == 'PS' ? storeOrder?.storeOrderPS_total : null;
            case "shipping": //"出庫",
              const shipping = ymdInfo?.infos?.shipping;
              return numUnit == 'CS' ? shipping?.shippingCS_total : numUnit == 'BL' ? shipping?.shippingBL_total : numUnit == 'PS' ? shipping?.shippingPS_total : null;
            case "shipping_group1": //"出庫",
              return null;
            case "shipping_storeOrder": //"出庫(先付含)",
              {
                const storeOrder = ymdInfo?.infos?.storeOrder;
                const shipping = ymdInfo?.infos?.shipping;
                //未来は出庫(先付含)
                if(ymd?.type == "future") {
                  return calcUtil.plus(
                    numUnit == 'CS' ? storeOrder?.storeOrderCS_total : numUnit == 'BL' ? storeOrder?.storeOrderBL_total : numUnit == 'PS' ? storeOrder?.storeOrderPS_total : null,
                    numUnit == 'CS' ? shipping?.shippingCS_total : numUnit == 'BL' ? shipping?.shippingBL_total : numUnit == 'PS' ? shipping?.shippingPS_total : null
                  );
                }
                //当日・過去は出庫のみ
                else {
                  return numUnit == 'CS' ? shipping?.shippingCS_total : numUnit == 'BL' ? shipping?.shippingBL_total : numUnit == 'PS' ? shipping?.shippingPS_total : null;
                }
              }
            case "shipping_last": //"出庫",
              const shipping_last = ymdInfo?.infos?.shipping_last;
              return numUnit == 'CS' ? shipping_last?.shippingCS_total : numUnit == 'BL' ? shipping_last?.shippingBL_total : numUnit == 'PS' ? shipping_last?.shippingPS_total : null;
            case "stockNormal": //"在庫（良品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return numUnit == 'CS' ? stock?.stockNormalCS_total : numUnit == 'BL' ? stock?.stockNormalBL_total : numUnit == 'PS' ? stock?.stockNormalPS_total : null;
              }
            // case "stockNormal1": //"在庫（一般）",
            //   {
            //     const stock = ymdInfo?.infos?.stock;
            //     return numUnit == 'CS' ? stock?.stockNormal1CS_total : numUnit == 'BL' ? stock?.stockNormal1BL_total : numUnit == 'PS' ? stock?.stockNormal1PS_total : null;
            //   }
            // case "stockNormal2": //"在庫（特売）",
            //   {
            //     const stock = ymdInfo?.infos?.stock;
            //     return numUnit == 'CS' ? stock?.stockNormal2CS_total : numUnit == 'BL' ? stock?.stockNormal2BL_total : numUnit == 'PS' ? stock?.stockNormal2PS_total : null;
            //   }
            // case "stockNormal3": //"在庫（予約）",
            //   {
            //     const stock = ymdInfo?.infos?.stock;
            //     return numUnit == 'CS' ? stock?.stockNormal3CS_total : numUnit == 'BL' ? stock?.stockNormal3BL_total : numUnit == 'PS' ? stock?.stockNormal3PS_total : null;
            //   }
            case "stockUseUp": //"在庫（出切）",
              {
                const stock = ymdInfo?.infos?.stock;
                return numUnit == 'CS' ? stock?.stockUseUpCS_total : numUnit == 'BL' ? stock?.stockUseUpBL_total : numUnit == 'PS' ? stock?.stockUseUpPS_total : null;
              }
            case "stockReturned": //"在庫（返品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return numUnit == 'CS' ? stock?.stockReturnedCS_total : numUnit == 'BL' ? stock?.stockReturnedBL_total : numUnit == 'PS' ? stock?.stockReturnedPS_total : null;
              }
            case "similarItem_warehousing":
              const similarItem_warehousing = ymdInfo?.infos?.similarItem_warehousing;
              return numUnit == 'CS' ? similarItem_warehousing?.warehousingCS_total : numUnit == 'BL' ? similarItem_warehousing?.warehousingBL_total : numUnit == 'PS' ? similarItem_warehousing?.warehousingPS_total : null;
            case "similarItem_shipping":
              const similarItem_shipping = ymdInfo?.infos?.similarItem_shipping;
              return numUnit == 'CS' ? similarItem_shipping?.shippingCS_total : numUnit == 'BL' ? similarItem_shipping?.shippingBL_total : numUnit == 'PS' ? similarItem_shipping?.shippingPS_total : null;
            case "similarItem_stockNormal":
              {
                const similarItem_stock = ymdInfo?.infos?.similarItem_stock;
                return numUnit == 'CS' ? similarItem_stock?.stockNormalCS_total : numUnit == 'BL' ? similarItem_stock?.stockNormalBL_total : numUnit == 'PS' ? similarItem_stock?.stockNormalPS_total : null;
              }
            default:
              break;
          }
        }
        else {
          return dataGroup[colKey];
        }
      }
    }
  }

  //グループ行
  if (rowInfo.TP == "center" || rowInfo.TP == "item" || rowInfo.TP == "maker") {
    switch (colKey) {
      case "no":
        return rowInfo.no;
      case "attribute1Label":
        if (rowInfo.TP == "center") {
          return `${dataGroup.centerItem.centerCD2} ${dataGroup.centerItem.centerNM}`;
        }
        if (rowInfo.TP == "item") {
          return `${dataGroup.centerItem.itemNM}`;
        }
        if (rowInfo.TP == "maker") {
          return `${dataGroup.centerItem.makerNM}`;
        }
        return null;
      case "attribute1Value":
      case "attribute2Label":
      case "attribute2Value":
        return null;
      case "itemTag":
        return null;
      case "rowHeader":
        return null;
      default: {
        return null;
      }
    }
  }

  //明細行
  if (rowInfo.TP == "info") {
    switch (colKey) {
      case "no":
        return `${rowInfo.no}-${rowInfo.subno}`;
      case "attribute1Label":
      case "attribute2Label":
        {
          let attrIndex = colKey == "attribute1Label" ? 0 : 1;
          let attrKey = visibleAttributes[attrIndex][rowInfo.rowIndex];
          let attr = centerItemAttributes[attrIndex].find(attr => attr.code == attrKey);
          return attr ? attr.ryaku : null;
        }
      case "attribute1Value":
      case "attribute2Value":
        {
          let attrIndex = colKey == "attribute1Value" ? 0 : 1;
          let attrKey = visibleAttributes[attrIndex][rowInfo.rowIndex];
          return getAttributeValue(dataGroup, attrKey);
        }

      //Excelデータシート用
      case "centerCD": //"倉庫"
        return `${dataGroup.centerItem.centerCD2}`;
      case "centerNM": //"倉庫"
        return `${dataGroup.centerItem.centerNM}`;
      case "area": //"地域"
        return `${dataGroup.centerItem.areaNM}`;
      case "pref": //"都道府県"
        return `${dataGroup.centerItem.prefNM}`;
      case "itemCD": //"商品CD"
        return `${dataGroup.centerItem.itemCD}`;
      case "itemNM": //"商品名"
        return `${dataGroup.centerItem.itemNM}`;
      case "janCD": //"JAN"
        return `${dataGroup.centerItem.janCD}`;
      case "makerCD": //"メーカー"
        return `${dataGroup.centerItem.makerCD}`;
      case "makerNM": //"メーカー"
        return `${dataGroup.centerItem.makerNM}`;
      case "categoryCD": //"商品カテゴリ"
        return `${dataGroup.centerItem.categoryCD}`;
      case "categoryNM": //"商品カテゴリ"
        return `${dataGroup.centerItem.categoryNM}`;
      case "blIrisu": //"BL入数"
        return `${formatterN0.format(dataGroup.centerItem.blIrisu)}`;
      case "csIrisu": //"CS入数"
        return `${formatterN0.format(dataGroup.centerItem.csIrisu)}`;
      case "csIrisu_lot": //"CS入数×発注単位"
        return `${formatterN0.format(dataGroup.centerItem.csIrisu_lot)}`;
      case "blIrisu_csIrisu": //"BL入数×CS入数"
        return dataGroup.centerItem.blIrisu_csIrisu;
      case "lot": //"発注単位"
        return `${formatterN0.format(dataGroup.centerItem.lot)}`;
      case "loadedQty": //"積付数(面)
        return dataGroup?.itemCommonData?.loadedQty;
      case "capacity": //"内容量"
        return `${dataGroup.centerItem.capacity}`;
      case "bestBefore": //"賞味期間"
        return `${dataGroup.centerItem.bestBefore}日`;
      case "allowablePeriod": //"許容期間"
        return dataGroup.centerItem.allowablePeriod ? `${dataGroup.centerItem.allowablePeriod}日` : '';
      case "teika": //"定価"
        return `${formatterN0.format(dataGroup.centerItem.teika)}円`;
      case "tatene": //"建値"
        return `${formatterP00.format(dataGroup.centerItem.tatene)}円`;
      case "similarItemCD": //"類似商品CD
        return dataGroup?.itemTag_NEW?.validToday ? dataGroup?.itemTag_NEW?.similarItemCD : '';
      case "similarItemNM": //"類似商品CD
        return dataGroup?.itemTag_NEW?.validToday ? dataGroup?.itemTag_NEW?.similarItemNM : '';
      case "shubai_date": //"終売日"
        return dataGroup.centerItem.shubai_date;
      case "makerCenterName": //"メーカー倉庫
        return dataGroup.centerItem.makerCenterName ? `${dataGroup.centerItem.makerCenterName}` : '';
      case "tanaban": //"棚番"
        return `${dataGroup.centerItem.tanaban}`;
      case "zaikoKigen": //"在庫期限"
        return `${dataGroup?.centerItem?.zaikoKigen}`;
      case "zaikoUketsuke": //"在庫受付日"
        return dataGroup?.centerItem?.zaikoKigenUketsukeYmd ? moment(`${dataGroup?.centerItem?.zaikoKigenUketsukeYmd}`).format('YY/MM/DD') : "";
      case "zaikoKigenAlert": //"許容期間アラート賞味期限"
        return `${dataGroup.centerItem.zaikoKigenOfAlert}`;
      case "tokuisakiCD": //"得意先
        return `${dataGroup.centerItem.tokuisakiCD}`;
      case "tokuisakiNM": //"得意先
        return `${dataGroup.centerItem.tokuisakiNM}`;
      case "tokuisakiItemCD": //"得意先
        return `${dataGroup.centerItem.tokuisakiItemCD}`;
      case "teibanTokuisakiCD": //"得意先
        return `${dataGroup.centerItem.teibanTokuisakiCD}`;
      case "teibanTokuisakiNM": //"得意先
        return `${dataGroup.centerItem?.teibanTokuisakiNM}`;
      case "teibanTokuisakiItemCD": //"得意先
        return `${dataGroup.centerItem.teibanTokuisakiItemCD}`;
      case "bundleItemCD": 
        return `${dataGroup.centerItem?.bundleItemCD}`;
      case "bundledItemCD": 
        return `${dataGroup.centerItem?.bundledItemCD}`;
      case "bundleRate": 
        return `${dataGroup.centerItem?.bundleRate}`;
      case "oyaCenter": //"得意先
        {
          const let1 = `${dataGroup.centerItem.oyaCenter1 ? `${dataGroup.centerItem.oyaCenter1.code.slice(-2)} ${dataGroup.centerItem.oyaCenter1.name} (${moment(`${!dataGroup.centerItem.oyaCenter1.fromYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.oyaCenter1.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup.centerItem.oyaCenter1.toYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.oyaCenter1.toYMD}`).format('YY/M/D')}` : ''}`;
          const let2 = `${dataGroup.centerItem.oyaCenter2 ? `${dataGroup.centerItem.oyaCenter2.code.slice(-2)} ${dataGroup.centerItem.oyaCenter2.name} (${moment(`${!dataGroup.centerItem.oyaCenter2.fromYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.oyaCenter2.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup.centerItem.oyaCenter2.toYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.oyaCenter2.toYMD}`).format('YY/M/D')}` : ''}`;
          const let3 = `${dataGroup.centerItem.oyaCenter3 ? `${dataGroup.centerItem.oyaCenter3.code.slice(-2)} ${dataGroup.centerItem.oyaCenter3.name} (${moment(`${!dataGroup.centerItem.oyaCenter3.fromYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.oyaCenter3.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup.centerItem.oyaCenter3.toYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.oyaCenter3.toYMD}`).format('YY/M/D')}` : ''}`;
          return `${let1} ${let2} ${let3}`;
        }
      case "koCenter": //"得意先
        {
          const let1 = `${dataGroup.centerItem.koCenter1 ? `${dataGroup.centerItem.koCenter1.code.slice(-2)} ${dataGroup.centerItem.koCenter1.name} (${moment(`${!dataGroup.centerItem.koCenter1.fromYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.koCenter1.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup.centerItem.koCenter1.toYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.koCenter1.toYMD}`).format('YY/M/D')}` : ''}`;
          const let2 = `${dataGroup.centerItem.koCenter2 ? `${dataGroup.centerItem.koCenter2.code.slice(-2)} ${dataGroup.centerItem.koCenter2.name} (${moment(`${!dataGroup.centerItem.koCenter2.fromYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.koCenter2.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup.centerItem.koCenter2.toYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.koCenter2.toYMD}`).format('YY/M/D')}` : ''}`;
          const let3 = `${dataGroup.centerItem.koCenter3 ? `${dataGroup.centerItem.koCenter3.code.slice(-2)} ${dataGroup.centerItem.koCenter3.name} (${moment(`${!dataGroup.centerItem.koCenter3.fromYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.koCenter3.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup.centerItem.koCenter3.toYMD}`).isValid() ? '' : moment(`${dataGroup.centerItem.koCenter3.toYMD}`).format('YY/M/D')}` : ''}`;
          return `${let1} ${let2} ${let3}`;
        }
      case "itemTag":
        //メーカー残在庫
        let offset = 0;
        if(dataGroup.makerLimitData) {
          if(rowInfo.rowIndex == offset) {
            if(dataGroup.makerLimitData?.overFlg_past) {
              return `ﾒｰｶｰ残:なし`
            }
            else if(dataGroup.makerLimitData?.overFlg_calc) {
              // return `ﾒｰｶｰ残:0 BL`
              return `ﾒｰｶｰ残:${formatterN0.format(dataGroup.makerLimitData?.zanBL_calc)} BL`
            }
            else {
              return `ﾒｰｶｰ残:${formatterN0.format(dataGroup.makerLimitData?.zanBL_calc)} BL`
            }
          }
          offset ++;
        }

        //在庫期限アラート
        if(dataGroup.freshnessAlert) {
          if(rowInfo.rowIndex == offset) {
            return `出荷鮮度切れ${dataGroup.freshnessAlertBL ? ` (${dataGroup.freshnessAlertBL})` : ''}`;
          }
          offset ++;
        }

        //商品属性
        let itemTagDatas = dataGroup.itemTagDatas;
        let itemTagDatasLength = dataGroup.itemTagDatas ? dataGroup.itemTagDatas.length : 0;
        let itemTagData = itemTagDatas?.[rowInfo.rowIndex - offset];
        if(itemTagData) {
          // return itemTagData.comment ? `${itemTagData.itemTag?.ryaku}(${itemTagData.comment})` : `${itemTagData.itemTag?.ryaku}`;
          return itemTagData.comment ? `${itemTagData.comment}` : `${itemTagData.itemTag?.ryaku}`;
        }
        offset += itemTagDatasLength;

        //グループコメント
        if(rowInfo.rowIndex >= offset) { 
          if(dataGroup?.comments?.[rowInfo.rowIndex-offset]?.comment) {
            return dataGroup?.comments?.[rowInfo.rowIndex-offset]?.comment;
          }
        }

        return null;
      case "rowHeader":
        const bumonCD = dataGroup.centerItem?.bumonCD;
        const headersRow = 
          bumonCD == '31' ? headersRowFM : 
          bumonCD == '55' ? headersRowSEJ : 
          onlyNormal ? headersRowGeneral : 
          result_type == 'general_teiban' ? headersRowGeneralTeibal : 
          result_type == 'general' ? headersRowGeneral : 
          result_type == 'general_yoyaku' ? headersRowGeneralYoyaku : 
          result_type == 'general_tokubai' ? headersRowGeneralTokubai : 
          headersRowAll;
        //ドリルダウン
        if(rowInfo.rowKey == 'shipping_group1') {
          return dataGroup.shipping_group1s_name?.[rowInfo.drilldownKey];
        }
        return headersRow[rowInfo.rowKey];
      default: {
        //期間列
        const termIndex = termColKeys.indexOf(colKey);
        if (termIndex >= 0) {
          const ymd = ymds[termIndex];
          const ymdID: number = ymd ? ymd.ymdID : -1;
          const ymdInfo: YMDInfo = ymd ? dataGroup.ymdInfos.find(ymdInfo => ymdInfo.ymdID == ymdID) : null;
          switch (info_rowKey) {
            case "weather": //
              const weather = ymdInfo?.infos?.weather;
              return weather ? weather.weather : null;
            case "storeSales": //"店頭売上",
              const storeSales = ymdInfo?.infos?.storeSales;
              return getNum(storeSales?.storeSalesBL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? storeSales?.storeSalesBL : numUnit == 'PS' ? storeSales?.storeSalesPS : null;  
            case "transfer": //"転送予定",
              const transferIn = ymdInfo?.infos?.transferIn;
              const transferOut = ymdInfo?.infos?.transferOut;
              const transferBL = getTransferBL(transferIn, transferOut);
              return getNum(transferBL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? calcUtil.minus(transferIn?.transferBL, transferOut?.transferBL) : 
            //       numUnit == 'PS' ? calcUtil.minus(transferIn?.transferPS, transferOut?.transferPS) : null;  
            case "warehousing": //"入庫",
              const warehousing = ymdInfo?.infos?.warehousing;
              return getNum(getWarehousingBL(warehousing, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? getWarehousingBL(warehousing) : numUnit == 'PS' ? getWarehousingPS(warehousing) : null;  
            case "warehousing_last": //"入庫",
              const warehousing_last = ymdInfo?.infos?.warehousing_last;
              return getNum(getWarehousingBL(warehousing_last, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            case "bestBefore": //"賞味期限",
              return ymdInfo?.infos?.bestBefore?.bestBefore;
            case "storeOrder": //"受注",
              const storeOrder = ymdInfo?.infos?.storeOrder;
              return getNum(getNumBLfromPS(storeOrder?.storeOrderPS, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? storeOrder?.storeOrderBL : numUnit == 'PS' ? storeOrder?.storeOrderPS : null;  
            case "shipping": //"出庫",
              const shipping = ymdInfo?.infos?.shipping;
              return getNum(getShippingBL(shipping, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            case "shipping_group1": //"出庫",
              const shipping_group1 = ymdInfo?.infos?.shipping_group1s?.[rowInfo.drilldownKey];
              return getNum(getShippingBL(shipping_group1, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? getShippingBL(shipping) : numUnit == 'PS' ? getShippingPS(shipping) : null;  
            case "shipping_storeOrder": //"出庫(先付含)",
              {
                const storeOrder = ymdInfo?.infos?.storeOrder;
                const shipping = ymdInfo?.infos?.shipping;
                //未来は出庫(先付含)
                if(ymd?.type == "future") {
                  return calcUtil.plus(
                    getNum(getNumBLfromPS(storeOrder?.storeOrderPS, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu),
                    getNum(getShippingBL(shipping, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu)
                  );
                }
                //当日・過去は出庫のみ
                else {
                  return getNum(getShippingBL(shipping, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                }
              }
            case "shipping_last": //"出庫",
              const shipping_last = ymdInfo?.infos?.shipping_last;
              return getNum(getShippingBL(shipping_last, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
              // return numUnit == 'BL' ? getShippingBL(shipping) : numUnit == 'PS' ? getShippingPS(shipping) : null;  
            case "stockNormal": //"在庫（良品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return getNum(stock?.stockNormalBL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? stock?.stockNormalBL : numUnit == 'PS' ? stock?.stockNormalPS : null;  
              }
            case "stockNormal1": //"在庫（良品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return getNum(stock?.stockNormal1BL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? stock?.stockNormalBL : numUnit == 'PS' ? stock?.stockNormalPS : null;  
              }
            case "stockNormal2": //"在庫（良品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return getNum(stock?.stockNormal2BL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? stock?.stockNormalBL : numUnit == 'PS' ? stock?.stockNormalPS : null;  
              }
            case "stockNormal3": //"在庫（良品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return getNum(stock?.stockNormal3BL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? stock?.stockNormalBL : numUnit == 'PS' ? stock?.stockNormalPS : null;  
              }
            case "stockUseUp": //"在庫（出切）",
              {
                const stock = ymdInfo?.infos?.stock;
                return getNum(stock?.stockUseUpBL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? stock?.stockUseUpBL : numUnit == 'PS' ? stock?.stockUseUpPS : null;  
              }
            case "stockReturned": //"在庫（返品）",
              {
                const stock = ymdInfo?.infos?.stock;
                return getNum(stock?.stockReturnedBL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? stock?.stockReturnedBL : numUnit == 'PS' ? stock?.stockReturnedPS : null;  
              }
            case "similarItem_warehousing":
              const similarItem_warehousing = ymdInfo?.infos?.similarItem_warehousing;
              return getNum(getWarehousingBL(similarItem_warehousing, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? getWarehousingBL(similarItem_warehousing) : numUnit == 'PS' ? getWarehousingPS(similarItem_warehousing) : null;  
            case "similarItem_shipping":
              const similarItem_shipping = ymdInfo?.infos?.similarItem_shipping;
              return getNum(getShippingBL(similarItem_shipping, dataGroup?.centerItem?.blIrisu), numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
            // return numUnit == 'BL' ? getShippingBL(similarItem_shipping) : numUnit == 'PS' ? getShippingPS(similarItem_shipping) : null;  
            case "similarItem_stockNormal":
              {
                const similarItem_stock = ymdInfo?.infos?.similarItem_stock;
                return getNum(similarItem_stock?.stockNormalBL, numUnit, dataGroup.centerItem.blIrisu, dataGroup.centerItem.csIrisu);
                // return numUnit == 'BL' ? similarItem_stock?.stockNormalBL : numUnit == 'PS' ? similarItem_stock?.stockNormalPS : null;  
              }

            default:
              break;
          }
        }
        else {
          return dataGroup[colKey];
        }
      }
    }
  }
  return null;
}
export const getAttributeValue = (dataGroup: RowDataGroup, attrKey: string): string | number => {
  switch (attrKey) {
    case "center": //"倉庫"
      return `${dataGroup?.weatherAlert ? '⚡ ' : ''}${dataGroup?.centerItem?.centerCD2} ${dataGroup?.centerItem?.centerNM}`;
    case "area": //"地域"
      return `${dataGroup?.centerItem?.areaNM}`;
    case "pref": //"都道府県"
      return `${dataGroup?.centerItem?.prefNM}`;
    case "itemCD": //"商品CD"
      return `${dataGroup?.centerItem?.itemCD}`;
    case "itemNM": //"商品名"
      return `${dataGroup?.centerItem?.itemNM}`;
    case "janCD": //"JAN"
      return `${dataGroup?.centerItem?.janCD}`;
    case "maker": //"メーカー"
      return `${dataGroup?.centerItem?.makerCD} ${dataGroup?.centerItem?.makerNM}`;
    case "shiire": //"仕入先"
      return `${dataGroup?.centerItem?.shiireCD} ${dataGroup?.centerItem?.shiireNM}`;
    case "shiireTEL": //"仕入先"
      return `${dataGroup?.centerItem?.shiireTEL}`;
    case "shiireFAX": //"仕入先"
      return `${dataGroup?.centerItem?.shiireFAX}`;

    case "category": //"商品カテゴリ"
      return `${dataGroup?.centerItem?.categoryCD} ${dataGroup?.centerItem?.categoryNM}`;
    case "blIrisu": //"BL入数"
      return `${formatterN0.format(dataGroup?.centerItem?.blIrisu)}`;
    case "csIrisu": //"CS入数"
      return `${formatterN0.format(dataGroup?.centerItem?.csIrisu)}`;
    case "csIrisu_lot": //"CS入数×発注単位"
      return `${formatterN0.format(dataGroup?.centerItem?.csIrisu_lot)}`;
    case "blIrisu_csIrisu": //"BL入数×CS入数"
      return dataGroup?.centerItem?.blIrisu_csIrisu;
    case "lot": //"発注単位"
      return `${formatterN0.format(dataGroup?.centerItem?.lot)}`;
    case "loadedQty": //"積付数(面)
      return dataGroup?.itemCommonData?.loadedQty;
    case "capacity": //"内容量"
      return `${dataGroup?.centerItem?.capacity}`;
    case "bestBefore": //"賞味期間"
      return `${dataGroup?.centerItem?.bestBefore}日`;
    case "allowablePeriod": //"許容期間"
      return dataGroup?.centerItem?.allowablePeriod ? `${dataGroup?.centerItem?.allowablePeriod}日` : '';
    case "teika": //"定価"
      return `${formatterN0.format(dataGroup?.centerItem?.teika)}円`;
    case "tatene": //"建値"
      return `${formatterP00.format(dataGroup?.centerItem?.tatene)}円`;
    case "similarItemCD": //"類似商品CD
      return dataGroup?.itemTag_NEW?.validToday ? dataGroup?.itemTag_NEW?.similarItemCD : '';
    case "similarItemNM": //"類似商品CD
      return dataGroup?.itemTag_NEW?.validToday ? dataGroup?.itemTag_NEW?.similarItemNM : '';
    case "shubai_date": //"終売日"
      return dataGroup?.centerItem?.shubai_date;
    case "makerCenterName": //"メーカー倉庫
      return dataGroup?.centerItem?.makerCenterName ? `${dataGroup?.centerItem?.makerCenterName}` : '';
    case "tanaban": //"棚番"
      return `${dataGroup?.centerItem?.tanaban}`;
    case "zaikoKigen": //"在庫期限"
      return `${dataGroup?.centerItem?.zaikoKigen}`;
    case "zaikoUketsuke": //"在庫受付日"
      return dataGroup?.centerItem?.zaikoKigenUketsukeYmd ? moment(`${dataGroup?.centerItem?.zaikoKigenUketsukeYmd}`).format('YY/MM/DD') : "";
    case "zaikoKigenAlert": //"許容アラート賞味期限"
      return `${dataGroup?.centerItem?.zaikoKigenOfAlert}`;
    case "tokuisakiCD": //"得意先
      return `${dataGroup?.centerItem?.tokuisakiCD}`;
    case "tokuisakiNM": //"得意先
      return `${dataGroup?.centerItem?.tokuisakiNM}`;
    case "tokuisakiItemCD": //"得意先
      return `${dataGroup?.centerItem?.tokuisakiItemCD}`;
    case "teibanTokuisakiCD": //"得意先
      return `${dataGroup?.centerItem?.teibanTokuisakiCD}`;
    case "teibanTokuisakiNM": //"得意先
      return `${dataGroup?.centerItem?.teibanTokuisakiNM}`;
    case "teibanTokuisakiItemCD": //"得意先
      return `${dataGroup?.centerItem?.teibanTokuisakiItemCD}`;
    case "bundleItemCD": 
      return `${dataGroup.centerItem?.bundleItemCD ?? ''}`;
    case "bundledItemCD": 
      return `${dataGroup.centerItem?.bundledItemCD ?? ''}`;
    case "bundleRate": 
      return `${dataGroup.centerItem?.bundleRate ?? ''}`;      
    case "oyaCenter": //"得意先
      {
        const let1 = `${dataGroup?.centerItem?.oyaCenter1 ? `${dataGroup?.centerItem?.oyaCenter1.code.slice(-2)} ${dataGroup?.centerItem?.oyaCenter1.name} (${moment(`${!dataGroup?.centerItem?.oyaCenter1.fromYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.oyaCenter1.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup?.centerItem?.oyaCenter1.toYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.oyaCenter1.toYMD}`).format('YY/M/D')})` : ''}`;
        const let2 = `${dataGroup?.centerItem?.oyaCenter2 ? `${dataGroup?.centerItem?.oyaCenter2.code.slice(-2)} ${dataGroup?.centerItem?.oyaCenter2.name} (${moment(`${!dataGroup?.centerItem?.oyaCenter2.fromYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.oyaCenter2.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup?.centerItem?.oyaCenter2.toYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.oyaCenter2.toYMD}`).format('YY/M/D')})` : ''}`;
        const let3 = `${dataGroup?.centerItem?.oyaCenter3 ? `${dataGroup?.centerItem?.oyaCenter3.code.slice(-2)} ${dataGroup?.centerItem?.oyaCenter3.name} (${moment(`${!dataGroup?.centerItem?.oyaCenter3.fromYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.oyaCenter3.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup?.centerItem?.oyaCenter3.toYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.oyaCenter3.toYMD}`).format('YY/M/D')})` : ''}`;
        return `${let1} ${let2} ${let3}`;
      }
    case "koCenter": //"得意先
      {
        const let1 = `${dataGroup?.centerItem?.koCenter1 ? `${dataGroup?.centerItem?.koCenter1.code.slice(-2)} ${dataGroup?.centerItem?.koCenter1.name} (${moment(`${!dataGroup?.centerItem?.koCenter1.fromYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.koCenter1.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup?.centerItem?.koCenter1.toYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.koCenter1.toYMD}`).format('YY/M/D')})` : ''}`;
        const let2 = `${dataGroup?.centerItem?.koCenter2 ? `${dataGroup?.centerItem?.koCenter2.code.slice(-2)} ${dataGroup?.centerItem?.koCenter2.name} (${moment(`${!dataGroup?.centerItem?.koCenter2.fromYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.koCenter2.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup?.centerItem?.koCenter2.toYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.koCenter2.toYMD}`).format('YY/M/D')})` : ''}`;
        const let3 = `${dataGroup?.centerItem?.koCenter3 ? `${dataGroup?.centerItem?.koCenter3.code.slice(-2)} ${dataGroup?.centerItem?.koCenter3.name} (${moment(`${!dataGroup?.centerItem?.koCenter3.fromYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.koCenter3.fromYMD}`).format('YY/M/D')}～${!moment(`${dataGroup?.centerItem?.koCenter3.toYMD}`).isValid() ? '' : moment(`${dataGroup?.centerItem?.koCenter3.toYMD}`).format('YY/M/D')})` : ''}`;
        return `${let1} ${let2} ${let3}`;
      }
    default:
      return null;
  }
}

//ページング初期化
const pagingInit = (dataGroups: RowDataGroup[], dataGroupTotal: RowDataGroup, rowInfos: RowInfo[], selectedValue: string, pagingUnit: 'group' | 'maker' | '50' | '100' | '200' | '300' | string, listSortOrder: CodeName, pageStatus: 'makerPrev' | string): PagingInfo => {
  if(!selectedValue) {
    selectedValue = "";
  }
  //フラグを初期化
  dataGroups.forEach(dataGroup => {
    dataGroup.displayed = false;
  });

  const rowInfosTotal = rowInfos.filter(rowInfo => rowInfo.TP == 'total');
  // const rowInfosTops = rowInfos.filter(rowInfo => rowInfo.TP == 'item' || rowInfo.TP == 'center');
  const rowInfosTops = rowInfos.filter(rowInfo => rowInfo.top);

  let pages:PageInfo[] = [];
  let pageAll:PageInfo = {
    value: 'ALL',
    title: '全て (注)重くなります',
    rowInfoIdxFrom: rowInfosTotal.length,
    rowInfoIdxTo: rowInfos.length-1,
    searched: true,
  };
  pages.push(pageAll);

  let pagingGroup = 
    pagingUnit == "group" ? "group": 
    pagingUnit == "50" ? "group": 
    pagingUnit == "100" ? "group": 
    pagingUnit == "200" ? "group": 
    pagingUnit == "300" ? "group": 
    pagingUnit == "maker" ? "maker":  
    pagingUnit == "maker50" ? "maker": 
    pagingUnit == "maker100" ? "maker": 
    pagingUnit == "maker200" ? "maker": 
    pagingUnit == "maker300" ? "maker": 
    "group";
//メーカー単位で、商品順では無ければ//並び順がメーカーならば、グループと同じにする
  if(pagingGroup == "maker" && listSortOrder?.option1 != "maker") {
    pagingGroup = "group";
    pagingUnit = 
      pagingUnit == "maker" ? "group":  
      pagingUnit == "maker50" ? "50": 
      pagingUnit == "maker100" ? "100": 
      pagingUnit == "maker200" ? "200": 
      pagingUnit == "maker300" ? "300": 
      "group";
  }

  const maxRowCount = 
    pagingUnit == "group" ? -1: 
    pagingUnit == "50" ? 50: 
    pagingUnit == "100" ? 100: 
    pagingUnit == "200" ? 200: 
    pagingUnit == "300" ? 300: 
    pagingUnit == "maker" ? 99999999:  
    pagingUnit == "maker50" ? 50: 
    pagingUnit == "maker100" ? 100: 
    pagingUnit == "maker200" ? 200: 
    pagingUnit == "maker300" ? 300: 
    100;
  let page:PageInfo;
  let titlePrefix:string;
  let titlePrefixSameCount:number = 1;
  let groupCount = 0;
  rowInfosTops.forEach((rowInfo, index) => {
    let dataGroup:RowDataGroup = getRowInfoDataGroup(rowInfo, dataGroups, dataGroupTotal);

    const rowInfoIdxTo = index != rowInfosTops.length - 1 ? rowInfosTops[index+1].index-1 : rowInfos.length-1;

    const valueTmp = 
      listSortOrder.group_code == 'item' ? dataGroup?.centerItem?.itemCD :
      listSortOrder.group_code == 'center' ? dataGroup?.centerItem?.centerCD :
      rowInfo.TP == 'maker' ? dataGroup?.centerItem?.itemCD :
      rowInfo.TP == 'item' ? dataGroup?.centerItem?.itemCD :
      rowInfo.TP == 'center' ? dataGroup?.centerItem?.centerCD :
      '';

    const tmpTitlePrefix = pagingGroup == "maker" ? (`${dataGroup?.centerItem?.makerCD} ${dataGroup?.centerItem?.makerNM}`) :
                    listSortOrder.group_code == 'item' ? `${dataGroup?.centerItem?.itemCD} ${dataGroup?.centerItem?.itemNM}` :
                    listSortOrder.group_code == 'center' ? `${dataGroup?.centerItem?.centerCD2} ${dataGroup?.centerItem?.centerNM}` :
                    rowInfo.TP == 'maker' ? `${dataGroup?.centerItem?.itemCD} ${dataGroup?.centerItem?.itemNM}` :
                    rowInfo.TP == 'item' ? `${dataGroup?.centerItem?.itemCD} ${dataGroup?.centerItem?.itemNM}` :
                    rowInfo.TP == 'center' ? `${dataGroup?.centerItem?.centerCD2} ${dataGroup?.centerItem?.centerNM}` :
                    '';
      
    const rowCountZan = rowInfo.rowCount ? (rowInfo.rowCount - rowInfo.rowIndex) : 0; //グループ行でまとめる場合は考慮しない
    if(!page 
      || (pagingGroup == "maker" && titlePrefix != tmpTitlePrefix) 
      || rowInfoIdxTo - page.rowInfoIdxFrom + 1 + rowCountZan > maxRowCount) {
      
      if(titlePrefix == tmpTitlePrefix) {
        titlePrefixSameCount++;
      }
      else {
        titlePrefixSameCount = 1;
        titlePrefix = tmpTitlePrefix;
      }
      
      page = {
        value: valueTmp,
        rowInfoIdxFrom: rowInfo.index,
        searched: true,
      };
      groupCount = 0;
      pages.push(page);
    }
    if(valueTmp == selectedValue) {
      selectedValue = page.value;
    }
    groupCount++;
    if(pagingGroup == "maker") {
      if(pagingUnit == "maker") {
        page.title = titlePrefix + ` (${groupCount}件)`;
      }
      else {
        page.title = titlePrefix + ` [${titlePrefixSameCount}] (${groupCount}件)`;
      }
    }
    else {
      page.title = titlePrefix + (groupCount > 1 ? ` (他${groupCount-1}件)` : '');
    }
    page.rowInfoIdxTo = rowInfoIdxTo;

    //実績未取得
    {
      let searched = true;
      rowInfos
        .filter((rowInfo, index) => rowInfo.TP == 'info' && rowInfo.rowIndex == 0 && page.rowInfoIdxFrom <= index  && index <= page.rowInfoIdxTo)
        .forEach(rowInfo => {
          if(searched && !dataGroups[rowInfo.dataGroupIndex]?.searchedResults) {
            searched=false; //実績未取得
          }
        });
      if(!searched) {
        page.searched=false; //実績未取得
        pageAll.searched=false; //実績未取得
      }
    }
  });

  let selectedPage = pages.find(page => page.value == selectedValue);
  if(!selectedPage) {
    //前のメーカーへの移動は最終ページにする
    selectedPage = pages[pageStatus === 'makerPrev' ? pages.length - 1 : 1];
  }

  //表示フラグを立てる
  rowInfos
    .filter((rowInfo, idx) => rowInfo.rowIndex == 0 && (!selectedPage || (selectedPage.rowInfoIdxFrom <= idx && idx <= selectedPage.rowInfoIdxTo)))
    .forEach(rowInfo => {
      // rowInfo.dataGroup.displayed = true;
      getRowInfoDataGroup(rowInfo, dataGroups, dataGroupTotal).displayed = true;
    });

  return {
    pages: pages,
    selectedPage: selectedPage,
    selectedPageIndex: pages.findIndex(page => page.value == selectedPage?.value),
    selectedPageRowInfos: rowInfos.filter((rowInfo, idx) => rowInfo.TP == "total" || !selectedPage || selectedPage.rowInfoIdxFrom <= idx && idx <= selectedPage.rowInfoIdxTo),
  };
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], pagingInfo:PagingInfo, colRowModel:MainPageColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  if(rowInfos) {
    let colF = colRowModel.colFromKey('attribute1Label');
    let colT = colRowModel.colFromKey('rowHeader') - 1;

    const pageInfo = pagingInfo?.selectedPage;
    rowInfos
    .filter((rowInfo, index) => rowInfo.TP == "total" || !pageInfo || (pageInfo.rowInfoIdxFrom <= index && pageInfo.rowInfoIdxTo >= index))
    .map((rowInfo, index) => {
      if(rowInfo.TP == 'center' || rowInfo.TP == 'item' || rowInfo.TP == 'maker') {
        mergeCells.push({row: index, col: colF, rowspan:1, colspan:colT-colF+1});
      }
    });
  }
  return mergeCells;
}

//エラーチェック
const checkDataGroups = (dataGroups:RowDataGroup[], ymds: YMD[], fromIndex:number, toIndex:number): RowDataGroup[] => {
  console.log('store.checkDataGroups');
  return dataGroups.map(dataGroup => checkDataGroup(dataGroup, ymds, fromIndex, toIndex));
}
const checkDataGroup = (dataGroup:RowDataGroup, ymds: YMD[], fromIndex:number, toIndex:number): RowDataGroup => {
  //チェック済みはreturn
  if(dataGroup.checked) {
    return dataGroup;
  }
  //実績未検索はreturn
  if(!dataGroup.searchedResults) {
    return dataGroup;
  }
  dataGroup.ymdInfos = checkDatas(dataGroup, dataGroup.ymdInfos, ymds, fromIndex, toIndex);
  dataGroup.checked = true;
  return dataGroup;
}
const checkDatas = (dataGroup:RowDataGroup, ymdInfos:YMDInfo[], ymds: YMD[], fromIndex:number, toIndex:number): YMDInfo[] => {
  // console.log('store.checkDatas');
  return ymdInfos.map((ymdInfo, ymdIndex) => ymdIndex < fromIndex || toIndex < ymdIndex ? ymdInfo : ymdInfo);
}

//ハイライトチェック
const highlightDataGroups = (dataGroups: RowDataGroup[], ymds: YMD[], fromIndex: number, toIndex: number, holidays: any, alertSetting: AlertSettings, checkForced?: boolean): RowDataGroup[] => {
  console.log('store.highlightDataGroups');
  return dataGroups.map(dataGroup => highlightDataGroup(dataGroup, ymds, fromIndex, toIndex, holidays, alertSetting, checkForced));
}
const highlightDataGroup = (dataGroup: RowDataGroup, ymds: YMD[], fromIndex: number, toIndex: number, holidays: any, alertSetting: AlertSettings, checkForced?: boolean): RowDataGroup => {
  //チェック済みはreturn
  if (dataGroup.highlighted && !checkForced) {
    return dataGroup;
  }
  //実績未検索はreturn
  if(!dataGroup.searchedResults) {
    return dataGroup;
  }
  dataGroup.ymdInfos = highlightDatas(dataGroup, dataGroup.ymdInfos, ymds, fromIndex, toIndex, holidays, alertSetting);
  dataGroup.highlighted = true;
  return dataGroup;
}
const highlightDatas = (dataGroup:RowDataGroup, ymdInfos:YMDInfo[], ymds: YMD[], fromIndex:number, toIndex:number, holidays:any, alertSetting:AlertSettings): YMDInfo[] => {
  // console.log('store.highlightDatas');
  //最小のリードタイム
  const enableBusiness = dataGroup?.makerTagData?.leadTimeFlg && dataGroup?.makerTagData?.leadTimeDays > 0;
  let minLeadYmd = null;
  const ymdToday = ymds.find(ymd => ymd.type == 'today');
  if(enableBusiness && ymdToday) {

    const baseDate = moment(`${ymdToday.ymdID}`); //基準日
    //最小のリードタイム
    const leadTimeDays = dataGroup?.makerTagData?.leadTimeDays;
    //締め時刻を過ぎていたら、基準日を１日プラスする
    if(leadTimeDays && dataGroup?.makerTagData?.closeTime) {
      //締め時刻超過判定
      const close = moment(`${moment(new Date()).format('YYYY-MM-DD')} ${dataGroup?.makerTagData?.closeTime}`);
      const now:Moment = moment(new Date());
      if(now.isAfter(close)) {
        baseDate.add(1, 'day');
      }
    }
  
    minLeadYmd = ymdToday ? momentUtil.plusDaysInBusinessLeadTime(
      baseDate, 
      leadTimeDays, 
      true, //!dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateMonFlg,
      true, //!dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateTueFlg,
      true, //!dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateWedFlg,
      true, //!dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateThrFlg,
      true, //!dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateFriFlg,
      !dataGroup?.makerTagData?.leadTimeExcludeSaturdayFlg, //!infoData?.specifiedDateFlg || infoData?.specifiedDateSatFlg,
      false, //日曜は除く//!dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateSunFlg,
      dataGroup?.makerTagData?.leadTimeContainHolidayFlg,
      holidays,
      dataGroup.disableDateData?.disableDates //納品不可日
      ) : null;
  }

  dataGroup.hasAlert = false;

  return ymdInfos.map((ymdInfo, ymdIndex) => ymdIndex < fromIndex || toIndex < ymdIndex ? ymdInfo : highlightData(dataGroup, ymdInfo, ymds[ymdIndex], ymdIndex, minLeadYmd, alertSetting, holidays));
}
const highlightData = (dataGroup:RowDataGroup, ymdInfo: YMDInfo, ymd: YMD, ymdIndex:number, minLeadYmd:moment.Moment, alertSetting:AlertSettings, holidays:any): YMDInfo => {
  const blIrisu = dataGroup?.centerItem?.blIrisu;
  let highlightInfo: HighlightInfoData = ymdInfo.highlightInfo;
  if(!highlightInfo) {
    highlightInfo = {};
    ymdInfo.highlightInfo = {};
  }

  //ハイライト内容
  // highlightInfo.storeSales_text = null;
  // highlightInfo.transfer_text = null;
  highlightInfo.warehousing_text = null;
  // highlightInfo.bestBefore_text = null;
  highlightInfo.storeOrder_text = null;
  highlightInfo.shipping_text = null;
  highlightInfo.shipping_storeOrder_text = null;
  highlightInfo.stockNormal_text = null;
  // highlightInfo.stockUseUp_text = null;
  // highlightInfo.stockReturned_text = null;
  //ハイライトCSS
  // highlightInfo.storeSales_css = null;
  // highlightInfo.transfer_css = null;
  highlightInfo.warehousing_css = null;
  // highlightInfo.bestBefore_css = null;
  highlightInfo.storeOrder_css = null;
  highlightInfo.shipping_css = null;
  highlightInfo.shipping_storeOrder_css = null;
  highlightInfo.stockNormal_css = null;
  // highlightInfo.stockUseUp_css = null;
  // highlightInfo.stockReturned_css = null;
  
  //子センターフラグ
  const koCenterFlg = 
    (dataGroup.centerItem.oyaCenter1 && dataGroup.centerItem.oyaCenter1.fromYMD <= ymdInfo.ymdID && ymdInfo.ymdID <= dataGroup.centerItem.oyaCenter1.toYMD) ||
    (dataGroup.centerItem.oyaCenter2 && dataGroup.centerItem.oyaCenter2.fromYMD <= ymdInfo.ymdID && ymdInfo.ymdID <= dataGroup.centerItem.oyaCenter2.toYMD) ||
    (dataGroup.centerItem.oyaCenter3 && dataGroup.centerItem.oyaCenter3.fromYMD <= ymdInfo.ymdID && ymdInfo.ymdID <= dataGroup.centerItem.oyaCenter3.toYMD)
    ;

  //TODO
  //【入庫】  
  if(!highlightInfo.warehousing_css) {
    //未来
    //リードタイム・発注可能
    if(['future'].indexOf(ymd.type) !== -1){
      // const makerTagData:MakerTagData = dataGroup.makerTagData;
      if(minLeadYmd) {
        const momentYmd = moment(`${ymd.ymdID}`);
        if(!momentYmd.isBefore(minLeadYmd)) {

          //"基準日（※）からリードタイムと納品可能曜日を考慮した納品可能日
          if(momentUtil.enableInBusiness(momentYmd, 
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateMonFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateTueFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateWedFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateThrFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateFriFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateSatFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateSunFlg,
            !dataGroup?.makerTagData?.specifiedDateFlg || dataGroup?.makerTagData?.specifiedDateHolFlg,
            holidays,
            dataGroup.disableDateData?.disableDates //納品不可日
            )) {

            //"上記の次回納品可能日において、在庫数＜出荷数の場合　★
            const infos = ymdInfo?.infos;
            if(infos) {
              const val_shippping = getShippingBL(infos.shipping, blIrisu);
              const val_storeOrder = getNumBLfromPS(infos.storeOrder?.storeOrderPS, blIrisu);
              const val_stock = infos.stock?.stockNormalBL;
              
              if(!koCenterFlg && calcUtil.minus(calcUtil.plus(val_shippping, val_storeOrder), val_stock) > 0) {
                highlightInfo.warehousing_text = `納品可能日で在庫数＜出荷数`;
                highlightInfo.warehousing_css = 'body_highlight_red';  //シアン
                //出庫
                highlightInfo.shipping_text = `納品可能日で在庫数＜出荷数`;
                highlightInfo.shipping_css = 'body_highlight_red';  //赤
                //出庫(先付含)
                highlightInfo.shipping_storeOrder_text = `納品可能日で在庫数＜出荷数`;
                highlightInfo.shipping_storeOrder_css = 'body_highlight_red';  //赤
                //在庫
                highlightInfo.stockNormal_text = `納品可能日で在庫数＜出荷数`;
                highlightInfo.stockNormal_css = 'body_highlight_red';  //赤
              }
              else {
                highlightInfo.warehousing_text = `納品可能日`;
                highlightInfo.warehousing_css = 'body_highlight_light_cyan';  //シアン
              }
            }
          }
        }
      }
    }
    //過去
    //入荷日が変更、入庫数が変更になった場合
    if(['past'].indexOf(ymd.type) !== -1){
      if(compareUtil.compareNumber(getWarehousingBL(ymdInfo?.infos?.warehousing, blIrisu), ymdInfo?.infos?.warehousing?.warehousingBL_plan, true) != 0) {
        highlightInfo.warehousing_text = `入庫日/入庫数が変更`;
        highlightInfo.warehousing_css = 'body_highlight_yellow';  //黄色
      }
    }
    if (['future', 'today', 'past'].indexOf(ymd.type) !== -1) {
      //終売日N日前以降
      let shubaiLimitAlert = dataGroup.centerItem?.shubaiLimitAlert;
      if (!shubaiLimitAlert) {
        shubaiLimitAlert = dataGroup.centerItem?.centerCD === '0602' ? 7 : 10; //厚木はデフォルト7日
      }
      const shubaiLimit = !dataGroup.centerItem?.shubai_date ? null : moment(dataGroup.centerItem.shubai_date, 'YY/MM/DD').add(-shubaiLimitAlert, 'day');
      if (shubaiLimit) {
        const momentYmds = ymd.week ?
          dataGroup.ymdInfos.filter(ymdInfo => !ymdInfo.week && ymdInfo.week_id == ymd.week_id).map(ymdInfo => moment(`${ymdInfo.ymdID}`), 'YYYYMMDD').filter(m => m.isValid()) :
          [moment(`${ymd.ymdID}`)];
        if (momentYmds.some(momentYmd => !momentYmd.isBefore(shubaiLimit))) {
          highlightInfo.warehousing_text = `終売日${shubaiLimitAlert}日前以降`;
          highlightInfo.warehousing_css = 'body_highlight_purple';
        }
      }
    }
  }

  //【受注】
  if(!highlightInfo.storeOrder_css) {
    //未来
    //当日受注で前週のNBL以上超
    if(['today'].indexOf(ymd.type) !== -1){
      const num:number = alertSetting?.storeOrderNover;
      if(num) {
        const ymdInfo_lastweek:YMDInfo = dataGroup?.ymdInfos?.[ymd.lastWeekIndex];
        if(ymdInfo_lastweek && getNumBLfromPS(calcUtil.minus(ymdInfo?.infos?.storeOrder?.storeOrderPS, ymdInfo_lastweek?.infos?.storeOrder?.storeOrderPS), dataGroup?.centerItem?.blIrisu) >= num) {
            highlightInfo.storeOrder_text = `前週の${num}BL以上`;
            highlightInfo.storeOrder_css = 'body_highlight_magenda';  //マゼンダ
            dataGroup.hasAlert = true;
        }
      }
    }
    //当日受注で前週のN倍以上超
    if(['today'].indexOf(ymd.type) !== -1){
      const times:number = alertSetting?.storeOrderNtimes;
      if(times) {
        const ymdInfo_lastweek:YMDInfo = dataGroup?.ymdInfos?.[ymd.lastWeekIndex];
        if(ymdInfo_lastweek && (calcUtil.minus2(ymdInfo?.infos?.storeOrder?.storeOrderPS, calcUtil.multiply(ymdInfo_lastweek?.infos?.storeOrder?.storeOrderPS, times)) ?? -1) >= 0) {
            highlightInfo.storeOrder_text = `前週の${times}倍以上`;
            highlightInfo.storeOrder_css = 'body_highlight_magenda';  //マゼンダ
            dataGroup.hasAlert = true;
        }
      }
    }
  }

  //【出庫】  //【出庫(先付含)】  
  if(!highlightInfo.shipping_css || !highlightInfo.shipping_storeOrder_css) {
    //過去	現在
    //"前週より120％出庫UP
    if(['past','today'].indexOf(ymd.type) !== -1){
      let ymdInfoLastWeek = dataGroup.ymdInfos[ymd.lastWeekIndex];
      
      if(getShippingBL(ymdInfo?.infos?.shipping, blIrisu) / getShippingBL(ymdInfoLastWeek?.infos?.shipping, blIrisu) >= 1.2) {
        if(!highlightInfo.shipping_css) {
          highlightInfo.shipping_text = `出庫数が前週より120％UP`;
          highlightInfo.shipping_css = 'body_highlight_light_red';  //赤
        }
        if(!highlightInfo.shipping_storeOrder_css) {
          highlightInfo.shipping_storeOrder_text = `出庫数(先付含む)が前週より120％UP`;
          highlightInfo.shipping_storeOrder_css = 'body_highlight_light_red';  //赤
        }
      }
    }
  }
  if(!highlightInfo.shipping_css) {
    //未来
    //出庫予測数（過去3週間平均）が先週より多い場合
    if(['future'].indexOf(ymd.type) !== -1){
      let ymdInfoLastWeek = dataGroup.ymdInfos[ymd.lastWeekIndex];
      if(getShippingBL(ymdInfo?.infos?.shipping, blIrisu) > getShippingBL(ymdInfoLastWeek?.infos?.shipping, blIrisu)) {
        highlightInfo.shipping_text = `出庫予測数が先週より多い`;
        highlightInfo.shipping_css = 'body_highlight_matsushita';  //マゼンダ
      }
    }
  }
  if(!highlightInfo.shipping_storeOrder_css) {
    //未来
    //出庫予測数（過去3週間平均）が先週より多い場合
    if(['future'].indexOf(ymd.type) !== -1){
      let ymdInfoLastWeek = dataGroup.ymdInfos[ymd.lastWeekIndex];
      if(calcUtil.plus(getShippingBL(ymdInfo?.infos?.shipping, blIrisu), getNumBLfromPS(ymdInfo?.infos?.storeOrder?.storeOrderPS, blIrisu)) > getShippingBL(ymdInfoLastWeek?.infos?.shipping, blIrisu)) {
        highlightInfo.shipping_storeOrder_text = `出庫予測数が先週より多い`;
        highlightInfo.shipping_storeOrder_css = 'body_highlight_matsushita';  //マゼンダ
      }
    }
  }

  
  //【一般在庫】  
  if(!highlightInfo.stockNormal_css) {
    //現在	未来
    //在庫数マイナス or 出荷数＞在庫数
    if(['today','future'].indexOf(ymd.type) !== -1){
      if(ymdInfo?.infos?.stock?.stockNormalBL < 0) {
        highlightInfo.stockNormal_text = `在庫数マイナス`;
        highlightInfo.stockNormal_css = 'body_highlight_red';  //赤
      }
      else if(!koCenterFlg && calcUtil.minus(getShippingBL(ymdInfo?.infos?.shipping, blIrisu), ymdInfo?.infos?.stock?.stockNormalBL) > 0) {
        highlightInfo.stockNormal_text = `出庫数＞在庫数`;
        highlightInfo.stockNormal_css = 'body_highlight_red';  //赤
      }
    }
  }
  if(!highlightInfo.stockNormal_css) {
    //未来
    //N日後に在庫マイナス
    if(['today'].indexOf(ymd.type) !== -1){
      const days:number = alertSetting?.stockMinusAfterNdays;
      if(days) {
        const ymdInfo_afterNdays:YMDInfo = dataGroup?.ymdInfos?.[ymdIndex + days];
        if(ymdInfo_afterNdays && ymdInfo_afterNdays?.infos?.stock?.stockNormalBL < 0) {
            highlightInfo.stockNormal_text = `${days}日後に在庫数マイナス`;
            highlightInfo.stockNormal_css = 'body_highlight_magenda';  //マゼンダ
            dataGroup.hasAlert = true;
        }
      }
    }
  }

  if(!highlightInfo.stockNormal_css) {
    //未来
    //M日間以上の在庫量を持っている
    if(['today'].indexOf(ymd.type) !== -1){
      const days:number = alertSetting?.stockMdaysOrMore;
      if(days) {
        const ymdInfo_afterNdays:YMDInfo = dataGroup?.ymdInfos?.[ymdIndex + days];
        if(ymdInfo_afterNdays && ymdInfo_afterNdays?.infos?.stock?.stockNormalBL > 0) {
            highlightInfo.stockNormal_text = `${days}日間以上の在庫量`;
            highlightInfo.stockNormal_css = 'body_highlight_cyan';  //青系
            dataGroup.hasAlert = true;
        }
      }
    }
  }

  if(!highlightInfo.stockNormal_css) {
    //過去	現在	未来
    //「カット」を登録した日からの「標準」色
    if(['past','today','future'].indexOf(ymd.type) !== -1){
      if(dataGroup.itemTag_CUT) {
        const itemTag = dataGroup.itemTag_CUT;
        if(!itemTag.termFlg || (ymdInfo.ymdID >= itemTag.termStartDate && ymdInfo.ymdID <= itemTag.termEndDate)) {
          highlightInfo.stockNormal_text = itemTag.comment ? `${itemTag.itemTag.ryaku}(${itemTag.comment})` : `${itemTag.itemTag.ryaku}`;
          highlightInfo.stockNormal_css = 'body_highlight_light_yellow';  //黄色
        }
      }
    }
  }

  ymdInfo.highlightInfo = highlightInfo;
  return ymdInfo;
}

const calcMakerLimitDatas = (makerLimitDatas:MakerLimitData[], dataGroupsAll:RowDataGroup[]): MakerLimitData[] => {
  const dataGroupsAllMap = {};
  dataGroupsAll.forEach(dataGroup => {
    if(dataGroup.makerLimitData) {
      const itemCD = dataGroup.centerItem.itemCD;
      let dataGroups:RowDataGroup[] = dataGroupsAllMap[itemCD];
      if(!dataGroups) {
        dataGroups = [];
        dataGroupsAllMap[itemCD] = dataGroups;
      }
      dataGroups.push(dataGroup);
    }
  });
  return makerLimitDatas.map(makerLimitData => calcMakerLimitData(makerLimitData, dataGroupsAllMap[makerLimitData.itemCD], 0));
}
const calcMakerLimitData = (makerLimitData:MakerLimitData, dataGroupsAll:RowDataGroup[], diff_warehousingBL:number): MakerLimitData => {
  if(!makerLimitData) {
    return makerLimitData;
  }
  if(!dataGroupsAll?.length) {
    return makerLimitData;
  }

  //既に過去分で食いつぶしていたらreturn
  if(makerLimitData.overFlg_past) {
    makerLimitData.overFlg_calc = true;
    makerLimitData.zanBL_calc = 0;
    return makerLimitData;
  }

  //既に過去分で食いつぶしていたらreturn
  let zanBL = calcUtil.minus(makerLimitData.stockLimitBL, makerLimitData.warehousingBL_past);
  if(!zanBL || zanBL < 0) {
    makerLimitData.overFlg_calc = true;
    makerLimitData.zanBL_calc = zanBL;
    return makerLimitData;
  }

  //計算
  const dataGroups = dataGroupsAll.filter(dataGroup => 
    dataGroup.centerItem.makerCD == makerLimitData.makerCD && 
    dataGroup.centerItem.itemCD == makerLimitData.itemCD && 
    dataGroup.centerItem.bumonCD == makerLimitData.bumonCD && 
    dataGroup.centerItem.makerCenterName == makerLimitData.makerCenterName
    );
  //計算対象がない
  if(!dataGroups.length) {
    makerLimitData.overFlg_calc = false;
    makerLimitData.zanBL_calc = zanBL;
    return makerLimitData;
  }
  
  //計算
  let warehousingBL_today_future = diff_warehousingBL;
  dataGroups.forEach(dataGroup => {
    const blIrisu = dataGroup.centerItem?.blIrisu;
    dataGroup.ymdInfos.forEach(ymdInfo => {
      switch(ymdInfo.type) {
        case 'past':
          break;
        case 'today':
        case 'future':
          warehousingBL_today_future = calcUtil.plus(warehousingBL_today_future, getWarehousingBL(ymdInfo.infos?.warehousing, blIrisu))
          break;
        default:
          break;
      }
    })
  });

  zanBL = calcUtil.minus(zanBL, warehousingBL_today_future);
  if(!zanBL || zanBL < 0) {
    makerLimitData.overFlg_calc = true;
    makerLimitData.zanBL_calc = zanBL;
    return makerLimitData;
  }

  makerLimitData.overFlg_calc = false;
  makerLimitData.zanBL_calc = zanBL;
  return makerLimitData;
}
export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});


//Page Slice Export
//mainPageTmp
export const mainPageTmpSlice = createSliceContent("mainPageTmp");
